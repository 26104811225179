import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/16/solid";
import Breadcrumb from "../../assets/components/breadcrumb";
import { BACKEND_URL } from "../../Default";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Checkbox,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Card,
    CardContent,
    Button, CircularProgress
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from 'react-confirm-alert'; // Import react-confirm-alert module
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PermitManagement = ({ user: propUser }) => {
    const navigate = useNavigate();
    const [permits, setPermits] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortedField, setSortedField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [selectedRows, setSelectedRows] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');
    const [sortDate, setSortDate] = useState('latest');
    const [permitLoading, setPermitLoading] = useState(true);
    const [user, setUser] = useState(propUser || JSON.parse(localStorage.getItem('user')));

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/dashboard' },
        { label: 'Permit', url: '' },
        { label: 'Management', url: '/permit/management' },
    ];

    useEffect(() => {
        fetchPermits();
    }, []);

    const fetchPermits = async () => {
        try {
            setPermitLoading(true);
            const userId = JSON.parse(localStorage.getItem('user'))
            const response = await axios.get(`${BACKEND_URL}/permit/get/all/${userId.id}`);
            const permitData = response.data.map(permit => ({
                id: permit.id,
                requestId: permit.request_id || 'N/A', // Default to 'N/A' if null
                operatorName: permit.operator_name || 'N/A', // Default to 'N/A' if null
                flightNo: permit.flightNo || 'N/A', // Default to 'N/A' if null
                DGR: permit.DGR ? 'Yes' : 'No',
                status: permit.status,
                submissionDate: permit.added_date ? permit.added_date : 'N/A',
                approvalDate: permit.updated_date ? permit.updated_date : 'N/A',
                subRequestName: permit.sub_request_name || 'N/A', // Default to 'N/A' if null
            }));
            setPermits(permitData);
            setPermitLoading(false);
        } catch (error) {
            console.error('Error fetching permits:', error);
        }
    };



    const handleRowSelect = (event, id) => {
        const selectedIndex = selectedRows.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }

        setSelectedRows(newSelected);
    };

    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    const handleSort = (field) => {
        const isAsc = sortedField === field && sortDirection === 'asc';
        setSortedField(field);
        setSortDirection(isAsc ? 'desc' : 'asc');
    };

    const handleFilterChange = (event) => {
        setFilterStatus(event.target.value);
    };

    const handleDateSortChange = (event) => {
        setSortDate(event.target.value);
    };

    const filteredData = permits.filter((permit) => {
        const matchesSearchTerm = (
            (permit.operatorName && permit.operatorName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (permit.requestId && permit.requestId.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        const matchesFilterStatus = filterStatus ? permit.status === filterStatus : true;
        return matchesSearchTerm && matchesFilterStatus;
    });

    // Ensure the `sortedData` slice logic is correct
    const sortedData = filteredData.slice().sort((a, b) => {
        let comparison = 0;

        if (sortedField) {
            const fieldA = a[sortedField] ?? '';
            const fieldB = b[sortedField] ?? '';
            comparison = fieldA.localeCompare(fieldB);
            comparison = sortDirection === 'asc' ? comparison : -comparison;
        }

        if (sortDate) {
            const dateA = new Date(a.submissionDate);
            const dateB = new Date(b.submissionDate);

            if (dateA.getTime() > dateB.getTime()) {
                comparison = 1;
            } else if (dateA.getTime() < dateB.getTime()) {
                comparison = -1;
            } else {
                // If dates are equal, comparison remains 0
                comparison = 0;
            }

            comparison = sortDate === 'latest' ? -comparison : comparison;
        }

        return comparison;
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowClick = (request_status, request_id) => {
        if (request_status.toLowerCase() === "incomplete") {
            localStorage.setItem('request_id', request_id);
            navigate(`/permit/new/${request_id}`);
        } else {
            navigate(`/permit/details/${request_id}`);
        }
    };

    const handleDeleteClick = async (e, permitIds) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this request?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            await Promise.all(permitIds.map(async (permitId) => {
                                await axios.delete(`${BACKEND_URL}/permit/delete/${permitId}`);
                            }));
                            toast.success('Permit Request Deleted Successfully');
                            await fetchPermits();
                        } catch (error) {
                            console.log('Error deleting: ', error);
                            toast.error('Error Deleting Permit Request');
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    const handleNewPermitClick = () => {
        navigate('/permit/new');
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="w-full min-w-full min-h-[88vh] px-4">
                <main>
                    <div className="mt-3">
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                    <div className="mt-3">
                        <h1 className="font-bold text-gray-800 text-xl">Permit Management</h1>
                        <div className="grid grid-cols-4 gap-4 my-2">
                            <div>
                                <TextField
                                    label="Search"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                    className="bg-neutral-50"
                                />
                            </div>
                            <div className="col-start-3 col-span-2 flex justify-end">
                                <div className="mr-2">
                                    <FormControl variant="outlined" size="small" className="w-48">
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            value={filterStatus}
                                            onChange={handleFilterChange}
                                            label="Status"
                                            className="bg-neutral-50"
                                        >
                                            <MenuItem value="">All</MenuItem>
                                            <MenuItem value="complete">Complete</MenuItem>
                                            <MenuItem value="incomplete">Incomplete</MenuItem>
                                            <MenuItem value="not approved">Not Approved</MenuItem>
                                            <MenuItem value="generated">Generated</MenuItem>
                                            <MenuItem value="submitted">Submitted</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div>
                                    <FormControl variant="outlined" size="small" className="w-48">
                                        <InputLabel>Sort Date</InputLabel>
                                        <Select
                                            value={sortDate}
                                            onChange={handleDateSortChange}
                                            label="Sort Date"
                                            className="bg-neutral-50"
                                        >
                                            <MenuItem value="latest">Latest</MenuItem>
                                            <MenuItem value="oldest">Oldest</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <Card>
                            <CardContent>
                                <div className="flex justify-end mb-3">
                                    <Button variant="contained" color="primary" onClick={handleNewPermitClick}>
                                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                        Add Permit
                                    </Button>
                                </div>
                                {permitLoading ? (
                                    <div className="flex justify-center">
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {/*<TableCell padding="checkbox">*/}
                                                        {/*    <Checkbox*/}
                                                        {/*        indeterminate={selectedRows.length > 0 && selectedRows.length < permits.length}*/}
                                                        {/*        checked={permits.length > 0 && selectedRows.length === permits.length}*/}
                                                        {/*        onChange={(event) => setSelectedRows(event.target.checked ? permits.map((permit) => permit.id) : [])}*/}
                                                        {/*    />*/}
                                                        {/*</TableCell>*/}
                                                        <TableCell>
                                                            <strong>

                                                                Operator Name

                                                            </strong>
                                                        </TableCell>
                                                        <TableCell>
                                                            <strong>

                                                                    Request ID

                                                            </strong>
                                                        </TableCell>
                                                        <TableCell>
                                                            <strong>
                                                                DGR
                                                            </strong>
                                                        </TableCell>
                                                        <TableCell>
                                                            <strong>

                                                                    Submission Date

                                                            </strong>
                                                        </TableCell>

                                                        <TableCell>
                                                            <strong>
                                                                Status
                                                            </strong>
                                                        </TableCell>
                                                        <TableCell>
                                                            <strong>
                                                                Purpose
                                                            </strong>
                                                        </TableCell>
                                                        <TableCell>
                                                            <strong>
                                                                Actions
                                                            </strong>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((permit) => {
                                                        const isItemSelected = isSelected(permit.id);
                                                        return (
                                                            <TableRow
                                                                key={permit.id}
                                                                hover
                                                                onClick={() => handleRowClick(permit.status, permit.id)}
                                                                role="checkbox"
                                                                aria-checked={isItemSelected}
                                                                tabIndex={-1}
                                                                selected={isItemSelected}
                                                            >
                                                                {/*<TableCell padding="checkbox">*/}
                                                                {/*    <Checkbox*/}
                                                                {/*        checked={isItemSelected}*/}
                                                                {/*        onChange={(event) => handleRowSelect(event, permit.id)}*/}
                                                                {/*        onClick={(event) => event.stopPropagation()}*/}
                                                                {/*    />*/}
                                                                {/*</TableCell>*/}
                                                                <TableCell>{permit.operatorName}</TableCell>
                                                                <TableCell>{permit.requestId}</TableCell>
                                                                <TableCell>{permit.DGR}</TableCell>
                                                                <TableCell>{permit.submissionDate.split('T')[0]}</TableCell>
                                                                <TableCell>
                                                                    <div className={
                                                                        permit.status.toUpperCase() === 'INCOMPLETE'
                                                                            ? 'bg-red-500 text-white font-bold rounded text-center py-1 uppercase'
                                                                            : permit.status.toUpperCase() === 'COMPLETE'
                                                                                ? 'bg-green-500 text-white font-bold rounded text-center py-1 uppercase'
                                                                                : permit.status.toUpperCase() === 'SUBMITTED'
                                                                                    ? 'bg-orange-500 text-white font-bold rounded text-center py-1 uppercase'
                                                                                    : permit.status.toUpperCase() === 'GENERATED'
                                                                                        ? 'bg-green-700 text-white font-bold rounded text-center py-1 uppercase'
                                                                                        : ''
                                                                    }>{permit.status}</div>
                                                                </TableCell>
                                                                <TableCell>{permit.subRequestName}</TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            handleDeleteClick(event, [permit.id]);
                                                                        }}
                                                                        size="small"
                                                                        startIcon={<FontAwesomeIcon icon={faTrash} />}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>

                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={sortedData.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </div>
                </main>
            </div>
        </>
    );
};

export default PermitManagement;
