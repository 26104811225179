import React, { useEffect, useState } from 'react';
import Breadcrumb from "../../assets/components/breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { HomeIcon } from "@heroicons/react/16/solid";
import { useNavigate, useParams } from "react-router-dom";
import { BACKEND_URL } from "../../Default";
import { BACK } from "../../assets/wordings/constants";
import axios from 'axios';
import { CircularProgress, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { TrashIcon } from "@heroicons/react/24/solid";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";

function CargoDocuments() {
    const { requestId } = useParams();
    const navigate = useNavigate();
    const [purpose, setPurpose] = useState(null);
    const [type, setType] = useState(null);
    const [subPurpose, setSubPurpose] = useState(null);
    const [requiredServices, setRequiredServices] = useState({});
    const [generalDescription, setGeneralDescription] = useState('');
    const [airwayBills, setAirwayBills] = useState([]);
    const [generalDeclarations, setGeneralDeclarations] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [isDGR, setIsDGR] = useState(false);
    const [existingCargoData, setExistingCargoData] = useState([]);
    const [dataRetrieved, setDataRetrieved] = useState(false);
    const [DGRRetrieved, setDGRRetrieved] = useState(false);

    useEffect(() => {
        const storedPurpose = JSON.parse(localStorage.getItem('purpose'));
        const storedType = JSON.parse(localStorage.getItem('type'));
        const storedSubPurpose = JSON.parse(localStorage.getItem('subPurpose'));
        setPurpose(storedPurpose || null);
        setType(storedType || null);
        setSubPurpose(storedSubPurpose || null);
        fetchExistingData();
    }, []);

    const fetchExistingData = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/permit/get/detailed/${requestId}`);
            if (response.data && response.data.cargos) {
                setDataRetrieved(true);
                setExistingCargoData(response.data.cargos);



            } else {
                setDataRetrieved(false);
                setExistingCargoData([]);
                setGeneralDescription('');
            }

            if (response.data.cargo_description) {
                setGeneralDescription(response.data.cargo_description || '');
            }

            setDGRRetrieved(response.data && response.data.dgrs.length > 0);
            setIsDGR(response.data && response.data.isDGR === 1);
        } catch (error) {
            console.error('Error fetching existing cargo data:', error);
        }
    };

    const handleAirwayBillChange = (event) => {
        setAirwayBills(Array.from(event.target.files));
    };

    const handleGeneralDeclarationChange = (event) => {
        setGeneralDeclarations(Array.from(event.target.files));
    };

    const handleDGRChange = (event) => {
        setIsDGR(event.target.checked);
    };

    function generateShortKey() {
        return Math.random().toString(36).substr(2, 5); // Generates a 5-character string
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        setSubmitLoading(true);

        const updatedServices = {
            ...requiredServices,
            cargoDetails: {
                generalDescription: generalDescription,
                airwayBill: airwayBills.length > 0 ? airwayBills.map((file) => `AirwayBill-${generateShortKey()}.${file.type.split('/')[1]}`) : null,
                generalDeclaration: generalDeclarations.length > 0 ? generalDeclarations.map((file) => `GeneralDeclaration-${generateShortKey()}.${file.type.split('/')[1]}`) : null
            },
            isDGR: isDGR
        };

        const formData = new FormData();
        formData.append('client_id', JSON.parse(localStorage.getItem('user')).id);
        formData.append('request_id', requestId);
        formData.append('request_data', JSON.stringify(updatedServices));
        formData.append('request_status', 'INCOMPLETE');

        airwayBills.forEach((file) => {
            formData.append('files', file, `AirwayBill-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        generalDeclarations.forEach((file) => {
            formData.append('files', file, `GeneralDeclaration-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        localStorage.setItem('requiredServices', JSON.stringify(updatedServices));

        try {
            const response = await axios.post(`${BACKEND_URL}/permit/cargo-data`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            console.log('Response from backend:', response.data);
            setSubmitLoading(false);
            if (isDGR) {
                navigate(`/permit/new/dgr-documents-upload/${requestId}`);
            } else {
                navigate(`/permit/new/add-on-services/${requestId}`);
            }
        } catch (error) {
            console.error('Error sending data to backend:', error);
            setSubmitLoading(false);
        }
    };

    const goBack = () => {
        navigate(`/permit/new/flight-schedule/${requestId}`);
    };

    const handleDeleteCargoData = (document_id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this file?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            await axios.delete(`${BACKEND_URL}/permit/cargo-delete/${document_id}`, {
                                data: { request_id: requestId }
                            });
                            fetchExistingData();
                            toast.success('Cargo data deleted successfully');
                        } catch (error) {
                            console.log('Error deleting:', error);
                            toast.error('Error deleting cargo file');
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Permit', url: '' },
        { label: 'New', url: '/permit/new' },
    ];

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="flex flex-col">
                <main className="flex-grow">
                    <div className="w-full min-w-full px-4">
                        <div className="mt-3">
                            <Breadcrumb items={breadcrumbItems} />
                        </div>
                        <div className="mt-3">
                            <h1 className="font-bold text-gray-800 text-xl">Upload Cargo Documents</h1>
                        </div>

                        <div className="mt-3">
                            <form onSubmit={handleSubmit} className="text-gray-800">
                                <div className="bg-white py-2 px-4 rounded-md">
                                    <h2>Fill in the following fields</h2>
                                    <div className="flex max-w-full justify-center items-center">
                                        <div className="mt-3 flex justify-between gap-5">
                                            <div>
                                                Purpose of Flight: <strong>{purpose}</strong>
                                            </div>
                                            <div>
                                                Type of flight: <strong className="capitalize">{subPurpose}</strong>
                                            </div>
                                        </div>
                                    </div>

                                    {existingCargoData.length > 0 && (
                                        <div className="flex justify-center">
                                            <div className="mt-3">
                                                <label className="font-bold">Existing Cargo Data</label>
                                                <div className="flex max-w-full justify-center items-center">
                                                    <div className="mt-3 flex justify-between gap-5">
                                                        <Table>
                                                            <TableBody>
                                                                {existingCargoData.map((data, index) => (
                                                                    <TableRow key={data.document_id}>
                                                                        <TableCell>{index + 1}</TableCell>
                                                                        <TableCell>{data.document.document_name.split('-')[0]}</TableCell>
                                                                        <TableCell>
                                                                            <a
                                                                                href={`${BACKEND_URL}/${data?.document?.file_path}`}
                                                                                target="_blank"
                                                                                className="bg-fts-blue text-white py-2 px-4 rounded-2xl ml-3 hover:bg-fts-gold"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                View
                                                                            </a>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => handleDeleteCargoData(data.document_id)}
                                                                            >
                                                                                <TrashIcon className="h-5 w-5 text-red-700 hover:text-red-500" />
                                                                            </button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="grid grid-cols-1 gap-4 mx-12 px-12 mt-3 justify-center items-center">
                                        <div className="mb-2 grid grid-cols-2 gap-2">
                                            <label className="">General description of goods:</label>
                                            <textarea
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                value={generalDescription}
                                                onChange={(e) => setGeneralDescription(e.target.value)}
                                            />
                                        </div>

                                        <div className="mb-2 grid grid-cols-2 gap-2">
                                            <label className="block">Airway Bill:</label>
                                            <input
                                                type="file"
                                                multiple
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                onChange={handleAirwayBillChange}
                                            />
                                        </div>

                                        <div className="mb-2 grid grid-cols-2 gap-2">
                                            <label className="block">Crew General Declaration (GD):</label>
                                            <input
                                                type="file"
                                                multiple
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                onChange={handleGeneralDeclarationChange}
                                            />
                                        </div>

                                        <div className="mb-2 flex items-center">
                                            <label className="">Any <strong>DG</strong> Onboard?</label>
                                            <input
                                                type="checkbox"
                                                className="border border-gray-300 px-3 py-2 rounded-md ml-2 w-5 h-5"
                                                checked={isDGR}
                                                onChange={handleDGRChange}
                                                disabled={DGRRetrieved}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between mt-4">
                                    <button
                                        className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        type="button"
                                        onClick={goBack}
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                        {BACK}
                                    </button>

                                    {submitLoading ? (
                                        <button type="button"
                                                className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                            <CircularProgress size={20} />
                                        </button>
                                    ) : (
                                        <button type="submit"
                                                className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                            Next
                                            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default CargoDocuments;
