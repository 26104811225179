import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Footer from "../assets/components/footer";
import {Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import { BACKEND_URL } from '../Default'; // Make sure you have the BACKEND_URL in your config.js
import plane_cover from '../assets/images/placeholder/anotonov.webp';
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import HCaptcha from "@hcaptcha/react-hcaptcha";

const Login = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/sign-in');
        } else {
            navigate('/dashboard')
        }
    }, [navigate]);

    const [formData, setFormData] = useState({
        Email: '',
        Password: '',
    });

    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    const goToNext = async (e) => {
        e.preventDefault();


        try {
            const response = await axios.post(`${BACKEND_URL}/users/login`, {
                email: formData.Email,
                password: formData.Password,
            });

            console.log(response.data);
            // Save the token to localStorage or state management
            localStorage.setItem('user', JSON.stringify(response.data.user));
            localStorage.setItem('token', response.data.token);

            // Navigate to the dashboard
            navigate('/dashboard');
        } catch (error) {
            console.error('Login failed', error);
            setError('Login failed. Please check your credentials and try again.');
        }
    };

    const goToSignUp = () => {
        navigate('/sign-up');
    };

    const goToGuest = () => {
        // alert('This feature will be added after 2.00 PM');
        navigate('/guest');
    };

    const containerStyle = {
        backgroundImage: `url(${plane_cover})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        // minHeight: '100vh',
        width: '100%',
    };

    return (
        <div className="min-h-screen h-auto flex flex-col" style={containerStyle}>
            <div className="bg-black/80 flex-grow flex flex-col">
                <div className="block flex-grow">

                    <div className="flex justify-center items-center w-full text-fts-gold p-8 mt-36">
                        <form onSubmit={goToNext}>
                            <div className="text-center ">
                                <h1 className="font-bold text-[48px]">SkyPermits</h1>
                                <hr className="w-[90%] border-fts-blue border-4 my-4"/>
                                <div>
                                    <div className="w-full text-start">
                                        <div className="flex flex-col mb-4">
                                            <label htmlFor="email"
                                                   className="font-medium text-[16px] mb-2 text-neutral-200">Email:</label>
                                            <input
                                                type="text"
                                                id="email"
                                                name="Email"
                                                placeholder="Enter your email"
                                                value={formData.Email}
                                                onChange={handleInputChange}
                                                className="border-2 border-fts-gold rounded-md px-4 py-2 text-black"
                                            />
                                        </div>

                                        <div className="flex flex-col mb-4 relative">
                                            <label htmlFor="password"
                                                   className="font-medium text-[16px] mb-2 text-neutral-200">Password:</label>
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                id="password"
                                                name="Password"
                                                placeholder="Enter your password"
                                                value={formData.Password}
                                                onChange={handleInputChange}
                                                className="border-2 border-fts-gold rounded-md px-4 py-2 text-black"
                                            />
                                            <span
                                                className="absolute right-3 top-10 cursor-pointer text-fts-blue"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
                                            </span>
                                        </div>
                                    </div>

                                    {error && <div className="text-red-500 mt-2">{error}</div>}


                                    <div className="flex justify-between items-center mt-12">
                                        <button
                                            type="button"
                                            onClick={goToSignUp}
                                            className="rounded-2xl px-4 py-2 text-neutral-200 hover:text-fts-gold">
                                            No Account? Sign Up
                                        </button>
                                        <button
                                            type="submit"
                                            className="rounded-2xl bg-fts-gold hover:bg-fts-blue hover:text-fts-gold px-4 py-2 text-white">
                                            Sign In
                                            <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                                        </button>
                                    </div>


                                </div>
                            </div>
                        </form>

                    </div>
                    <div className="flex justify-center items-center">
                        <button onClick={goToGuest}
                                className="text-fts-gold hover:text-white flex mt-3 justify-center items-center bg-fts-blue hover:bg-fts-gold px-4 py-2 rounded-2xl">
                            <h5>Continue as Guest</h5>
                            <FontAwesomeIcon icon={faUser} className="ml-3"/>
                        </button>
                    </div>

                </div>

                {/*<div className="flex w-full text-white justify-between text-xs uppercase mt-0 mb-0 py-1 px-2">*/}
                {/*    <Link to={"/privacy-policy"} className="hover:text-fts-gold">*/}
                {/*        <h5>Privacy Policy</h5>*/}
                {/*    </Link>*/}
                {/*    <Link to={"/terms-conditions"} className="hover:text-fts-gold">*/}
                {/*        <h5>Terms and Conditions</h5>*/}
                {/*    </Link>*/}
                {/*</div>*/}
                <Footer/>
            </div>
        </div>
    );
};

export default Login;
