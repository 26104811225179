import React, { useEffect, useState } from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom';
import Sidebar from "../assets/components/sidebar";
import Dashboard from "./Dashboard";

import WaitingForApproval from "./New Permit/WaitingForApproval";
import PermitManagement from "./Permit Management/PermitManagement";
import PermitDetails from "./Permit Management/PermitDetails";
import HeadlineMarquee from "../assets/components/headlineMarquee";
import Footer from "../assets/components/footer";
import bgImage from '../assets/images/backgrounds/pattern-03.png';
import FlightDetails from './New Permit/FlightDetails';
import PassengerDocuments from './New Permit/PassengerDocuments';
import AircraftManagement from './AircraftManagement/AircraftManagement';
import DgrDocumentUpload from './New Permit/DGRDocumentUpload';
import TandC from './New Permit/TandC';
import RequestSummary from './New Permit/RequestSummary';
import FlightSchedule from './New Permit/FlightSchedule';
import CargoDocuments from './New Permit/CargoDocuments';
import RequiredService from './New Permit/RequiredService';


import Logs from "./Other/Logs";
import UserProfile from "./Other/UserProfile";

import PermitType from "./New Permit/PermitType";
import api from '../api';
import useInactivityTimeout from "../InactivityTimeOut";
import AddOnServices from "./New Permit/AddOnServices";

const Home = () => {
    useInactivityTimeout();
    const navigate = useNavigate();
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('user'));

        if (!token || !userData) {
            navigate('/sign-in');
        }

        if(userData){
            setUser(userData)
        }
    }, [navigate]);

    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };

    return (
        <div className="flex min-h-screen h-auto">
            <div className={`transition-all h-auto duration-300 bg-fts-blue ${isSidebarCollapsed ? 'w-16' : 'w-64'} max-w-64 min-w-16`}>
                <Sidebar user={user} isCollapsed={isSidebarCollapsed} toggleSidebar={toggleSidebar} />
            </div>

            <div className={`flex-grow flex flex-col bg-neutral-200 justify-between ${isSidebarCollapsed ? 'w-16' : 'w-64'}`} style={{  backgroundRepeat: 'repeat', backgroundAttachment: 'repeat', backgroundSize: '600px' }}>
                <div className="flex-none">
                    <HeadlineMarquee />
                </div>
                <div className="flex-grow overflow-y-auto px-3 bg-neutral-300/95">
                    <Routes>
                        <Route exact path="/dashboard" element={<Dashboard user={user}/>} />
                        {/* New Permit */}

                        <Route exact path="/permit/new" element={<RequiredService user={user}/>} />
                        <Route exact path="/permit/new/:requestId" element={<RequiredService user={user}/>} />
                        <Route exact path="/permit/new/awaiting-approval" element={<WaitingForApproval user={user}/>} />
                        <Route exact path="/permit/new/flight-details/:requestId" element={<FlightDetails user={user}/>} />
                        <Route exact path="/permit/new/permit-type/:requestId" element={<PermitType user={user}/>} />
                        <Route exact path="/permit/new/flight-schedule/:requestId" element={<FlightSchedule user={user}/>} />
                        <Route exact path="/permit/new/passenger-documents-upload/:requestId" element={<PassengerDocuments user={user}/>} />
                        <Route exact path="/permit/new/cargo-documents-upload/:requestId" element={<CargoDocuments user={user}/>} />
                        <Route exact path="/permit/new/dgr-documents-upload/:requestId" element={<DgrDocumentUpload user={user}/>} />
                        <Route exact path="/permit/new/add-on-services/:requestId" element={<AddOnServices user={user}/>} />
                        <Route exact path="/permit/new/terms-and-conditions/:requestId" element={<TandC user={user}/>} />
                        <Route exact path="/permit/new/request-summary/:requestId" element={<RequestSummary user={user}/>} />



                        {/* Permit Management */}
                        <Route exact path="/permit/management" element={<PermitManagement user={user}/>} />
                        <Route path="/permit/details/:ref" element={<PermitDetails user={user}/>} />
                        {/* Aircraft Management */}
                        <Route exact path="/aircraft/management" element={<AircraftManagement user={user}/>} />


                        {/*  Other  */}
                        {/*    Logs*/}
                        <Route exact path="/system/logs" element={<Logs user={user}/>} />
                        <Route exact path="/system/profile" element={<UserProfile  user={user}/>} />
                    </Routes>
                </div>
                <div className="flex-none">
                    <Footer user={user}/>
                </div>
            </div>
        </div>
    );
};

export default Home;
