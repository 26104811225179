import React, { useState } from 'react';
import {
    Paper,
    Avatar,
    Typography,
    Button,
    Box,
    Divider,
} from '@mui/material';
import { Edit, Notifications, Security, Settings, ExitToApp } from '@mui/icons-material';

const UserProfile = ({ user: propUser }) => {
    const [user, setUser] = useState(propUser || JSON.parse(localStorage.getItem('user')));

    const handleComingSoon = () => {
        alert('This feature is coming soon!');
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <Paper className="p-8 max-w-2xl w-full mx-4" elevation={3}>
                <div className="flex justify-center mb-6">
                    <Avatar
                        className="w-24 h-24"
                        alt={user.username}
                        src={user.image || "https://via.placeholder.com/150"}
                    />
                </div>
                <Typography variant="h4" className="text-center font-semibold mb-2">
                    {user.username || 'Username'}
                </Typography>
                <Typography variant="body1" className="text-center mb-4 text-gray-600">
                    {user.email || 'Email'}
                </Typography>
                <Divider className="mb-6" />
                <Box className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                    <Typography variant="body2">
                        <strong>Company: </strong>{user.company_name || 'Company'}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Designation: </strong>{user.designation || 'Designation'}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Phone: </strong>{user.contact_no || 'Phone'}
                    </Typography>
                </Box>
                <Divider className="mb-6" />
                <Box className="flex flex-wrap justify-around">
                    <Button
                        startIcon={<Edit />}
                        variant="contained"
                        color="primary"
                        className="m-2"
                        onClick={handleComingSoon}
                    >
                        Edit Profile
                    </Button>
                    <Button
                        startIcon={<Notifications />}
                        variant="outlined"
                        color="primary"
                        className="m-2"
                        onClick={handleComingSoon}
                    >
                        Notifications
                    </Button>
                    <Button
                        startIcon={<Security />}
                        variant="outlined"
                        color="primary"
                        className="m-2"
                        onClick={handleComingSoon}
                    >
                        Security
                    </Button>
                    <Button
                        startIcon={<Settings />}
                        variant="outlined"
                        color="primary"
                        className="m-2"
                        onClick={handleComingSoon}
                    >
                        Settings
                    </Button>

                </Box>
            </Paper>
        </div>
    );
};

export default UserProfile;
