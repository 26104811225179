import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusCircle, faTrash} from '@fortawesome/free-solid-svg-icons';
import Breadcrumb from "../../assets/components/breadcrumb";
import { HomeIcon } from "@heroicons/react/16/solid";
import NewAircraft from '../NewAircraft';
import axios from 'axios';
import { BACKEND_URL } from '../../Default';
import { useNavigate } from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdateAircraft from "../UpdateAircraft";

const AircraftManagement = ({user: propUser}) => {
    const [aircraft, setAircraft] = useState([]);
    const [user, setUser] = useState(propUser || JSON.parse(localStorage.getItem('user')));
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const navigate = useNavigate();
    const [selectedAircraftId, setSelectedAircraftId] = useState(null);

    useEffect(() => {

        fetchAircraft();
    }, [user, navigate, showModal, showUpdateModal]);

    // Fetch aircraft data from the API endpoint
    const fetchAircraft = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/aircrafts/aircraft/${user.id}`);
            setAircraft(response.data);
        } catch (error) {
            console.error('Error fetching aircraft data:', error);
        }
    };


    const handleRowSelect = (event, id) => {
        const selectedIndex = selectedRows.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }

        setSelectedRows(newSelected);
    };

    const handleOpenInNewTab = async (fileUrl) => {
        // Check if fileUrl is null or empty
        if (!fileUrl) {
            toast.error('File is not available. Update the file and try again.');
            return;
        }

        try {
            const fullFileUrl = `${BACKEND_URL}/${fileUrl.replace(/\\/g, '/')}`;
            const response = await fetch(fullFileUrl);

            // Check if the response is successful
            if (!response.ok) {
                throw new Error(`Network response was not ok. Status: ${response.status}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const newTab = window.open(url, '_blank');
            newTab.focus();

            // Optional: Revoke the object URL after some time to free up resources
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 10000); // Adjust the timeout as needed
        } catch (error) {
            console.error('Error opening file in new tab:', error);
            toast.error('An error occurred while opening the file. Please try again.');
        }
    };

    const handleUpdateModal = (aircraftId) => {
        setSelectedAircraftId(aircraftId);
        setShowUpdateModal(true);
    }


    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    const handleDeleteClick = async (e, aircraftIds) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this aircraft?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            await Promise.all(aircraftIds.map(async (aircraftId) => {
                                await axios.delete(`${BACKEND_URL}/aircrafts/delete/${aircraftId}`);
                            }));
                            toast.success('Aircraft Deleted Successfully')
                            await fetchAircraft();
                            // Optionally, you can update state or perform any other action after successful deletion
                        } catch (error) {
                            console.log('Error deleting: ', error);
                            toast.error('Error Deleting Aircraft')
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do nothing if "No" is clicked
                }
            ]
        });
    };

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/dashboard' },
        { label: 'Aircraft', url: '' },
        { label: 'Management', url: '/aircraft/management' },
    ];

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        <div className="w-full min-w-full min-h-[88vh] px-4">
            <main>
                <div className="mt-3">
                    <Breadcrumb items={breadcrumbItems} />
                </div>
                <div className="mt-3">
                    <h1 className="font-bold text-gray-800 text-xl">Aircraft Management</h1>
                    <div className="flex justify-between items-center my-4">
                        <TextField
                            label="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            variant="outlined"
                            size="small"
                        />
                        <div className="space-x-2">
                            <button
                                onClick={() => setShowModal(true)}
                                className="bg-green-600 py-2 px-2 rounded-md hover:bg-green-900 text-neutral-200">
                                <FontAwesomeIcon icon={faPlusCircle} className="mr-2"/>
                                Add New Aircraft
                            </button>


                        </div>
                    </div>
                    <div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>

                                        <TableCell><strong>Aircraft Registration</strong></TableCell>
                                        <TableCell><strong>Aircraft Model</strong></TableCell>
                                        <TableCell><strong>MTOW (Kg)</strong></TableCell>
                                        <TableCell><strong>Noise Certificate</strong></TableCell>
                                        <TableCell><strong>Seating Capacity</strong></TableCell>
                                        <TableCell><strong>Onboard Communication</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {aircraft.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                                        const isItemSelected = isSelected(row.aircraft_id);
                                        return (
                                            <TableRow
                                                key={row.aircraft_id}
                                                hover
                                                onClick={(event) => handleRowSelect(event, row.aircraft_id)}
                                                role="checkbox"
                                                selected={isItemSelected}
                                            >
                                                <TableCell>{row.aircraft_registration}</TableCell>
                                                <TableCell>{row.aircraft_model}</TableCell>
                                                <TableCell>{row.aircraft_mtow}</TableCell>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleOpenInNewTab(row.noise_certificate, row.aircraft_id)}
                                                >
                                                    View
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => handleUpdateModal(row.aircraft_id)}
                                                >
                                                    Update
                                                </Button>
                                                <TableCell>{row.seating_capacity}</TableCell>
                                                <TableCell>{row.onboard_communication}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={aircraft.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, newPage) => setPage(newPage)}
                            onRowsPerPageChange={(event) => {
                                setRowsPerPage(parseInt(event.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </div>
                </div>
                <NewAircraft showModal={showModal} onClose={() => setShowModal(false)} />
                <UpdateAircraft
                    showUpdateModal={showUpdateModal}
                    onClose={() => setShowUpdateModal(false)}
                    aircraftId={selectedAircraftId} // Pass the selected aircraft ID to the modal
                    userId={user.id}
                />
            </main>
        </div>
            </>
    );
};

export default AircraftManagement;
