import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { BACKEND_URL } from '../Default';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CircularProgress} from "@mui/material";

const UpdateAircraft = ({ showUpdateModal, onClose, aircraftId, userId }) => {
    const [noiseCertificate, setNoiseCertificate] = useState(null);
    const [errors, setErrors] = useState({});
    const [submitLoading, setSubmitLoading] = useState(false);



    const handleSubmit = async (e) => {
        e.preventDefault();

setSubmitLoading(true);
        const formData = new FormData();
        formData.append('client_id', userId);
        formData.append('aircraft_id', aircraftId);
        formData.append('noise_certificate', noiseCertificate);


        try {
            await axios.post(`${BACKEND_URL}/aircrafts/update-aircraft`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });


            setNoiseCertificate(null);


            setErrors({});
            toast.success("Noise certificate updated successfully", {
                onClose: () => {
                    setSubmitLoading(false);
                    onClose();
                }
            });
            onClose();
        } catch (error) {
            console.error('Error adding new aircraft:', error);
        }
    };

    if (!showUpdateModal) {
        return null;
    }


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
                <div className="bg-white rounded-lg p-6 w-1/2">
                    <div className="flex justify-end">
                        <button onClick={onClose}>
                            <FontAwesomeIcon icon={faTimes} className="text-gray-700" />
                        </button>
                    </div>
                    <h2 className="text-2xl font-bold mb-4">Update Noise Certificate</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-2 gap-4">

                            <div className="mb-4">
                                <label className="block">Noise certificate of the aircraft:</label>
                                <input
                                    type="file"
                                    className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                    onChange={(e) => setNoiseCertificate(e.target.files[0])}
                                />
                            </div>
                            <div className="flex justify-end col-span-2">
                                {submitLoading ?
                                    (
                                        <button
                                            disabled
                                                className="hover:bg-fts-gold bg-fts-blue text-white px-4 py-2 rounded-md">
                                            <CircularProgress width="25px"/>
                                        </button>
                                    )
                                    :
                                    (
                                        <button type="submit"
                                                className="hover:bg-fts-gold bg-fts-blue text-white px-4 py-2 rounded-md">
                                        Add Aircraft
                                    </button>
                                    )}

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default UpdateAircraft;
