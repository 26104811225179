import React, { useEffect, useState } from 'react';
import { HomeIcon } from "@heroicons/react/16/solid";
import Breadcrumb from "../../assets/components/breadcrumb";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../Default";
import axios from 'axios';
import { CircularProgress, Pagination, TextField, Button, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import dayjs from 'dayjs';

const Logs = ({ user: propUser }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(propUser || JSON.parse(localStorage.getItem('user')));
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');
    const logsPerPage = 5;

    useEffect(() => {
        fetchLogs();
    }, [navigate, startDate, endDate, sortOrder]);

    const fetchLogs = () => {
        axios.get(`${BACKEND_URL}/logs/get/${user.id}`)
            .then(response => {
                let filteredLogs = response.data;

                if (startDate) {
                    filteredLogs = filteredLogs.filter(log => new Date(log.date) >= new Date(startDate));
                }
                if (endDate) {
                    filteredLogs = filteredLogs.filter(log => new Date(log.date) <= new Date(endDate));
                }

                const sortedLogs = filteredLogs.sort((a, b) => sortOrder === 'asc'
                    ? new Date(a.date) - new Date(b.date)
                    : new Date(b.date) - new Date(a.date));

                setLogs(sortedLogs);
            })
            .catch(error => {
                console.error('Error fetching logs data:', error);
            });
    };

    const totalPages = Math.ceil(logs.length / logsPerPage);
    const indexOfLastLog = currentPage * logsPerPage;
    const indexOfFirstLog = indexOfLastLog - logsPerPage;
    const currentLogs = logs.slice(indexOfFirstLog, indexOfLastLog);

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    const handleFilterSubmit = (event) => {
        event.preventDefault();
        setCurrentPage(1);
        fetchLogs();
    };

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'System', url: '' },
        { label: 'Logs', url: '/system/logs' },
    ];

    return (
        <>
            <div className="flex flex-col">
                <main className="flex-grow">
                    <div className="w-full min-w-full px-4">
                        <div className="mt-3">
                            <Breadcrumb items={breadcrumbItems} />
                        </div>
                        <div className="mt-3">
                            <h1 className="font-bold text-gray-800 text-xl">User Actions</h1>
                        </div>
                        <form className="mt-3 grid grid-cols-6 gap-3 text-xs" onSubmit={handleFilterSubmit}>
                            <TextField
                                label="Start Date"
                                type="datetime-local"
                                className="col-start-3"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                            />
                            <TextField
                                label="End Date"
                                type="datetime-local"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                            />
                            <FormControl fullWidth>
                                <InputLabel>Sort Order</InputLabel>
                                <Select
                                    value={sortOrder}
                                    onChange={(e) => setSortOrder(e.target.value)}
                                    label="Sort Order"
                                >
                                    <MenuItem value="asc">Ascending</MenuItem>
                                    <MenuItem value="desc">Descending</MenuItem>
                                </Select>
                            </FormControl>
                            {/*<Button type="submit" variant="contained" color="primary" fullWidth>*/}
                            {/*    Apply Filters*/}
                            {/*</Button>*/}
                        </form>
                        <div className="mt-3">
                            {logs.length === 0 ? (
                                <CircularProgress />
                            ) : (
                                <div className="space-y-4 bg-white">
                                    {currentLogs.map(log => (
                                        <div key={log.id} className="p-4 border rounded shadow-md">
                                            <div className="flex justify-between">
                                                <span className="font-semibold text-gray-800">{log.action}</span>
                                                <span className={`text-sm ${log.status >= 200 && log.status < 300 ? 'text-green-500' : 'text-red-500'}`}>{log.status}</span>
                                            </div>
                                            <div className="text-sm text-gray-600">
                                                <p>Browser: {log.userAgent}</p>
                                                <p>Date: {dayjs(log.date).format('YYYY-MM-DD HH:mm:ss')}</p>
                                                <p>IP Address: {log.remoteAddr}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className="mt-4 flex justify-center">
                                <Pagination count={totalPages} page={currentPage} onChange={handleChangePage} color="primary" />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default Logs;
