import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import { BACKEND_URL } from "../Default";
import { BACK } from "../assets/wordings/constants";
import { CircularProgress } from "@mui/material";

const PermitType = () => {
    const { uniqueId } = useParams();
    const navigate = useNavigate();
    const [operatorData, setOperatorData] = useState({});
    const [permitTypes, setPermitTypes] = useState([]);
    const [selectedPermitType, setSelectedPermitType] = useState('');
    const [customType, setCustomType] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);


    useEffect(() => {
        const fetchExistingData = () => {
            try {
                const response = localStorage.getItem('generalInformation');

                if (response) {
                    const existingData = JSON.parse(response);
                    setSelectedPermitType(existingData.permitType || '');
                    console.log(existingData);

                }
            } catch (error) {
                console.error('Error fetching existing data:', error);
            }
        };

        fetchPermitTypes();
        fetchExistingData();
    }, [navigate]);

    const fetchPermitTypes = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${BACKEND_URL}/requests/request-permit-types`);
            setPermitTypes(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching permit types", error);
            setLoading(false);
        }
    };

    const handleCheckboxChange = (permitType) => {
        setSelectedPermitType(prevState => prevState === permitType ? '' : permitType);
    };

    const validate = () => {
        const errors = {};
        if (!selectedPermitType) {
            errors.selectedPermitType = 'A permit type must be selected.';
        }
        return errors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        let formData = JSON.parse(localStorage.getItem('generalInformation')) || {};

        formData = {
            ...formData,
            permitType: selectedPermitType ,
        };

        localStorage.setItem('generalInformation', JSON.stringify(formData));
        navigate(`/guest/flight-schedule/${uniqueId}`);
    };

    const goBack = () => {
        navigate(`/guest/aircraft-details/${uniqueId}`);
    };

    return (
        <div className="p-4">
            <form onSubmit={handleSubmit}>
                <div className="text-center max-w-4xl mx-auto">
                    <h1 className="font-bold text-[48px] text-fts-gold">Permit Type</h1>
                    <hr className="w-[90%] border-fts-gold border-2 my-4" />
                    <div className="justify-start text-start text-white px-6 grid grid-cols-3 gap-2">
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            permitTypes.map(permitType => (
                                <div key={permitType.id} className="flex items-center mb-2">
                                    <input
                                        type="radio"
                                        id={permitType.id}
                                        name="permitType"
                                        checked={selectedPermitType === permitType.id.toString()}
                                        onChange={() => handleCheckboxChange(permitType.id.toString())}
                                        className="hidden"
                                    />
                                    <label
                                        htmlFor={permitType.id}
                                        className={`flex items-center cursor-pointer p-4 rounded-md border-2 border-fts-blue text-fts-blue font-bold ${
                                            selectedPermitType === permitType.id.toString()
                                                ? 'bg-fts-gold'
                                                : 'bg-white'
                                        }`}
                                    >
                                        <span className="mr-2 capitalize">{permitType.name}</span>
                                        {selectedPermitType === permitType.id.toString() && (
                                            <span className="text-lg">✔️</span>
                                        )}
                                    </label>
                                </div>
                            ))
                        )}
                        {selectedPermitType === 'other' && (
                            <input
                                type="text"
                                value={customType}
                                onChange={(e) => setCustomType(e.target.value)}
                                placeholder="Custom Type"
                                className="mt-2 p-2 text-xl border-2 border-fts-blue rounded"
                            />
                        )}
                        {errors.selectedPermitType && (
                            <p className="text-red-500 mt-2">{errors.selectedPermitType}</p>
                        )}
                    </div>
                    <div className="flex justify-between mt-4">
                        <button
                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                            type="button"
                            onClick={goBack}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                            {BACK}
                        </button>

                        {submitLoading ? (
                            <button
                                type="button"
                                className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                            >
                                <CircularProgress size={20} />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                            >
                                Next
                                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                            </button>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PermitType;
