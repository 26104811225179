import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Footer from "../assets/components/footer";
import { BACKEND_URL } from "../Default";
import Select from "react-select";

const Registration = () => {
    const navigate = useNavigate();
    const [aocFile, setAocFile] = useState(null);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        designation: '',
        company_name: '',
        company_address: '',
        country_code: '',
        contact_no: '',
        password: '',
        ConfirmPassword: '',
        company_type: '',
    });

    const [error, setError] = useState('');
    const [countryCodes, setCountryCodes] = useState([]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCountryCodeChange = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            country_code: selectedOption ? selectedOption.value : '',
        }));
    };


    useEffect(() => {
        fetchCountryCodes();
    }, []);


    const fetchCountryCodes = async () => {
        try {
            const response = await axios.get('https://restcountries.com/v3.1/all');
            const countries = response.data;
            const codes = countries.map(country => ({
                country: country.name.common,
                code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : '')
            }));
            setCountryCodes(codes);
        } catch (error) {
            console.error('Error fetching country codes:', error);
        }
    };

    const clearForm = () => {
        setFormData({
            username: '',
            email: '',
            designation: '',
            company_name: '',
            company_address: '',
            country_code: '',
            contact_no: '',
            password: '',
            ConfirmPassword: '',
            company_type: '',
        });
        setAocFile(null);
    };

    const goToNext = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.ConfirmPassword) {
            setError('Passwords do not match');
            return;
        }

        const payload = new FormData();

        // Append form data
        Object.keys(formData).forEach((key) => {
            payload.append(key, formData[key]);
        });

        // Append file only if it exists and the company type is 'OPERATOR'
        if (formData.company_type === 'OPERATOR' && aocFile) {
            payload.append('files', aocFile, `AOC.${aocFile.type.split('/')[1]}`);
        }

        try {
            // Choose the correct endpoint based on the company_type
            const endpoint = formData.company_type === 'OPERATOR'
                ? `${BACKEND_URL}/users/register-operator-user`
                : `${BACKEND_URL}/users/register`;

            const response = await axios.post(endpoint, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log(response.data);
            clearForm();
            navigate('/dashboard');
        } catch (error) {
            if (error.response) {
                // Server responded with a status other than 200 range
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
                setError(`Registration failed: ${error.response.data.error || 'Unknown error'}`);
            } else if (error.request) {
                // Request was made but no response was received
                console.error('Request data:', error.request);
                setError('No response received from server');
            } else {
                // Something else triggered an error
                console.error('Error', error.message);
                setError('Registration failed due to unexpected error');
            }
        }
    };


    const goToSignIn = () => {
        navigate('/sign-in');
    };

    const containerStyle = {
        backgroundImage: 'url("https://ftsaero.com/wp-content/uploads/2023/07/flight-planning.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '100vh',
        width: '100%',
    };

    const codeOptions = countryCodes.map((code) => ({
        value: code.code,
        label: `${code.code} (${code.country})`,
    }));

// Sort the codeOptions array based on the 'value' property
    const sortedCodeOptions = codeOptions.sort((a, b) => {
        // Compare the values numerically
        return parseInt(a.value, 10) - parseInt(b.value, 10);
    });

    return (
        <div style={containerStyle}>
            <div className="bg-fts-blue/80 min-h-screen w-full flex flex-col">
                <div className="flex flex-grow w-full">
                    <div className="flex justify-center items-center w-full text-fts-gold p-8">
                        <div className="text-center">
                            <h1 className="font-bold text-[48px]">Sign Up</h1>
                            <hr className="w-[90%] border-fts-gold border-2 my-4" />
                            <form onSubmit={goToNext}>
                                <div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-2 w-full text-start">
                                        <div className="flex flex-col mb-4">
                                            <label htmlFor="username"
                                                   className="font-medium text-[16px] mb-2 text-neutral-200">Username:</label>
                                            <input
                                                type="text"
                                                id="username"
                                                name="username"
                                                placeholder="Enter your username"
                                                value={formData.username}
                                                onChange={handleInputChange}
                                                className="border-2 border-fts-gold rounded-md px-4 py-2 text-black"
                                                required
                                            />
                                        </div>

                                        <div className="flex flex-col mb-4">
                                            <label htmlFor="email"
                                                   className="font-medium text-[16px] mb-2 text-neutral-200">Email:</label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                placeholder="Enter your email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                className="border-2 border-fts-gold rounded-md px-4 py-2 text-black"
                                                required
                                            />
                                        </div>

                                        <div className="flex flex-col mb-4">
                                            <label htmlFor="designation"
                                                   className="font-medium text-[16px] mb-2 text-neutral-200">Designation:</label>
                                            <input
                                                type="text"
                                                id="designation"
                                                name="designation"
                                                placeholder="Enter your designation"
                                                value={formData.designation}
                                                onChange={handleInputChange}
                                                className="border-2 border-fts-gold rounded-md px-4 py-2 text-black"
                                                required
                                            />
                                        </div>

                                        <div className="flex flex-col mb-4">
                                            <label htmlFor="company-name"
                                                   className="font-medium text-[16px] mb-2 text-neutral-200">Company
                                                Name:</label>
                                            <input
                                                type="text"
                                                id="company-name"
                                                name="company_name"
                                                placeholder="Enter your company name"
                                                value={formData.company_name}
                                                onChange={handleInputChange}
                                                className="border-2 border-fts-gold rounded-md px-4 py-2 text-black"
                                                required
                                            />
                                        </div>

                                        <div className="flex flex-col mb-4">
                                            <label htmlFor="company-address"
                                                   className="font-medium text-[16px] mb-2 text-neutral-200">Company
                                                Address:</label>
                                            <input
                                                type="text"
                                                id="company-address"
                                                name="company_address"
                                                placeholder="Enter your company address"
                                                value={formData.company_address}
                                                onChange={handleInputChange}
                                                className="border-2 border-fts-gold rounded-md px-4 py-2 text-black"
                                                required
                                            />
                                        </div>

                                        <div className="flex flex-col mb-4">
                                            <label htmlFor="contact_no"
                                                   className="font-medium text-[16px] mb-2 text-neutral-200">
                                                Contact Number
                                            </label>
                                            <div className="flex">
                                                <Select
                                                    className="w-1/3 text-sm"
                                                    value={sortedCodeOptions.find(option => option.value === formData.country_code)}
                                                    onChange={handleCountryCodeChange}
                                                    options={sortedCodeOptions}
                                                    placeholder="Code"
                                                    isSearchable
                                                    menuPortalTarget={document.body}
                                                    menuPosition="fixed"
                                                    styles={{
                                                        menuPortal: base => ({...base, zIndex: 9999, width: '30%'}),
                                                        control: (provided) => ({
                                                            ...provided,
                                                            border: '2px solid #FFD700', // Custom border color for react-select
                                                            borderRadius: '0.375rem', // Custom border radius
                                                            padding: '0.5rem', // Custom padding
                                                            minHeight: '40px', // Ensures it's not too tall
                                                            width: '100%' // Ensures full width of container
                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            fontSize: '0.875rem', // Custom font size
                                                        }),
                                                        placeholder: (provided) => ({
                                                            ...provided,
                                                            fontSize: '0.875rem', // Custom font size for placeholder
                                                        }),
                                                    }}
                                                />

                                                <input
                                                    type="text"
                                                    name="contact_no"
                                                    id="contact_no"
                                                    className="w-2/3 px-3 py-2 text-sm border-2 border-fts-gold rounded-r-md focus:outline-none text-black"
                                                    value={formData.contact_no}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter your contact number"
                                                    required
                                                />
                                            </div>
                                        </div>


                                        <div className="flex flex-col mb-4">
                                            <label htmlFor="password"
                                                   className="font-medium text-[16px] mb-2 text-neutral-200">Password:</label>
                                            <input
                                                type="password"
                                                id="password"
                                                name="password"
                                                placeholder="Enter your Password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                className="border-2 border-fts-gold rounded-md px-4 py-2 text-black"
                                                required
                                            />
                                        </div>

                                        <div className="flex flex-col mb-4">
                                            <label htmlFor="confirmPassword"
                                                   className="font-medium text-[16px] mb-2 text-neutral-200">Re-Enter
                                                Password:</label>
                                            <input
                                                type="password"
                                                id="confirmPassword"
                                                name="ConfirmPassword"
                                                placeholder="Re-Enter your Password"
                                                value={formData.ConfirmPassword}
                                                onChange={handleInputChange}
                                                className="border-2 border-fts-gold rounded-md px-4 py-2 text-black"
                                                required
                                            />
                                        </div>

                                        <div className="flex flex-col mb-4">
                                            <label htmlFor="company_type"
                                                   className="font-medium text-[16px] mb-2 text-neutral-200">Select
                                                Company Type</label>
                                            <select
                                                id="company_type"
                                                name="company_type"
                                                value={formData.company_type}
                                                onChange={handleInputChange}
                                                className="border-2 border-fts-gold rounded-md px-4 py-2 text-black"
                                                required
                                            >
                                                <option value="">Select Company Type</option>
                                                <option value="AGENT">Agent</option>
                                                <option value="OPERATOR">Operator / Airline</option>
                                            </select>
                                        </div>

                                        {formData.company_type === 'OPERATOR' && (
                                            <div className="flex flex-col mb-4">
                                                <label htmlFor="aocFile"
                                                       className="font-medium text-[16px] mb-2 text-neutral-200">AOC
                                                    File:</label>
                                                <input
                                                    type="file"
                                                    id="aocFile"
                                                    name="aocFile"
                                                    onChange={(e) => setAocFile(e.target.files[0])}
                                                    className="border-2 border-fts-gold rounded-md px-4 py-2"
                                                    // required
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {error && (
                                    <p className="text-red-500 mb-4">{error}</p>
                                )}

                                <div className="text-center flex flex-col">
                                    <button
                                        type="submit"
                                        className="bg-fts-gold text-black hover:bg-fts-blue font-bold py-3 px-6 rounded-md text-xl hover:text-amber-50 shadow-md"
                                    >
                                        <span className="mr-2">Register</span>
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={goToSignIn}
                                        className="text-neutral-200 mt-2"
                                    >
                                        Already have an account? Sign In
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Registration;
