import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { HomeIcon } from "@heroicons/react/16/solid";
import Breadcrumb from "../../assets/components/breadcrumb";
import { BACKEND_URL } from "../../Default";
import {BACK, NEXT} from "../../assets/wordings/constants";
import axios from 'axios';
import {CircularProgress, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {TrashIcon} from "@heroicons/react/24/solid";
import { confirmAlert } from 'react-confirm-alert'; // Import react-confirm-alert module
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PassengerDocuments() {
    const { requestId } = useParams();
    const navigate = useNavigate();
    const [purpose, setPurpose] = useState(null);
    const [type, setType] = useState(null);
    const [requiredServices, setRequiredServices] = useState({});
    const [inboundPassengerManifests, setInboundPassengerManifests] = useState([]);
    const [outboundPassengerManifests, setOutboundPassengerManifests] = useState([]);
    const [inboundGeneralDeclarations, setInboundGeneralDeclarations] = useState([]);
    const [outboundGeneralDeclarations, setOutboundGeneralDeclarations] = useState([]);
    const [isDGR, setIsDGR] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [subPurpose, setSubPurpose] = useState(null);
    const [existingPassengerData, setExistingPassengerData] = useState([]);
    const [dataRetrieved, setDataRetrieved] = useState(false);
    const [DGRRetrieved, setDGRRetrieved] = useState(false);


    useEffect(() => {
        const storedPurpose = JSON.parse(localStorage.getItem('purpose'));
        const storedType = JSON.parse(localStorage.getItem('type'));
        const subPurpose = JSON.parse(localStorage.getItem('subPurpose'));
        setSubPurpose(subPurpose || null);
        setPurpose(storedPurpose || null);
        setType(storedType || null);
        fetchExistingData();
    }, []);

    const fetchExistingData = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/permit/get/detailed/${requestId}`);
            if (response.data && response.data.passengers) {
                setDataRetrieved(true);
                console.log(response.data.passengers)
                setExistingPassengerData(response.data.passengers);
            } else {
                setDataRetrieved(false);
                setExistingPassengerData([]);
            }

            if (response.data && response.data.dgrs.length > 0) {
                setDGRRetrieved(true);
            } else {
                setDGRRetrieved(false);
            }

            if (response.data && response.data.isDGR === 1 ) {
                setIsDGR(true);
            }
        } catch (error) {
            console.error('Error fetching existing passenger data:', error);
        }
    };

    const handleFileChange = (setFileState) => (event) => {
        setFileState(Array.from(event.target.files));
    };

    const handleDGRChange = (event) => {
        setIsDGR(event.target.checked);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitLoading(true);

        const updatedServices = {
            ...requiredServices,
            passengerDetails: {
                passengerManifest: {
                    inbound: inboundPassengerManifests.length > 0 ? inboundPassengerManifests.map((file, index) => `InboundPassengerManifest-${generateShortKey()}.${file.type.split('/')[1]}`) : null,
                    outbound: outboundPassengerManifests.length > 0 ? outboundPassengerManifests.map((file, index) => `OutboundPassengerManifest-${generateShortKey()}.${file.type.split('/')[1]}`) : null
                },
                generalDeclaration: {
                    inbound: inboundGeneralDeclarations.length > 0 ? inboundGeneralDeclarations.map((file, index) => `InboundGeneralDeclaration-${generateShortKey()}.${file.type.split('/')[1]}`) : null,
                    outbound: outboundGeneralDeclarations.length > 0 ? outboundGeneralDeclarations.map((file, index) => `OutboundGeneralDeclaration-${generateShortKey()}.${file.type.split('/')[1]}`) : null
                }
            },
            isDGR: isDGR
        };

        function generateShortKey() {
            return Math.random().toString(36).substr(2, 5); // Generates a 5-character string
        }

        const formData = new FormData();
        formData.append('client_id', JSON.parse(localStorage.getItem('user')).id);
        formData.append('request_id', requestId);
        formData.append('request_data', JSON.stringify(updatedServices));
        formData.append('request_status', 'INCOMPLETE');

        inboundPassengerManifests.forEach((file, index) => {
            formData.append('files', file, `InboundPassengerManifest-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        outboundPassengerManifests.forEach((file, index) => {
            formData.append('files', file, `OutboundPassengerManifest-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        inboundGeneralDeclarations.forEach((file, index) => {
            formData.append('files', file, `InboundGeneralDeclaration-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        outboundGeneralDeclarations.forEach((file, index) => {
            formData.append('files', file, `OutboundGeneralDeclaration-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        localStorage.setItem('requiredServices', JSON.stringify(updatedServices));

        try {
            const response = await axios.post(`${BACKEND_URL}/permit/passenger-data`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (isDGR) {
                navigate(`/permit/new/dgr-documents-upload/${requestId}`)
            } else {
                navigate(`/permit/new/add-on-services/${requestId}`);
            }

            setSubmitLoading(false);
        } catch (error) {
            console.error('Error sending data to backend:', error);
            setSubmitLoading(false);
        }
    };

    const handleDeletePassengerData = (document_id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this file?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            // Send the DELETE request with document_id in the URL
                            await axios.delete(`${BACKEND_URL}/permit/passenger-delete/${document_id}`, {
                                data: { request_id: requestId } // Send additional data in the request body
                            });
                            fetchExistingData();
                            toast.success('Passenger data deleted successfully');
                        } catch (error) {
                            console.log('Error deleting:', error);
                            toast.error('Error deleting passenger file');
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }


    const goBack = () => {
        navigate(`/permit/new/flight-schedule/${requestId}`);
    };

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Permit', url: '' },
        { label: 'New', url: '/permit/new' },
    ];

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        <div className="w-full min-w-full min-h-[88vh] px-4">
            <main>
                <div className="mt-3">
                    <h1 className="font-bold text-gray-800 text-xl">Other Required Information</h1>
                </div>
                <div className="mt-6 bg-white px-3 py-2 rounded-md shadow-sm sm:rounded-lg">
                    <form onSubmit={handleSubmit} className="text-gray-800">
                        <div>
                            {/*<h2>Fill in the following fields</h2>*/}
                            <div className="flex max-w-full justify-center items-center">
                                <div className="mt-3 flex justify-between gap-5">
                                    <div>Purpose of Flight: <strong>{purpose}</strong></div>
                                    <div>Type of flight: <strong className="capitalize">{subPurpose}</strong></div>
                                </div>
                            </div>

                            {existingPassengerData && existingPassengerData.length > 0 && (
                                <div className="flex justify-center">
                                    <div className="mt-3">
                                        <label className="font-bold ">Existing Passenger Data</label>
                                        <div className="flex max-w-full justify-center items-center">
                                            <div className="mt-3 flex justify-between gap-5">
                                                <Table>
                                                    <TableBody>
                                                        {
                                                            existingPassengerData.map((data, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{index + 1}</TableCell>
                                                                    <TableCell>{data.document.document_name.split('-')[0]}</TableCell>
                                                                    <TableCell>
                                                                        <a
                                                                            href={`${BACKEND_URL}/${data?.document?.file_path}`}
                                                                            target="_blank"
                                                                            className="bg-fts-blue text-white py-2 px-4 rounded-2xl ml-3 hover:bg-fts-gold"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            View
                                                                        </a>
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => handleDeletePassengerData(data.document_id)}>
                                                                            <TrashIcon className="h-5 w-5 text-red-700 hover:text-red-500"/>
                                                                        </button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="grid grid-cols-1 gap-2 mx-12 px-12 mt-3 justify-center items-center">
                                <div className="grid grid-cols-2 gap-2">
                                    <label className="font-bold">Passenger Manifest </label>
                                </div>
                                <div className="mb-2 grid grid-cols-2 gap-2">
                                    <div>
                                        <label>Inbound:</label>
                                        <input
                                            type="file"
                                            multiple
                                            className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                            onChange={handleFileChange(setInboundPassengerManifests)}
                                        />
                                    </div>
                                    <div>
                                        <label>Outbound:</label>
                                        <input
                                            type="file"
                                            multiple
                                            className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                            onChange={handleFileChange(setOutboundPassengerManifests)}
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-2">
                                    <label className="font-bold">Crew General Declaration</label>
                                </div>
                                <div className="mb-2 grid grid-cols-2 gap-2">
                                    <div>
                                        <label>Inbound:</label>
                                        <input
                                            type="file"
                                            multiple
                                            className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                            onChange={handleFileChange(setInboundGeneralDeclarations)}
                                        />
                                    </div>
                                    <div>
                                        <label>Outbound:</label>
                                        <input
                                            type="file"
                                            multiple
                                            className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                            onChange={handleFileChange(setOutboundGeneralDeclarations)}
                                        />
                                    </div>
                                </div>

                                {/*<div className="mb-2 flex items-center">*/}
                                {/*    <label>Is this flight <strong>DGR?</strong></label>*/}
                                {/*    <input*/}
                                {/*        type="checkbox"*/}
                                {/*        className="border border-gray-300 px-3 h-5 w-5 py-2 ml-2 rounded-md"*/}
                                {/*        checked={isDGR}*/}
                                {/*        disabled={DGRRetrieved}*/}
                                {/*        onChange={handleDGRChange}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>

                            <div className="flex justify-between mt-4">
                                <button
                                    className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                    type="button"
                                    onClick={goBack}
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                                    {BACK}
                                </button>

                                {submitLoading ? (
                                    <button
                                        type="button"
                                        className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                    >
                                        <CircularProgress size={20}/>
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                    >
                                        {NEXT}
                                        <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </main>
        </div>
        </>
    );
}

export default PassengerDocuments;
