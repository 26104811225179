import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams} from "react-router-dom";
import { BACKEND_URL } from "../Default";
import {BACK} from "../assets/wordings/constants";
import {CircularProgress} from "@mui/material";
import axios from "axios";

const RequiredService = () => {
    const { uniqueId } = useParams();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [categoriesLoading, setCategoriesLoading] = useState(true);
    const [requiredServices, setRequiredServices] = useState({});
    const [aircraftNature, setAircraftNature] = useState('');
    const [flightType, setFlightType] = useState('');
    const [subRequestName, setSubRequestName] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [type, setType] = useState(null);

    useEffect(() => {

        const fetchExistingData = () => {
            try {
                const response = localStorage.getItem('generalInformation');
                // console.log(response);
                if (response) {
                    const existingData = JSON.parse(response);
                    setAircraftNature(existingData.aircraftNature || '');
                    setSubRequestName(existingData.subRequestName || '');
                    setType(existingData.type || '');
                }
            } catch (error) {
                console.error('Error fetching existing data:', error);
            }
        };
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${BACKEND_URL}/requests/request-categories-with-sub`);
                const data = await response.json();
                setCategories(data);
                setCategoriesLoading(false);
                console.log(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
        fetchExistingData();
    }, [navigate]);

    const handleAircraftNatureChange = (event) => {
        const selectedNature = event.target.value;
        setAircraftNature(selectedNature);

        const selectedCategory = categories.find(category => category.name === selectedNature);

        if (selectedCategory && selectedCategory.sub_categories.length === 1) {
            const subCategory = selectedCategory.sub_categories[0];
            setFlightType(subCategory.flightType);
            setSubRequestName(subCategory.sub_request_name);
            setSelectedSubCategory(subCategory.sub_category_id);
        } else {
            setFlightType('');
            setSubRequestName('');
            setSelectedSubCategory('');
        }
    };

    const handleFlightTypeChange = (event) => {
        const selectedSubCategory = event.target.value;
        const selectedCategory = categories.find(category => category.name === aircraftNature);

        if (selectedCategory) {
            const subCategory = selectedCategory.sub_categories.find(sub => sub.sub_request_name === selectedSubCategory);
            setFlightType(subCategory.flightType);
            setSubRequestName(subCategory.sub_request_name);
            setSelectedSubCategory(subCategory.sub_category_id);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitLoading(true);
        let existingData = JSON.parse(localStorage.getItem('generalInformation')) || {};

        existingData = {
            ...existingData,
            aircraftNature,
            subRequestName,
            flightType,
        };

        localStorage.setItem('generalInformation', JSON.stringify(existingData));

        const formData = new FormData();

        formData.append('request_id', uniqueId);
        formData.append('operator_name', existingData.company_name);
        formData.append('guest_name', existingData.guest_name);
        formData.append('email', existingData.guest_email);
        formData.append('contact', `${existingData.country_code}${existingData.company_contact_number}`);
        formData.append('request_data', JSON.stringify(existingData));
        formData.append('request_status', 'incomplete');


        try {

            const response = await axios.post(`${BACKEND_URL}/guests/new`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            setSubmitLoading(false);
            navigate(`/guest/aircraft-details/${uniqueId}`);

        } catch (error) {
            console.error(error);
            setSubmitLoading(false);
        }




    };

    const goBack = () => {
        navigate(`/guest/`);
    };

    return (
        <div className="w-full min-w-full  px-4 py-6">
            <form onSubmit={handleSubmit} className="max-w-5xl mx-auto">
                <div className="text-center mb-6">
                    <h1 className="font-bold text-2xl text-fts-gold">Purpose of Flight</h1>
                    <hr className="w-[90%] mx-auto border-fts-gold border-2 my-4" />
                </div>

                <div className="text-left  ">
                    {categoriesLoading ? (
                        <div className="flex justify-center items-center p-5">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                            {categories.map(category => (
                                <div key={category.category_id} className="p-4 border rounded-lg shadow-sm hover:shadow-md transition-shadow">
                                    <input
                                        type="radio"
                                        id={category.name.toLowerCase().replace(/\s+/g, '-')}
                                        name="aircraftNature"
                                        value={category.name}
                                        checked={aircraftNature === category.name}
                                        onChange={handleAircraftNatureChange}
                                        className="hidden"
                                    />
                                    <label
                                        htmlFor={category.name.toLowerCase().replace(/\s+/g, '-')}
                                        className={`block cursor-pointer p-3 rounded-md ${aircraftNature === category.name ? 'bg-fts-gold text-fts-blue' : 'bg-white text-gray-800'}`}
                                    >
                                        <span className="font-bold text-lg">{category.name}</span>
                                        {aircraftNature === category.name && category.sub_categories.length > 1 && (
                                            <div className="mt-3">
                                                {category.sub_categories.map(subCategory => (
                                                    <div key={subCategory.sub_category_id} className="mt-3">
                                                        <input
                                                            type="radio"
                                                            id={`${category.name.toLowerCase().replace(/\s+/g, '-')}-${subCategory.sub_request_name.toLowerCase().replace(/\s+/g, '-')}`}
                                                            name="subCategory"
                                                            value={subCategory.sub_request_name}
                                                            checked={subRequestName === subCategory.sub_request_name}
                                                            onChange={handleFlightTypeChange}
                                                            className="hidden"
                                                        />
                                                        <label
                                                            htmlFor={`${category.name.toLowerCase().replace(/\s+/g, '-')}-${subCategory.sub_request_name.toLowerCase().replace(/\s+/g, '-')}`}
                                                            className={`block cursor-pointer p-2 rounded-md ${subRequestName === subCategory.sub_request_name ? 'bg-fts-blue text-fts-gold' : 'bg-white text-gray-800'}`}
                                                        >
                                                            {subCategory.sub_request_name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </label>
                                </div>
                            ))}
                        </div>
                    )}
                </div>


                    <div className="flex justify-between mt-4 mx-auto">
                        <button
                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                            type="button"
                            onClick={goBack}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                            {BACK}
                        </button>

                        {submitLoading ? (
                            <button
                                type="button"
                                className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                            >
                                <CircularProgress size={20}/>
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                            >
                                Next
                                <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                            </button>
                        )}
                    </div>

            </form>
        </div>
    );
};

export default RequiredService;
