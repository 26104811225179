import { useEffect } from 'react';

const useInactivityTimeout = (timeout = 20 * 60 * 1000) => {
    useEffect(() => {
        let timer;

        const resetTimer = () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                localStorage.removeItem('token');
                window.location.href = '/login';
            }, timeout);
        };

        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keydown', resetTimer);

        resetTimer();

        return () => {
            clearTimeout(timer);
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keydown', resetTimer);
        };
    }, [timeout]);
};

export default useInactivityTimeout;
