import React, {useEffect} from 'react';
import Footer from "../assets/components/footer";
import {Route, Router, Routes, useNavigate} from "react-router-dom";
import GeneralInformationForm from "./GeneralInformationForm";
import Landing from "../Landing";
import RequiredService from "./RequiredService";
import AircraftDetails from "./AircraftDetails";
import axios from "axios";
import {BACKEND_URL} from "../Default";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PermitType from "./PermitType";
import {Flight} from "@mui/icons-material";
import FlightSchedule from "./FlightSchedule";
import PassengerDocumentsUpload from "./PassengerDocumentsUpload";
import CargoDocumentsUpload from "./CargoDocumentsUpload";
import DgrDocumentsUpload from "./DGRDocumentsUpload";
import TermsAndCondition from "./TermsAndCondition";
import RequestSummary from "./RequestSummary";

const GuestHome = () => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();
        const fetchPermits = async () => {
            const token = localStorage.getItem('token');
            const userData = localStorage.getItem('user');
            if (token || userData) {
                  toast.info("You have already logged into the system", {
                    onClose: () => {
                        navigate('/dashboard');
                    }
                });
            }

        };

        fetchPermits();
    }, [navigate]);

    const containerStyle = {
        backgroundImage: 'url("https://ftsaero.com/wp-content/uploads/2023/07/flight-planning.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '100vh',
        width: '100%',
    };

    return (
        <>
            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={500} // Close after 2 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        <div style={containerStyle} className="flex flex-col min-h-screen">
            <div className="flex-grow bg-fts-blue/80 flex items-center justify-center overflow-y-auto">
                <Routes>
                    <Route exact path="/" element={<GeneralInformationForm/>} />
                    <Route exact path="/flight-purpose/:uniqueId" element={<RequiredService/>} />
                    <Route exact path="/aircraft-details/:uniqueId" element={<AircraftDetails/>} />
                    <Route exact path="/permit-type/:uniqueId" element={<PermitType/>} />
                    <Route exact path="/flight-schedule/:uniqueId" element={<FlightSchedule/>} />
                    <Route exact path="/passenger-documents-upload/:uniqueId" element={<PassengerDocumentsUpload/>} />
                    <Route exact path="/cargo-documents-upload/:uniqueId" element={<CargoDocumentsUpload/>} />
                    <Route exact path="/dgr-documents-upload/:uniqueId" element={<DgrDocumentsUpload/>} />
                    <Route exact path="/terms-and-conditions/:uniqueId" element={<TermsAndCondition/>} />
                    <Route exact path="/request-summary/:uniqueId" element={<RequestSummary/>} />
                </Routes>
            </div>
            <div className="flex-none">
                <Footer />
            </div>
        </div>
            </>
    );
};

export default GuestHome;
