import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendar, faChevronDown, faChevronRight, faHome,
    faList, faPlane, faSignOut, faBars, faLock, faClipboardList, faMultiply, faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { Link, useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import react-confirm-alert module
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../css/custom-alert.css';

import logo from '../images/branding/SkyPermits.jpg';
import axios from "axios";
import {BACKEND_URL} from "../../Default";
import {toast} from "react-toastify";

const Sidebar = ({ isCollapsed, toggleSidebar, user:propUser }) => {
    const navigate = useNavigate();
    const [openMenus, setOpenMenus] = useState({});
    const [user, setUser] = useState(propUser || JSON.parse(localStorage.getItem('user')));
    const toggleMenu = (menu) => {
        setOpenMenus(prevState => ({ ...prevState, [menu]: !prevState[menu] }));
    };

    const handleSignOut = () => {

        confirmAlert({
            title: 'Confirm Sign Out?',
            // message: 'Are you sure you want to sign out?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        localStorage.clear();
                        navigate('/sign-in');
                    }
                },
                {
                    label: 'No',
                    onClick: () => {} // Do nothing if "No" is clicked
                }
            ]
        });

    };

    const handleNavigation = () => {
        localStorage.removeItem('requiredServices');
        localStorage.removeItem('request_id');
        navigate('/permit/new');
    };

    return (
        <aside className={`bg-fts-blue h-auto text-white p-4 transition-all duration-300 ${isCollapsed ? 'w-16' : 'w-64'}`}>

            {isCollapsed &&
                <div className={`flex ${isCollapsed ? 'justify-center' : 'justify-between'} items-center mb-6`}>
                    <button onClick={toggleSidebar} className="focus:outline-none">
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                </div>
            }

            {!isCollapsed &&
            <div className={`flex ${isCollapsed ? 'justify-center' : 'justify-between'} items-center mb-6`}>
                <button onClick={toggleSidebar} className="focus:outline-none">
                    <FontAwesomeIcon icon={faMultiply} />
                </button>
            </div>
            }

            <div className="flex justify-between items-center mb-6">
                {!isCollapsed && <img src={logo} alt="FTS Company Logo" className="bg-neutral-200 rounded" />}
            </div>
            <nav>
                <ul className="space-y-2">
                    <li className="mt-12">
                        <Link to={`/dashboard`}>
                            <div
                                className="flex items-center justify-between py-2 px-3 hover:bg-gray-700/70 hover:backdrop-blur-lg rounded-2xl cursor-pointer"
                            >
                                <div className="flex items-center">
                                    <FontAwesomeIcon icon={faHome} className="mr-2"/>
                                    {!isCollapsed && <span>Home</span>}
                                </div>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <hr/>
                    </li>

                    <li>
                        <div className="flex items-center justify-between py-1 pb-0 px-3 ">
                            <div className="flex items-start">
                                {!isCollapsed && <span className="text-xs">PERMITS</span>}
                            </div>
                        </div>
                    </li>

                    <div onClick={handleNavigation}>
                        <li className="mt-1">
                            <div
                                className="flex items-center justify-between py-2 px-3 hover:bg-gray-700/70 hover:backdrop-blur-lg rounded-2xl cursor-pointer"
                            >
                                <div className="flex items-center justify-center">
                                    <FontAwesomeIcon icon={faFile} className="mr-2"/>
                                    {!isCollapsed && <span>New Permits</span>}
                                </div>
                            </div>
                        </li>
                    </div>

                    <Link to={`/permit/management`}>
                        <li className="mt-1">
                            <div
                                className="flex items-center justify-between py-2 px-3 hover:bg-gray-700/70 hover:backdrop-blur-lg rounded-2xl cursor-pointer"
                            >
                                <div className="flex items-center">
                                    <FontAwesomeIcon icon={faClipboardList} className="mr-2"/>
                                    {!isCollapsed && <span>Track Permits</span>}
                                </div>
                            </div>
                        </li>
                    </Link>

                    <li>
                        <hr/>
                    </li>

                    <li>
                        <div className="flex items-center justify-between py-1 pb-0 px-3 ">
                            <div className="flex items-start">
                                {!isCollapsed && <span className="text-xs">AIRCRAFT</span>}
                            </div>
                        </div>
                    </li>

                    <Link to={`/aircraft/management`}>
                        <li className="mt-1">
                            <div
                                className="flex items-center justify-between py-2 px-3 hover:bg-gray-700/70 hover:backdrop-blur-lg rounded-2xl cursor-pointer"
                            >
                                <div className="flex items-center">
                                    <FontAwesomeIcon icon={faPlane} className="mr-2"/>
                                    {!isCollapsed && <span>Manage Aircraft</span>}
                                </div>
                            </div>
                        </li>
                    </Link>

                    {/*<Link to={``}>*/}
                    {/*    <li className="mt-1">*/}
                    {/*        <div*/}
                    {/*            className="flex items-center justify-between py-2 px-3 hover:bg-gray-700/70 hover:backdrop-blur-lg rounded-2xl cursor-pointer"*/}
                    {/*        >*/}
                    {/*            <div className="flex items-center">*/}
                    {/*                <FontAwesomeIcon icon={faCalendar} className="mr-2"/>*/}
                    {/*                {!isCollapsed && <span>Aircraft Schedule</span>}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}
                    {/*</Link>*/}


                    <li>
                        <hr/>
                    </li>

                    <li>
                        <div className="flex items-center justify-between py-1 pb-0 px-3 ">
                            <div className="flex items-start">
                                {!isCollapsed && <span className="text-xs">SYSTEM</span>}
                            </div>
                        </div>
                    </li>

                    <Link to={`/system/logs`}>
                        <li className="mt-1">
                            <div
                                className="flex items-center justify-between py-2 px-3 hover:bg-gray-700/70 hover:backdrop-blur-lg rounded-2xl cursor-pointer"
                            >
                                <div className="flex items-center">
                                    <FontAwesomeIcon icon={faList} className="mr-2"/>
                                    {!isCollapsed && <span>User Actions</span>}
                                </div>
                            </div>
                        </li>
                    </Link>

                    <Link to={`/system/profile`}>
                        <li className="mt-1">
                            <div
                                className="flex items-center justify-between py-2 px-3 hover:bg-gray-700/70 hover:backdrop-blur-lg rounded-2xl cursor-pointer"
                            >
                                <div className="flex items-center capitalize">
                                    <FontAwesomeIcon icon={faUserCircle} className="mr-2"/>
                                    {!isCollapsed && <span>{user && user.username}'s Profile</span>}
                                </div>
                            </div>
                        </li>
                    </Link>


                    <li>
                        <hr/>
                    </li>

                    <li>
                        <div onClick={handleSignOut}>
                            <div
                                className="flex items-center justify-between py-2 px-3 hover:bg-gray-700/70 hover:backdrop-blur-lg rounded-2xl cursor-pointer">
                                <div className="flex items-center">
                                    <FontAwesomeIcon icon={faSignOut} className="mr-2"/>
                                    {!isCollapsed && <span>Sign Out</span>}
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
        </aside>
    );
};

export default Sidebar;
