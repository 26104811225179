import React, { useEffect, useState } from 'react';
import {useParams, useNavigate, Link} from 'react-router-dom';
import { HomeIcon } from "@heroicons/react/16/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowLeft, faArrowRight, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import {
    Card,
    CardContent,
    CircularProgress,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button
} from '@mui/material';
import Breadcrumb from "../../assets/components/breadcrumb";
import { BACK } from "../../assets/wordings/constants";
import axios from "axios";
import { BACKEND_URL } from "../../Default";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Airports from '../../assets/data/Airports.json';


function RequestSummary() {
    const { requestId } = useParams();
    const navigate = useNavigate();
    const [permitData, setPermitData] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [type, setType] = useState(null);

    useEffect(() => {

        const storedType = JSON.parse(localStorage.getItem('type'));
        setType(storedType || null);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/permit/get/detailed/${requestId}`);
                const data = response.data;

                const mappedLandings = data.landings.map(landing => {
                    const departureAirport = Airports.find(airport => airport.id === landing.departure_airport.airport_id);
                    const arrivalAirport = Airports.find(airport => airport.id === landing.arrival_airport.airport_id);
                    return {
                        ...landing,
                        departure_airport: departureAirport,
                        arrival_airport: arrivalAirport,
                    };
                });

                setPermitData({
                    ...data,
                    landings: mappedLandings,
                });
            } catch (error) {
                console.error('Error fetching permit data:', error);
            }
        };

        fetchData();
    }, [requestId]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitLoading(true);

        const formData = new FormData();
        formData.append('client_id', JSON.parse(localStorage.getItem('user')).id);
        formData.append('request_id', requestId);
        formData.append('status', 'submitted');

        try {
            const response = await axios.post(`${BACKEND_URL}/permit/permit-complete`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            toast.success(response.data.message, {
                onClose: () => {
                    setSubmitLoading(false);
                    navigate('/permit/management');
                }
            });
        } catch (error) {
            console.error('Error sending data to backend:', error);
            setSubmitLoading(false);
        }
    };

    const goBack = () => {
        navigate('/permit/new/terms-and-conditions/' + requestId);
    };

    const renderLandingDetails = () => (
        <TableContainer component={Paper} className="mb-4">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Flight No</strong></TableCell>
                        <TableCell><strong>Departure Airport</strong></TableCell>
                        <TableCell><strong>Departure Date</strong></TableCell>
                        <TableCell><strong>Departure Time</strong></TableCell>
                        <TableCell><strong>Arrival Airport</strong></TableCell>
                        <TableCell><strong>Arrival Date</strong></TableCell>
                        <TableCell><strong>Arrival Time</strong></TableCell>
                        <TableCell><strong>Landing Permit Requested</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {permitData.landings.map((landing, index) => (
                        <TableRow key={index}>
                            <TableCell>{landing.flight_no}</TableCell>
                            <TableCell>{landing.departure_airport.airport_name} - {landing.departure_airport.icao_code}</TableCell>
                            <TableCell>{new Date(landing.departure_date).toLocaleDateString()}</TableCell>
                            <TableCell>{landing.departure_time.slice(0, 5)}</TableCell>
                            <TableCell>{landing.arrival_airport.airport_name} - {landing.arrival_airport.icao_code}</TableCell>
                            <TableCell>{new Date(landing.arrival_date).toLocaleDateString()}</TableCell>
                            <TableCell>{landing.arrival_time.slice(0, 5)}</TableCell>

                            <TableCell>
                                {landing.landing === '1' ? 'Yes' : landing.landing === '0' ? 'No' : '-'}
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderOverflyingDetails = () => (
        <TableContainer component={Paper} className="mb-4">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Flight No</strong></TableCell>
                        <TableCell><strong>Country</strong></TableCell>
                        <TableCell><strong>Entry Airspace</strong></TableCell>
                        <TableCell><strong>Entry Date</strong></TableCell>
                        <TableCell><strong>Entry Time</strong></TableCell>
                        <TableCell><strong>Exit Airspace</strong></TableCell>
                        <TableCell><strong>Exit Date</strong></TableCell>
                        <TableCell><strong>Exit Time</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {permitData.overflyings.map((overflying, index) => (
                        <TableRow key={index}>
                            <TableCell>{overflying.flightNo}</TableCell>
                            <TableCell>{overflying.country.country_name}</TableCell>
                            <TableCell>{overflying.entry_airspace}</TableCell>
                            <TableCell>{overflying.entry_date.split('T')[0]}</TableCell>
                            <TableCell>{overflying.entry_time.split('T')[0]}</TableCell>
                            <TableCell>{overflying.exit_airspace}</TableCell>
                            <TableCell>{overflying.exit_date.split('T')[0]}</TableCell>
                            <TableCell>{overflying.exit_time.split('T')[0]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderAircraftDetails = (aircraft) => (
        <div className="mb-4 grid grid-cols-2 gap-4">
            <div>
                <Table>
                    <TableRow>
                        <TableCell variant="h6" className="font-bold "><strong>Selected Registration No:</strong></TableCell>
                        <TableCell>
                            <Typography variant="body1" className="mb-2"> {aircraft.aircraft_registration || '-'}</Typography>
                        </TableCell>
                    </TableRow>

                </Table>
            </div>

        </div>
    );

    const renderAddOnServices = (addOns) => {
        // Parse addOns if it's a string
        if (typeof addOns === 'string') {
            addOns = JSON.parse(addOns);
        }

        // Ensure it's an array before mapping
        if (Array.isArray(addOns)) {
            return (
                <div className="mb-4 grid grid-cols-4">
                    {addOns.map((service, index) => (
                        <div key={index} className="flex items-center space-x-2">
                            <FontAwesomeIcon icon={faCheckSquare} />
                            <p>{service}</p>
                        </div>
                    ))}
                </div>
            );
        } else {
            return <div>No add-on services selected</div>;
        }
    };



    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Permit', url: '' },
        { label: 'New', url: '/permit/new' },
    ];

    if (!permitData) {
        return <CircularProgress />;
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="flex flex-col h-full">
                <main className="flex-grow">
                    <div className="w-full min-w-full px-4">
                        <div className="mt-3">
                            <Breadcrumb items={breadcrumbItems} />
                        </div>
                        <div className="mt-3 justify-center text-center">
                            <h1 className="font-bold text-gray-800 text-xl">Request Overview - Ref No #{permitData.request_id}</h1>
                        </div>
                        <div className="mt-6">
                            <form onSubmit={handleSubmit} className="text-gray-800">
                                <Card className="mb-6">
                                    <CardContent>
                                        <div className="grid grid-cols-2">
                                            <div>
                                                <Typography variant="h6" className="font-bold mb-4">Request Details</Typography>
                                                <Table className=" mb-4">

                                                        <TableRow>
                                                            <TableCell><strong>Purpose of Flight</strong></TableCell>
                                                            <TableCell>
                                                                <Typography variant="body1"> {permitData.request_category_name || '-'}</Typography>
                                                            </TableCell>

                                                        </TableRow>


                                                        <TableRow>
                                                            <TableCell><strong>Flight Category</strong></TableCell>
                                                            <TableCell>
                                                                <Typography variant="body1" > {permitData.sub_request_name || '-'}</Typography>
                                                            </TableCell>
                                                        </TableRow>



                                                    {type.toLowerCase() === "cargo" && (
                                                        <TableRow>
                                                            <TableCell><strong>DGR</strong></TableCell>
                                                            <TableCell>
                                                                <Typography variant="body1">
                                                                    {permitData.isDGR === 1 ? 'Yes' : 'No'}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                    }

                                                </Table>
                                            </div>
                                            <div>

                                                <Typography variant="h6" className="font-bold mb-4">Operator Details</Typography>

                                                <Table>
                                                    <TableRow>
                                                        <TableCell><strong>Name:</strong></TableCell>
                                                        <TableCell><Typography variant="body1" className="mb-2"> {permitData.operator?.operator_name || '-'}</Typography></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell><strong>Address:</strong></TableCell>
                                                        <TableCell><Typography variant="body1" className="mb-2"> {permitData.operator?.operator_address || '-'}</Typography></TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell><strong>Email:</strong></TableCell>
                                                        <TableCell><Typography variant="body1" className="mb-2"> {permitData.operator?.operator_email || '-'}</Typography></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell><strong>Phone:</strong></TableCell>
                                                        <TableCell><Typography variant="body1" className="mb-2">{`${permitData.operator?.operator_country_code || '-'} ${permitData.operator?.operator_contact_no || '-'}`}</Typography></TableCell>

                                                    </TableRow>
                                                </Table>

                                            </div>

                                        </div>

                                        <Typography variant="h6" className="font-bold mb-4">Add On Services</Typography>
                                        {renderAddOnServices(permitData.selected_services)}

                                        {permitData.alternate_aircrafts.length > 0 ? <Typography variant="h6" className="font-bold mb-4">Selected Alternate Aircraft Details</Typography> : <Typography></Typography>}
                                        {permitData.alternate_aircrafts.length > 0 ? permitData.alternate_aircrafts.map((alt, index) => renderAircraftDetails(alt.aircraft)) : <Typography></Typography>}

                                        {permitData.landings.length > 0 ? <Typography variant="h6" className="font-bold mb-4 mt-2">Schedule</Typography>: <></>}
                                        {permitData.landings.length > 0 ? renderLandingDetails() : <></>}

                                        {permitData.overflyings.length > 0 ?<Typography variant="h6" className="font-bold mb-4 mt-2">Overflying Details</Typography> : <></>}
                                        {permitData.overflyings.length > 0 ? renderOverflyingDetails() : <></>}

                                        <Typography variant="h6" className="font-bold mb-4 mt-2">Other Required Information</Typography>

                                        {permitData.passengers.length > 0 ? (
                                            permitData.passengers.map((passenger, index) => (
                                                <div>
                                                    <Typography variant="h6" className="font-bold mb-4">Passenger Details</Typography>
                                                    <div key={index} className="flex items-center">
                                                    <Typography  variant="body1" className="mb-2">
                                                        <strong>Passenger Document {index + 1}:</strong>
                                                        {passenger.document?.document_name || '-'}</Typography>
                                                        <a
                                                            href={`${BACKEND_URL}/${passenger.document?.file_path}`}
                                                            target="_blank"
                                                            className="bg-fts-blue text-white py-2 px-4 rounded-2xl ml-3 hover:bg-fts-gold"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View
                                                        </a>

                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <Typography>No files attached</Typography>
                                        )}


                                        {permitData.cargos.length > 0 ? (
                                            permitData.cargos.map((cargo, index) => (
                                                <div>
                                                    <Typography variant="h6" className="font-bold mb-4">Cargo Details</Typography>
                                                    <div key={index} className="flex items-center">
                                                        <Typography  variant="body1" className="mb-2"><strong>Cargo Document {index + 1}:</strong> {cargo.document?.document_name || '-'}</Typography>
                                                        <a
                                                            href={`${BACKEND_URL}/${cargo.document?.file_path}`}
                                                            target="_blank"
                                                            className="bg-fts-blue text-white py-2 px-4 rounded-2xl ml-3 hover:bg-fts-gold"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View
                                                        </a>

                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <Typography></Typography>
                                        )}





                                        {permitData.dgrs.length > 0 ? (
                                            <div>
                                                <Typography variant="h6" className="font-bold mb-4 mt-4">DGR Details</Typography>
                                                {permitData.dgrs.map((dgr, index) => (
                                                <div>

                                                    <div key={index} className="flex items-center">
                                                        <Typography  variant="body1" className="mb-2"><strong>DGR Document {index + 1}:</strong> {dgr.document?.document_name || '-'}</Typography>
                                                        <a
                                                            href={`${BACKEND_URL}/${dgr.document?.file_path}`}
                                                            target="_blank"
                                                            className="bg-fts-blue text-white py-2 px-4 rounded-2xl ml-3 hover:bg-fts-gold"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View
                                                        </a>

                                                    </div>
                                                </div>
                                            ))}
                                            </div>
                                        ) : (
                                            <Typography></Typography>
                                        )}
                                    </CardContent>
                                </Card>

                                <div className="flex justify-between mt-4 mb-2">
                                    <button
                                        className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        type="button"
                                        onClick={goBack}
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                                        {BACK}
                                    </button>

                                    {submitLoading ? (
                                        <button
                                            type="button"
                                            className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        >
                                            <CircularProgress size={20}/>
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        >
                                            Submit
                                            <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default RequestSummary;
