import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/16/solid";
import Breadcrumb from "../../assets/components/breadcrumb";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BACKEND_URL } from '../../Default';
import { BACK } from '../../assets/wordings/constants';
import axios from 'axios';
import { CircularProgress, Paper, Table, TableCell, TableContainer, TableRow, TableHead, TableBody } from "@mui/material";

function AddOnServices() {
    const navigate = useNavigate();
    const { requestId } = useParams();
    const [type, setType] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [subPurpose, setSubPurpose] = useState(null);
    const [purpose, setPurpose] = useState(null);
    const [existingData, setExistingData] = useState(null);

    useEffect(() => {
        const storedPurpose = JSON.parse(localStorage.getItem('purpose'));
        const storedType = JSON.parse(localStorage.getItem('type'));
        const subPurpose = JSON.parse(localStorage.getItem('subPurpose'));
        setSubPurpose(subPurpose || null);
        setPurpose(storedPurpose || null);
        setType(storedType || null);
    }, []);

    useEffect(() => {
        fetchAddOnServices();
        // Removed fetchExistingData from here to avoid premature fetching
    }, []);

    useEffect(() => {
        // Ensure services are fetched before fetching existing data
        if (services.length > 0) {
            fetchExistingData();
        }
    }, [services]); // Reacts when services are set

    useEffect(() => {
        // Update selected services based on existing data
        if (existingData && services.length > 0) {
            const existingServiceIds = services.filter(service => existingData.flat().includes(service.name)).map(service => service.id);
            setSelectedServices(existingServiceIds);
        }
    }, [existingData, services]); // Ensure this runs when either services or existing data changes



    const fetchAddOnServices = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${BACKEND_URL}/requests/add-on-services`);
            setServices(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching add-on services", error);
            setLoading(false);
        }
    };

    const fetchExistingData = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/permit/get-add-on/${requestId}`);
            setExistingData(response.data.flat()); // Flattens the array if nested
        } catch (error) {
            console.error("Error fetching existing add-on services:", error);
        }
    };



    const handleServiceChange = (serviceId) => {
        setSelectedServices(prevSelectedServices => {
            const isSelected = prevSelectedServices.includes(serviceId);
            if (isSelected) {
                return prevSelectedServices.filter(id => id !== serviceId);
            } else {
                return [...prevSelectedServices, serviceId];
            }
        });
    };

    const handleSelectAllChange = () => {
        setSelectAll(prevState => {
            if (!prevState) {
                setSelectedServices(services.map(service => service.id));
            } else {
                setSelectedServices([]);
            }
            return !prevState;
        });
    };


    const goBack = () => {
        if (type && type.toLowerCase() === "passenger") {
            navigate(`/permit/new/passenger-documents-upload/${requestId}`);
        } else if (type && type.toLowerCase() === "cargo") {
            navigate(`/permit/new/cargo-documents-upload/${requestId}`);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitLoading(true);

        const selectedServiceNames = services
            .filter(service => selectedServices.includes(service.id))
            .map(service => service.name);

        try {
            const response = await axios.post(`${BACKEND_URL}/permit/add-on-services/${requestId}`, selectedServiceNames);
            navigate(`/permit/new/terms-and-conditions/${requestId}`);
        } catch (error) {
            console.error("Error submitting add-on services", error);
        } finally {
            setSubmitLoading(false);
        }
    };



    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/dashboard' },
        { label: 'Permit', url: '' },
        { label: 'New', url: '/permit/new' },
    ];

    return (
        <div className="flex flex-col">
            <main className="flex-grow">
                <div className="w-full min-w-full px-4">
                    <div className="mt-3">
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                    <div className="mt-3">
                        <h1 className="font-bold text-gray-800 text-xl">Add On Services</h1>
                        <form onSubmit={handleSubmit} className="justify-center items-center">
                            <div className="mt-6 bg-white px-3 py-2 border border-gray-300 rounded-lg">
                                <h2>Select the required value added services</h2>
                                <div className="flex max-w-full justify-center items-center">
                                    <div className="mt-3 flex justify-between gap-5">
                                        <div>Purpose of Flight: <strong>{purpose}</strong></div>
                                        <div>Type of flight: <strong className="capitalize">{subPurpose}</strong></div>
                                    </div>
                                </div>
                                <div className="justify-center items-center mt-4">
                                    {loading ? (
                                        <CircularProgress/>
                                    ) : (
                                        <div className="mx-32">
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                <div className="flex items-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="selectAll"
                                                                        checked={selectAll}
                                                                        onChange={handleSelectAllChange}
                                                                        className="mr-2"
                                                                    />
                                                                    <label htmlFor="selectAll"
                                                                           className="text-gray-800 font-bold">Select
                                                                        All</label>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {services.map(service => (
                                                            <TableRow key={service.id}>
                                                                <TableCell>
                                                                    <div className="flex items-center">
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`service-${service.id}`}
                                                                            checked={selectedServices.includes(service.id)}
                                                                            onChange={() => handleServiceChange(service.id)}
                                                                            className="mr-2"
                                                                        />
                                                                        <label htmlFor={`service-${service.id}`}
                                                                               className="text-gray-800">
                                                                            {service.name}
                                                                        </label>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    )}
                                </div>

                                <div className="mt-6">
                                    <div className="flex justify-between mt-4">
                                        <button
                                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                            type="button"
                                            onClick={goBack}>
                                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                                            {BACK}
                                        </button>

                                        {submitLoading ? (
                                            <button type="button"
                                                    className="text-fts-blue  rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                                <CircularProgress size={20}/>
                                            </button>
                                        ) : (
                                            <button type="submit"
                                                    className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                                Next
                                                <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default AddOnServices;
