import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams} from "react-router-dom";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import {BACKEND_URL} from "../Default";

const CargoDocumentsUpload = () => {
    const { uniqueId } = useParams();
    const navigate = useNavigate();
    const [operatorData, setOperatorData] = useState({});
    const [sessionType, setSessionType] = useState(null);
    const [type, setType] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [airwayBills, setAirwayBills] = useState([]);
    const [generalDeclarations, setGeneralDeclarations] = useState([]);
    const [isDGR, setIsDGR] = useState(false);

    useEffect(() => {
        const fetchExistingData = () => {
            try {
                const response = localStorage.getItem('generalInformation');

                if (response) {
                    const existingData = JSON.parse(response);
                    console.log(existingData);
                    const session = parseInt(existingData.permitType, 10); // Ensure it's an integer
                    setSessionType(session);
                    setType(existingData.flightType || '');
                    setIsDGR(existingData.isDGR || false);
                    setAirwayBills(existingData.airwayBills || []);
                    setGeneralDeclarations(existingData.generalDeclarations || []);
                }
            } catch (error) {
                console.error('Error fetching existing data:', error);
            }
        };

        fetchExistingData();
    }, [navigate]);

    const handleFileChange = (setFileState) => (event) => {
        setFileState(Array.from(event.target.files));
    };

    const handleDGRChange = (event) => {
        setIsDGR(event.target.checked);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitLoading(true);

        let existingData = JSON.parse(localStorage.getItem('generalInformation')) || {};

        existingData = {
            ...existingData,
            isDGR,
            airwayBills,
            generalDeclarations,
        };

        function generateShortKey() {
            return Math.random().toString(36).substr(2, 5); // Generates a 5-character string
        }

        const formData = new FormData();

        formData.append('request_id', uniqueId);
        formData.append('operator_name', existingData.company_name);
        formData.append('guest_name', existingData.guest_name);
        formData.append('email', existingData.guest_email);
        formData.append('contact', `${existingData.country_code}${existingData.company_contact_number}`);
        formData.append('request_data', JSON.stringify(existingData));
        formData.append('request_status', 'incomplete');

        airwayBills.forEach((file, index) => {
            formData.append('files', file, `AirwayBill-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        generalDeclarations.forEach((file, index) => {
            formData.append('files', file, `GeneralDeclaration-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        setSubmitLoading(true);


        try {

            const response = await axios.post(`${BACKEND_URL}/guests/new`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            localStorage.setItem('generalInformation', JSON.stringify(existingData));

            if (isDGR) {
                navigate(`/guest/dgr-documents-upload/${uniqueId}`);
            } else {
                navigate(`/guest/terms-and-conditions/${uniqueId}`);
            }

            setSubmitLoading(false);

        } catch (error) {
            console.error(error);
            setSubmitLoading(false);
        }



        setSubmitLoading(false);
    };

    const goBack = () => {
        navigate(`/guest/flight-schedule/${uniqueId}`);
    };

    const renderFileNames = (files) => {
        return files.map((file, index) => (
            <p key={index} className="text-sm text-neutral-100">{file.name}</p>
        ));
    };

    return (
        <div className="w-full min-w-full px-4">
            <main>
                <div className="mt-3 text-center max-w-4xl mx-auto">
                    <h1 className="font-bold text-[48px] text-fts-gold">Cargo Documents Upload</h1>
                    <hr className="w-[90%] border-fts-gold border-2 my-4"/>
                </div>
                <div className="mt-3 max-w-4xl mx-auto">
                    <form onSubmit={handleSubmit} className="text-gray-800">
                        <div className="text-white py-2 px-4 rounded-md">
                            <h2>Fill in the following fields</h2>
                            <div className="grid grid-cols-1 gap-4 mx-12 px-12 mt-3 justify-center items-center">
                                <div className="mb-2 grid grid-cols-2 gap-2">
                                    <label>Airway Bill:</label>
                                    <div>
                                        <input
                                            type="file"
                                            multiple
                                            className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                            onChange={handleFileChange(setAirwayBills)}
                                        />
                                        {renderFileNames(airwayBills)}
                                    </div>
                                </div>

                                <div className="mb-2 grid grid-cols-2 gap-2">
                                    <label className="block">Crew General Declaration (GD):</label>
                                    <div>
                                        <input
                                            type="file"
                                            multiple
                                            className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                            onChange={handleFileChange(setGeneralDeclarations)}
                                        />
                                        {renderFileNames(generalDeclarations)}
                                    </div>
                                </div>

                                <div className="mb-2 flex items-center">
                                    <label>Is this flight <strong>DGR?</strong></label>
                                    <input
                                        type="checkbox"
                                        className="border border-gray-300 px-3 py-2 rounded-md ml-2 w-5 h-5"
                                        checked={isDGR}
                                        onChange={handleDGRChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between mt-4">
                            <button
                                className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                type="button"
                                onClick={goBack}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                                Back
                            </button>

                            {submitLoading ? (
                                <button type="button"
                                        className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                    <CircularProgress size={20}/>
                                </button>
                            ) : (
                                <button type="submit"
                                        className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                    Next
                                    <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </main>
        </div>
    );
};

export default CargoDocumentsUpload;
