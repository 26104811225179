import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/16/solid";
import Breadcrumb from "../../assets/components/breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faPlus,
  faTimes,
  faPlane
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { BACKEND_URL } from "../../Default";
import {
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Select from 'react-select';
import Home from "../Home";
import NewAircraft from "../NewAircraft";
import {BACK} from "../../assets/wordings/constants";
import Airports from "../../assets/data/Airports.json";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultFlight = {
  flightNumber: '',
  depAirport: null,
  depDate: '',
  depTime: '',
  arrAirport: null,
  arrDate: '',
  arrTime: '',
  landingRequired: false
};

const initialFlightSchedule = [
  { ...defaultFlight },
  // Add more initial flights if needed
];

const FlightSchedule = () => {
  const { requestId } = useParams();
  const navigate = useNavigate();
  const [purpose, setPurpose] = useState(null);
  const [type, setType] = useState(null);
  const [overflying, setOverflying] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const [landing, setLanding] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [requiredServices, setRequiredServices] = useState({});
  const [aircrafts, setAircrafts] = useState([]);
  const [aircraftLoading, setAircraftLoading] = useState(true);
  const [alternateAircrafts, setAlternateAircrafts] = useState([]);
  const [flightSchedule, setFlightSchedule] = useState(initialFlightSchedule);
  const [airspacePoints, setAirspacePoints] = useState([{ id: Date.now() }]);
  const [flightType, setFlightType] = useState([]);
  const [flightTypeLoading, setFlightTypeLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedAircraft, setSelectedAircraft] = useState("");
  const [selectedAlternateAircrafts, setSelectedAlternateAircrafts] = useState([]);
  const [airports, setAirports] = useState([]);
  const [airportLoading, setAirportLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [existingLandingData, setExistingLandingData] = useState([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [subPurpose, setSubPurpose] = useState(null);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    const storedPurpose = JSON.parse(localStorage.getItem('purpose'));
    const storedType = JSON.parse(localStorage.getItem('type'));
    const subPurpose = JSON.parse(localStorage.getItem('subPurpose'));
    setSubPurpose(subPurpose || null);
    setPurpose(storedPurpose || null);
    setType(storedType || null);

    const fetchAircraft = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/aircrafts/aircraft/${userData.id}`);
        setAircrafts(response.data);
        setAircraftLoading(false);
      } catch (error) {
        console.error('Error fetching aircraft:', error);
        setAircraftLoading(false); // Stop loading even on error
      }
    };

    const fetchAirports = async () => {
      try {
        const response = await fetch('/assets/data/Airports.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // console.log("Airport Data: ", data)
        setAirports(data);
      } catch (error) {
        console.error('Error fetching airport data:', error);
      } finally {
        setAirportLoading(false);
      }
    };

    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/requests/countries/all`);
        setCountries(response.data);
        setCountriesLoading(false);
      } catch (error) {
        console.error('Error fetching countries:', error);
        setAircraftLoading(false); // Stop loading even on error
      }
    };

    const fetchFlightType = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/permit/get/permit-status/${requestId}`);
        const data = response.data;
        setFlightType(data);
        setFlightTypeLoading(false);

        // Set landing and overflying based on received data
        const landingPermit = data.find(item => item.permit_type_id === 1);
        const overflyingPermit = data.find(item => item.permit_type_id === 2);
        const bothPermits = data.find(item => item.permit_type_id === 4);

        // If both permits are active, set both states to true
        if (landingPermit) {
          setLanding(true);
        } else if (overflyingPermit) {
          setSchedule(true);
          setOverflying(true);
        } else if (bothPermits) {
          setLanding(true);
          setOverflying(true);
        }

      } catch (error) {
        console.error('Error fetching permit types:', error);
        setFlightTypeLoading(false);
      }
    };


    const initializeData = async () => {
      await Promise.all([fetchAircraft(),
      fetchFlightType(),
      await fetchAirports(),
      await fetchCountries(),
      ]);


      fetchExistingOverflightData()
    };

    initializeData();


  }, [requestId]);

  useEffect(() => {
    fetchAircraft();

  }, [showModal]);

  useEffect(() => {
    fetchExistingLandingData();

  }, [airports]);

  useEffect(() => {
    fetchExistingOverflightData();

  }, [countries]);


  const fetchExistingOverflightData = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/permit/get/detailed/${requestId}`);
      const data = response.data;

      if (data && data.overflyings.length > 0) {
        if (data.overflyings) {
          const mappedPoints = data.overflyings.map(overflying => {

            const country = countries.find(country => country.id === overflying.country.country_id)

            console.log(country?.id);

            return {

              id: Date.now(), // Unique ID for new points
              country: country ? { value: country.id, label: country.country_name } : null,
              entryAirspace: overflying.entry_airspace,
              entryDate: overflying.entry_date.split('T')[0],
              entryTime: overflying.entry_time,
              exitAirspace: overflying.exit_airspace,
              exitDate: overflying.exit_date.split('T')[0],
              exitTime: overflying.exit_time,
              flightNo: overflying.flightNo,
            }
          });
          setAirspacePoints(mappedPoints);
        }
        setExistingLandingData(data.overflyings);
      }
    } catch (error) {
      console.error('Error fetching existing operators:', error);
    }
  };



  const fetchExistingLandingData = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/permit/get/detailed/${requestId}`);
      const data = response.data;

      console.log("Data: ", data)

      if (data && data.landings.length > 0) {
        const mappedData = data.landings.map((landing) => {
          const departureAirport = airports.find(
              (airport) => airport.id === landing.departure_airport?.airport_id
          );
          const arrivalAirport = airports.find(
              (airport) => airport.id === landing.arrival_airport?.airport_id
          );

          console.log("Dep Airp: ", departureAirport)

          return {
            flightNumber: landing.flight_no,
            depAirport: departureAirport
                ? {
                  label: `(${departureAirport.icao_code} | ${departureAirport.iata_code}) ${departureAirport.airport_name}`,
                  name: `${departureAirport.airport_name} (ICAO - ${departureAirport.icao_code} | IATA - ${departureAirport.iata_code})`,
                  value: departureAirport.id,
                }
                : null,
            depDate: landing.departure_date.split("T")[0],
            depTime: landing.departure_time,
            arrAirport: arrivalAirport
                ? {
                  label: `(${arrivalAirport.icao_code} | ${arrivalAirport.iata_code}) ${arrivalAirport.airport_name}`,
                  name: `${arrivalAirport.airport_name} (ICAO - ${arrivalAirport.icao_code} | IATA - ${arrivalAirport.iata_code})`,
                  value: arrivalAirport.id,
                }
                : null,
            arrDate: landing.arrival_date.split("T")[0],
            arrTime: landing.arrival_time,
            landingRequired: landing.landing === "1",
          };
        });

        setFlightSchedule(mappedData);
      } else {
        setFlightSchedule([{ ...defaultFlight }]);
      }




      if (data.selected_aircraft_schedule) {
        setSelectedAircraft(data.selected_aircraft_schedule.aircraft.aircraft_id);
      }

      if (data.alternate_aircrafts) {
        const initialAlternateAircrafts = data.alternate_aircrafts.map((alt) => ({
          id: alt.aircraft.aircraft_id,
          value: alt.aircraft.aircraft_id,
        }));
        setAlternateAircrafts(initialAlternateAircrafts.map(alt => ({ id: alt.id })));
        setSelectedAlternateAircrafts(initialAlternateAircrafts);
      }
    } catch (error) {
      console.error('Error fetching existing operators:', error);
      setFlightSchedule([{ ...defaultFlight }]);
    }
  };

  const fetchAircraft = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem('user'));
      const response = await axios.get(`${BACKEND_URL}/aircrafts/aircraft/${userData.id}`);
      setAircrafts(response.data);
      setAircraftLoading(false);
    } catch (error) {
      console.error('Error fetching aircraft:', error);
      setAircraftLoading(false); // Stop loading even on error
    }
  };



  const addAlternateAircraft = () => {
    setAlternateAircrafts([...alternateAircrafts, { id: Date.now() }]);
  };

  const removeAlternateAircraft = (id) => {
    setAlternateAircrafts(alternateAircrafts.filter(aircraft => aircraft.id !== id));
    setSelectedAlternateAircrafts(selectedAlternateAircrafts.filter(aircraft => aircraft.id !== id));
  };

  const addFlightRow = () => {
    setFlightSchedule((prevSchedule) => {
      const lastRow = prevSchedule[prevSchedule.length - 1];
      const newRow = lastRow ? { depAirport: lastRow.arrAirport } : {};
      return [...prevSchedule, newRow];
    });
  };

  const removeFlightRow = (index) => {
    const values = [...flightSchedule];
    values.splice(index, 1);
    setFlightSchedule(values);
  };

  const addAirspacePoint = () => {
    setAirspacePoints([...airspacePoints, { id: Date.now() }]);
  };

  const removeAirspacePoint = (id) => {
    setAirspacePoints(airspacePoints.filter(point => point.id !== id));
  };

  const breadcrumbItems = [
    { label: <HomeIcon className="h-4 w-5" />, url: "/" },
    { label: "Permit", url: "" },
    { label: "New", url: "/permit/new" },
  ];

  const [errors, setErrors] = useState({});

  const maskTime = (value) => {
    const strippedValue = value.replace(/[^0-9]/g, '');
    let hh = strippedValue.substring(0, 2);
    let mm = strippedValue.substring(2, 4);
    if (hh.length === 1 && hh > 2) {
      hh = '0' + hh;
    }
    if (hh.length === 2 && parseInt(hh, 10) > 23) {
      hh = '23';
    }
    if (mm.length === 2 && parseInt(mm, 10) > 59) {
      mm = '59';
    }
    return `${hh}:${mm}`;
  };


  const handleTimeChange = (index, event) => {
    const { name, value } = event.target;
    const maskedValue = maskTime(value);

    if (name === "depTime" || name === "arrTime") {
      const updatedSchedule = [...flightSchedule];
      updatedSchedule[index][name] = maskedValue;
      setFlightSchedule(updatedSchedule);
    } else if (name === "entryTime" || name === "exitTime") {
      const updatedPoints = [...airspacePoints];
      updatedPoints[index][name] = maskedValue;
      setAirspacePoints(updatedPoints);
    }
  };

  const handleTimeBlur = (index, event) => {
    validateTime(index, event);
  };

  const validateTime = (index, event) => {
    const { name, value } = event.target;
    const timePattern = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;
    if (!timePattern.test(value)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [`${name}-${index}`]: 'Invalid time format. Use hh:mm'
      }));
    } else {
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors[`${name}-${index}`];
        return newErrors;
      });
    }
  };


  const validateAircraft = () => {
    const selectedAircraftId = selectedAircraft;
    // console.log(selectedAircraftId);
    // console.log("selected: " ,selectedAlternateAircrafts);
    const alternateAircraftIds = selectedAlternateAircrafts.map(aircraft => aircraft.value);

    // Create a Set from alternate aircraft ids to ensure uniqueness
    const uniqueAlternateAircrafts = new Set(alternateAircraftIds);

    // Check if there are duplicates among the alternate aircrafts
    if (uniqueAlternateAircrafts.size !== alternateAircraftIds.length) {
      toast.info("Each alternate aircraft can only be selected once.");
      return false;
    }

    // Check if the selected aircraft is unique among the alternates
    if (alternateAircraftIds.includes(selectedAircraftId)) {
      toast.info("Selected aircraft cannot be the same as one of the alternates.");
      return false;
    }

    return true; // Return true if validation passes
  };


  const validateAirports = () => {
    for (let i = 0; i < flightSchedule.length; i++) {
      const flight = flightSchedule[i];
      if (flight.depAirport.value && flight.arrAirport.value && flight.depAirport.value === flight.arrAirport.value) {
        toast.info(`Departure and Arrival airports cannot be the same in the same sector.`);
        return false; // Return false to indicate validation failure
      }
    }

    return true; // Return true if validation passes
  };




  const isFormValid = () => {
    return Object.keys(errors).length === 0;
  };

  const handleFormValidationSubmit = (event) => {
    event.preventDefault();
    if (isFormValid()) {
      handleSubmit(event);
    } else {
      toast.error("Please correct the errors before submitting the form.");
    }
  };



  const handleInputChange = (index, event) => {
    const { name, value } = event.target;

    if (name === "depDate" || name === "arrDate") {
      const year = value.split("-")[0]; // Extract the year from the date
      if (year.length !== 4 || isNaN(year)) {
        toast.info("Please enter a valid year with 4 digits.");
        return;
      }
    }

    const values = [...flightSchedule];
    values[index][name] = value;
    setFlightSchedule(values);
  };


  const handleCheckboxChange = (index, event) => {
    const { checked } = event.target;
    const values = [...flightSchedule];
    values[index].landingRequired = checked;
    setFlightSchedule(values);
  };

  const handleSelectChange = (index, selectedOption, name) => {
    // Create a copy of the current flightSchedule state
    const values = [...flightSchedule];

    // Check if selectedOption is valid
    if (selectedOption) {
      // Update the specific field (depAirport or arrAirport) in the specified index
      values[index] = { ...values[index], [name]: selectedOption };

      // Log the updated values for debugging
      // console.log('Updated values:', values);

      // Update the state with the new values
      setFlightSchedule(values);
    } else {
      console.warn('Invalid selectedOption:', selectedOption);
    }
  };

  const handleAircraftChange = (event) => {
    const aircraftId = event.target.value; // Get the selected aircraft ID
    setSelectedAircraft(aircraftId); // Set the state with the aircraft ID
  };


  const handleAlternateAircraftChange = (index, event) => {
    const values = [...selectedAlternateAircrafts];
    values[index] = { id: alternateAircrafts[index].aircraft_id, value: event.target.value };
    setSelectedAlternateAircrafts(values);
  };

  const handleAirspacePointChange = (index, value, field) => {
    const updatedPoints = airspacePoints.map((point, idx) => {
      if (idx === index) {
        return {
          ...point,
          [field]: value,
        };
      }
      return point;
    });
    setAirspacePoints(updatedPoints);

  };

  const goBack = () => {
    navigate(`/permit/new/permit-type/${requestId}`);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitLoading(true);

    const updatedServices = {
      ...requiredServices,
      schedule: {
        aircraft: selectedAircraft,
        alternateAircraft: selectedAlternateAircrafts,
        flightNumber: flightSchedule[0]?.flightNumber || '', // Assuming you want to include the first flight number
        landing: flightSchedule.length > 0 ? flightSchedule : null,
        overflying: airspacePoints.length > 0 ? airspacePoints : null,
      },
    };

    console.log(updatedServices);

    const formData = new FormData();
    formData.append('client_id', JSON.parse(localStorage.getItem('user')).id);
    formData.append('request_id', requestId);
    formData.append('request_data', JSON.stringify(updatedServices));
    formData.append('status', 'incomplete')


    try {
      // Validate aircraft and airports before proceeding
      const isAircraftValid = validateAircraft();
      const isAirportsValid = validateAirports();
      const isAnyCheckboxChecked = flightSchedule.some(flight => flight.landingRequired);

      if (!isAnyCheckboxChecked && landing) {
        // Show a toast notification if no checkbox is selected
        toast.info("At least one checkbox must be selected.");
        setSubmitLoading(false);
        return;
      }

      if (!isAircraftValid || !isAirportsValid) {
        setSubmitLoading(false);
        return; // Stop submission if validation fails
      }
        try {
          await axios.post(`${BACKEND_URL}/permit/flight-schedule`, formData);
          setSubmitLoading(false);

          if (type && type.toLowerCase() === "passenger") {
            navigate(`/permit/new/passenger-documents-upload/${requestId}`);
          } else if (type && type.toLowerCase() === "cargo"){
            navigate(`/permit/new/cargo-documents-upload/${requestId}`);
          }
        } catch (error) {
          console.error('Error submitting form:', error);
          setSubmitLoading(false);
        }
    } catch (error) {
      toast.error(error.message || "Try again later");
      setSubmitLoading(false);
    }


  };

  return (
      <>
        <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
      <div className="w-full min-w-full px-4">
        <main>
          <div className="mt-3">
            <Breadcrumb items={breadcrumbItems} />
          </div>
          <div className="mt-3">
            <h1 className="font-bold text-gray-800 text-xl">Flight Schedule</h1>
          </div>
          <div className="mt-3">
            <form onSubmit={handleFormValidationSubmit}>
              <div className="bg-white rounded-md px-2 py-2">
              <div className="bg-white rounded-md px-2 py-1">
                <div className="flex max-w-full justify-center items-center">
                  <div className="mt-3 flex justify-between gap-5">
                    <div>
                      Purpose of Flight: <strong>{purpose}</strong>
                    </div>
                    <div>
                      Type of flight: <strong className="capitalize">{subPurpose}</strong>
                    </div>
                  </div>
                </div>
                <h5 className="text-sm font-bold">Aircraft Details</h5>

                <div className="grid grid-cols-6 gap-2 items-end">
                  <div>
                    <label className="text-xs">Select Aircraft</label>
                    <select
                        value={selectedAircraft}
                        onChange={handleAircraftChange}
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                    >
                      <option value="">Select Aircraft</option>
                      {aircraftLoading ? (
                          <option value="" disabled className="flex justify-center items-center">
                            Loading...
                          </option>
                      ) : (
                          aircrafts.map((aircraft) => (
                              <option key={aircraft.aircraft_id} value={aircraft.aircraft_id}>
                                {aircraft.aircraft_registration}
                              </option>
                          ))
                      )}
                    </select>

                  </div>

                  <div >
                    <button
                        type="button"
                        aria-placeholder="Add Aircraft"
                        className="bg-yellow-600 hover:bg-yellow-900 px-2 py-1 rounded text-xs text-gray-100 mr-2 h-1/2 ml-2"
                        onClick={() => setShowModal(true)}
                    >
                      <FontAwesomeIcon icon={faPlus} className="mr-3"/>
                      <FontAwesomeIcon icon={faPlane} className="mr-3"/>
                    </button>
                  </div>

                  <div className="col-span-4 grid grid-cols-4 gap-2">
                    {alternateAircrafts.map((aircraft, index) => (
                        <div className="flex justify-between items-center" key={aircraft.aircraft_id}>
                          <div>
                            <label className="text-xs">Alternate Aircraft {index + 1}</label>
                            <select
                                required
                                value={selectedAlternateAircrafts[index]?.value || ""}
                                onChange={(event) => handleAlternateAircraftChange(index, event)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                            >
                              <option value="">Select Aircraft</option>
                              {aircrafts.map((aircraft) => (
                                  <option key={aircraft.aircraft_id} value={aircraft.aircraft_id}>
                                    {aircraft.aircraft_registration}
                                  </option>
                              ))}
                            </select>
                          </div>
                          <button
                              type="button"
                              onClick={() => removeAlternateAircraft(aircraft.id)}
                              className="ml-2 text-red-500 hover:text-red-700"
                          >
                            <FontAwesomeIcon icon={faTimes}/>
                          </button>
                        </div>
                    ))}
                  </div>


                </div>

                <div className="mt-0 mb-1">
                  <button
                      type="button"
                      onClick={addAlternateAircraft}
                      className="text-blue-500 hover:text-blue-700 text-xs"
                  >
                    Add Alternate Aircraft
                  </button>
                </div>
              </div>

              <hr/>

              {landing && (
                  <div className="bg-white rounded-md px-2 py-1 mt-1">
                    <div className="flex justify-between items-center mt-1">
                      <h5 className="text-sm font-bold">Landing Permit</h5>
                      <button
                          type="button"
                          onClick={addFlightRow}
                          className="bg-green-500 hover:bg-green-700 text-xs text-white py-1 px-2 rounded"
                      >
                        Add Sector
                      </button>
                    </div>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <strong>FLT No.</strong>
                            </TableCell>
                            <TableCell width="100px">

                            </TableCell>
                            <TableCell width="250px">
                              <strong>Airport</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Date</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Time (UTC)</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Landing Permit?</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Action</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {flightSchedule.map((flight, index) => (
                              <React.Fragment key={index}>
                                {/* First Row for Departure */}
                                <TableRow>
                                  <TableCell rowSpan={2} width="100px">
                                    <input
                                        type="text"
                                        required
                                        name="flightNumber"
                                        value={flight.flightNumber || ''}
                                        onChange={(event) => handleInputChange(index, event)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                    />
                                  </TableCell>
                                  <TableCell width="100px">
                                    <strong>Departure</strong>
                                  </TableCell>
                                  <TableCell width="250px">
                                    <Select
                                        name="depAirport"
                                        required
                                        value={flight.depAirport}
                                        onChange={(selectedOption) => handleSelectChange(index, selectedOption, 'depAirport')}
                                        options={airports.map((airport) => ({
                                          value: airport.id,
                                          name: `${airport.airport_name} (ICAO - ${airport.icao_code} | IATA - ${airport.iata_code})`,
                                          label: `(${airport.icao_code} | ${airport.iata_code}) ${airport.airport_name}`,
                                        }))}
                                        className="text-sm"
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={{
                                          menuPortal: (base) => ({ ...base, zIndex: 9999, width: '30%' }),
                                        }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <input
                                        type="date"
                                        required
                                        name="depDate"
                                        value={flight.depDate || ''}
                                        onChange={(event) => handleInputChange(index, event)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <input
                                        type="text"
                                        name="depTime"
                                        value={flight.depTime || 'hh:mm'}
                                        onChange={(event) => handleTimeChange(index, event)}
                                        onBlur={(event) => handleTimeBlur(index, event)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                        placeholder="hh:mm"
                                    />
                                    {errors[`depTime-${index}`] && (
                                        <div className="text-red-500 text-xs">
                                          {errors[`depTime-${index}`]}
                                        </div>
                                    )}
                                  </TableCell>

                                  <TableCell >
                                  </TableCell>


                                  <TableCell rowSpan={2} width="50px">
                                    <button
                                        type="button"
                                        onClick={() => removeFlightRow(index)}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                      <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                  </TableCell>
                                </TableRow>

                                {/* Second Row for Arrival */}
                                <TableRow>
                                  <TableCell width="100px">
                                    <strong>Arrival</strong>
                                  </TableCell>
                                  <TableCell width="250px">
                                    <Select
                                        name="arrAirport"
                                        value={flight.arrAirport}
                                        required
                                        onChange={(selectedOption) => handleSelectChange(index, selectedOption, 'arrAirport')}
                                        options={airports.map((airport) => ({
                                          value: airport.id,
                                          name: `${airport.airport_name} (ICAO - ${airport.icao_code} | IATA - ${airport.iata_code})`,
                                          label: `(${airport.icao_code} | ${airport.iata_code}) ${airport.airport_name}`,
                                        }))}
                                        className="text-sm"
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={{
                                          menuPortal: (base) => ({ ...base, zIndex: 9999, width: '30%' }),
                                        }}
                                    />

                                  </TableCell>
                                  <TableCell width="100px">
                                    <input
                                        type="date"
                                        name="arrDate"
                                        value={flight.arrDate || ''}
                                        onChange={(event) => handleInputChange(index, event)}
                                        required
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                    />
                                  </TableCell>
                                  <TableCell width="100px">
                                    <input
                                        type="text"
                                        required
                                        name="arrTime"
                                        value={flight.arrTime || 'hh:mm'}
                                        onChange={(event) => handleTimeChange(index, event)}
                                        onBlur={(event) => handleTimeBlur(index, event)}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                        placeholder="hh:mm"
                                    />
                                    {errors[`arrTime-${index}`] && (
                                        <div className="text-red-500 text-xs">
                                          {errors[`arrTime-${index}`]}
                                        </div>
                                    )}
                                  </TableCell>
                                  <TableCell width="50px">
                                    <Checkbox
                                        checked={flight.landingRequired || false}
                                        onChange={(event) => handleCheckboxChange(index, event)}
                                    />
                                  </TableCell>

                                </TableRow>
                              </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>



                  </div>
              )}

              {overflying && (
                  <div>
                  <div className="grid grid-cols-4 gap-1 mt-1 bg-white rounded-md px-2 py-2">
                    <div className="col-span-5">
                      <h5 className="text-sm font-bold">Overflight Permit</h5>
                    </div>
                    <div className="col-span-4 grid grid-cols-4 gap-2">
                      <div className="col-span-4 grid grid-cols-4 gap-2">
                        {airspacePoints.map((point, index) => (
                            <div className="col-span-4 grid grid-cols-5 gap-2" key={point.id}>
                              <div className="grid grid-rows-2 items-center">

                                  <div className="row-span-2">
                                    <label className="text-xs">Select Country</label>
                                    <Select
                                        name="country"
                                        required
                                        value={airspacePoints[index]?.country || null}
                                        onChange={(selectedOption) => handleAirspacePointChange(index, selectedOption, 'country')}
                                        options={countries.map(country => ({
                                          value: country.id,
                                          label: country.country_name
                                        }))}
                                        className="text-sm"
                                        menuPortalTarget={document.body}
                                        menuPosition="fixed"
                                        styles={{
                                          menuPortal: base => ({...base, zIndex: 9999})
                                        }}
                                    />
                                  </div>


                              </div>
                              <div className="grid grid-rows-2">
                                <div>
                                  <label className="text-xs">Enter Entry Point</label>
                                  <input
                                      type="text"
                                      required
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                      value={point.entryAirspace || ''}
                                      onChange={(e) => handleAirspacePointChange(index, e.target.value, 'entryAirspace')}
                                  />
                                </div>
                                <div>
                                  <label className="text-xs">Enter Exit Point</label>
                                  <input
                                      type="text"
                                      required
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                      value={point.exitAirspace || ''}
                                      onChange={(e) => handleAirspacePointChange(index, e.target.value, 'exitAirspace')}
                                  />
                                </div>
                              </div>
                              <div className="grid grid-rows-2">
                                <div>
                                  <label className="text-xs">Entry Date </label>
                                  <input
                                      type="date"
                                      required
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                      value={point.entryDate || ''}
                                      onChange={(e) => handleAirspacePointChange(index, e.target.value, 'entryDate')}
                                  />
                                </div>
                                <div>
                                  <label className="text-xs">Exit Date</label>
                                  <input
                                      type="date"
                                      required
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                      value={point.exitDate || ''}
                                      onChange={(e) => handleAirspacePointChange(index, e.target.value, 'exitDate')}
                                  />
                                </div>
                              </div>

                              <div className="grid grid-rows-2">
                                <div>
                                  <label className="text-xs">Entry Time (UTC)</label>
                                  <input
                                      type="text"
                                      required
                                      name="entryTime"
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                      value={point.entryTime || ''}
                                      onChange={(e) => handleTimeChange(index, e)}
                                      onBlur={(e) => handleTimeBlur(index, e)}
                                      placeholder="hh:mm"
                                  />
                                </div>
                                <div>
                                  <label className="text-xs">Exit Time (UTC)</label>
                                  <input
                                      type="text"
                                      name="exitTime"
                                      required
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                      value={point.exitTime || ''}
                                      onChange={(event) => handleTimeChange(index, event)}
                                      onBlur={(event) => handleTimeBlur(index, event)}
                                      placeholder="hh:mm"
                                  />
                                </div>
                              </div>

                              <div className="flex justify-between items-center">
                                <div>
                                  <label className="text-xs">Flight No</label>
                                  <input
                                      type="text"
                                      name="flightNo"
                                      required
                                      value={point.flightNo || ''}
                                      onChange={(e) => handleAirspacePointChange(index, e.target.value, 'flightNo')}
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                  />
                                </div>
                              </div>

                              <div className="flex justify-center items-center col-span-5 ">

                                <button
                                    type="button"
                                    className="bg-green-600 hover:bg-green-900 text-neutral-100 rounded py-1 px-2"
                                    onClick={addAirspacePoint}
                                >
                                  <FontAwesomeIcon icon={faPlus}/>
                                </button>

                                <div>
                                  {airspacePoints.length > 1 && (
                                      <button
                                          type="button"
                                          onClick={() => removeAirspacePoint(point.id)}
                                          className="bg-red-600 hover:bg-red-900 text-neutral-100 ml-2 rounded py-1 px-2"
                                      >
                                        <FontAwesomeIcon icon={faTimes}/>
                                      </button>
                                  )}
                                </div>
                              </div>
                            </div>
                        ))}
                      </div>
                    </div>
                  </div>

                    {schedule && (
                        <div className="bg-white rounded-md px-2 py-1 mt-1">
                          <div className="flex justify-between items-center mt-1">
                            <h5 className="text-sm font-bold">Landing Schedule</h5>
                            <button
                                type="button"
                                onClick={addFlightRow}
                                className="bg-green-500 hover:bg-green-700 text-xs text-white py-1 px-2 rounded"
                            >
                              Add Sector
                            </button>
                          </div>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <strong>FLT No.</strong>
                                  </TableCell>
                                  <TableCell>
                                    <strong>DEP Airport</strong>
                                  </TableCell>
                                  <TableCell>
                                    <strong>Date (UTC)</strong>
                                  </TableCell>
                                  <TableCell>
                                    <strong>STD (UTC)</strong>
                                  </TableCell>
                                  <TableCell>
                                    <strong>ARR Airport</strong>
                                  </TableCell>
                                  <TableCell>
                                    <strong>Date (UTC)</strong>
                                  </TableCell>
                                  <TableCell>
                                    <strong>STA (UTC)</strong>
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {flightSchedule.map((flight, index) => (
                                    <TableRow key={index}>
                                      <TableCell width="100px">
                                        <input
                                            key={index}
                                            type="text"
                                            required
                                            name="flightNumber"
                                            value={flight.flightNumber || ''}
                                            onChange={(event) => handleInputChange(index, event)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Select
                                            name="depAirport"
                                            required
                                            value={flight.depAirport}
                                            onChange={(selectedOption) => handleSelectChange(index, selectedOption, 'depAirport')}
                                            options={airports.map(airport => ({
                                              value: airport.id,
                                              name: `${airport.airport_name} (ICAO - ${airport.icao_code} | IATA - ${airport.iata_code})`,
                                              label: `(${airport.icao_code} | ${airport.iata_code}) ${airport.airport_name}`
                                            }))}
                                            className="text-sm"
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                              menuPortal: base => ({ ...base, zIndex: 9999, width:'30%' })
                                            }}
                                        />

                                      </TableCell>
                                      <TableCell width="100px">
                                        <input
                                            type="date"
                                            name="depDate"
                                            required
                                            value={flight.depDate || ''}
                                            onChange={(event) => handleInputChange(index, event)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                        />
                                      </TableCell>
                                      <TableCell width="100px">
                                        <input
                                            type="text"
                                            name="depTime"
                                            value={flight.depTime || 'hh:mm'}
                                            required
                                            onChange={(event) => handleTimeChange(index, event)}
                                            onBlur={(event) => handleTimeBlur(index, event)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                            placeholder="hh:mm"
                                        />
                                        {errors[`depTime-${index}`] && <div className="text-red-500 text-xs">{errors[`depTime-${index}`]}</div>}
                                      </TableCell>
                                      <TableCell>
                                        <Select
                                            name="arrAirport"
                                            value={flight.arrAirport}
                                            required
                                            onChange={(selectedOption) => handleSelectChange(index, selectedOption, 'arrAirport')}
                                            options={airports.map((airport) => ({
                                              value: airport.id,
                                              name: `${airport.airport_name} (ICAO - ${airport.icao_code} | IATA - ${airport.iata_code})`,
                                              label: `(${airport.icao_code} | ${airport.iata_code}) ${airport.airport_name}`,
                                            }))}
                                            className="text-sm"
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                              menuPortal: (base) => ({ ...base, zIndex: 9999, width: '30%' }),
                                            }}
                                        />

                                      </TableCell>

                                      <TableCell width="100px">
                                        <input
                                            type="date"
                                            name="arrDate"
                                            value={flight.arrDate || ''}

                                            onChange={(event) => handleInputChange(index, event)}
                                            required
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                        />
                                      </TableCell>
                                      <TableCell width="100px">
                                        <input
                                            type="text"
                                            name="arrTime"
                                            required
                                            value={flight.arrTime || 'hh:mm'}
                                            onChange={(event) => handleTimeChange(index, event)}
                                            onBlur={(event) => handleTimeBlur(index, event)}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                            placeholder="hh:mm"
                                        />
                                        {errors[`arrTime-${index}`] && <div className="text-red-500 text-xs">{errors[`arrTime-${index}`]}</div>}
                                      </TableCell>

                                      <TableCell>
                                        <button
                                            type="button"
                                            onClick={() => removeFlightRow(index)}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                          <FontAwesomeIcon icon={faTimes} />
                                        </button>
                                      </TableCell>
                                    </TableRow>
                                ))}
                              </TableBody>


                            </Table>
                          </TableContainer>
                        </div>
                    )}
                  </div>
              )}


              <div className="flex justify-between mt-4">
                <button
                    className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                    type="button"
                    onClick={goBack}
                >
                  <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                  {BACK}
                </button>

                {submitLoading ? (
                    <button
                        type="button"
                        className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                    >
                      <CircularProgress size={20}/>
                    </button>
                ) : (
                    <button
                        type="submit"
                        className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                    >
                      Next
                      <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                    </button>
                )}
              </div>
              </div>
            </form>
          </div>
        </main>
        <NewAircraft showModal={showModal} onClose={() => setShowModal(false)}/>
      </div>
        </>
  );
};

export default FlightSchedule;