import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { BACKEND_URL } from '../../Default';
import {
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination
} from '@mui/material';
import { HomeIcon } from "@heroicons/react/16/solid";
import Breadcrumb from "../../assets/components/breadcrumb";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid";
import Airports from "../../assets/data/Airports.json";

const PermitDetails = () => {
    const { ref } = useParams();
    const [permitData, setPermitData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPermitDetails = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/permit/get/detailed/${ref}`);
                const data = response.data;

                const mappedLandings = data.landings.map(landing => {
                    const departureAirport = Airports.find(airport => airport.id === landing.departure_airport.airport_id);
                    const arrivalAirport = Airports.find(airport => airport.id === landing.arrival_airport.airport_id);
                    return {
                        ...landing,
                        departure_airport: departureAirport,
                        arrival_airport: arrivalAirport,
                    };
                });

                setPermitData({
                    ...data,
                    landings: mappedLandings,
                });

                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchPermitDetails();
    }, [ref]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return <div className="text-center text-red-500">Error: {error}</div>;
    }

    if (!permitData) {
        return <p className="text-center">Details not available</p>;
    }

    // Files array handling
    const files = [
        ...(permitData.passengers || []).map(passenger => ({
            document_name: passenger.document.document_name,
            file_path: passenger.document.file_path
        })),
        ...(permitData.cargos || []).map(cargo => ({
            document_name: cargo.document.document_name,
            file_path: cargo.document.file_path
        })),
        ...(permitData.dgrs || []).map(dgr => ({
            document_name: dgr.document.document_name,
            file_path: dgr.document.file_path
        })),
        ...(permitData.operator.operator_aoc ? [{
            document_name: permitData.operator.operator_aoc.document_name,
            file_path: permitData.operator.operator_aoc.file_path
        }] : [])
    ];

    const handleDownload = async (fileUrl, fileName, requestId) => {
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const downloadFileName = `${requestId}-${fileName}`;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadFileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/dashboard' },
        { label: 'Permit', url: '' },
        { label: 'Management', url: '/permit/management' },
    ];

    const goBack = () => {
        navigate('/permit/management');
    };

    return (
        <div className="container mx-auto p-4">
            <div className="mt-3 flex">
                <button
                    className="flex items-center justify-between mr-6"
                    onClick={goBack}>
                    <ArrowUturnLeftIcon className="h-4 w-5 mr-1"/>
                    Back
                </button>
                <Breadcrumb items={breadcrumbItems}/>
            </div>

            <Card className="mb-6">
                <CardContent>
                    <div className="grid grid-cols-2">
                        <div>
                            <Typography variant="h5" className="font-bold mb-4">Permit Details</Typography>
                            <Typography variant="body1" className="mb-2">
                                <strong>Request ID:</strong> {permitData.request_id || 'Not available'}
                            </Typography>
                            <Typography variant="body1" className="mb-2 capitalize">
                                <strong>Status:</strong> {permitData.status || 'Not available'}
                            </Typography>
                            <Typography variant="body1" className="mb-2 capitalize">
                                <strong>Flight Type:</strong> {permitData.flightType || 'Not available'}
                            </Typography>
                            <Typography variant="body1" className="mb-2">
                                <strong>Sub Category:</strong> {permitData.sub_request_name || 'Not available'}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" className="mb-2">
                                <strong>Operator Name:</strong> {permitData.operator.operator_name || 'Not available'}
                            </Typography>
                            <Typography variant="body1" className="mb-2">
                                <strong>Operator Address:</strong> {permitData.operator.operator_address || 'Not available'}
                            </Typography>
                            <Typography variant="body1" className="mb-2">
                                <strong>Operator Email:</strong> {permitData.operator.operator_email || 'Not available'}
                            </Typography>
                            <Typography variant="body1" className="mb-2">
                                <strong>Contact Number:</strong> {permitData.operator.operator_country_code || ''} {permitData.operator.operator_contact_no || 'Not available'}
                            </Typography>
                        </div>
                    </div>
                </CardContent>
            </Card>

            {permitData.landings && permitData.landings.length > 0 && (
                <div>
                    <Typography variant="h5" className="font-bold mb-3">Landings</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Departure Airport</TableCell>
                                    <TableCell>Departure Date</TableCell>
                                    <TableCell>Departure Time</TableCell>
                                    <TableCell>Arrival Airport</TableCell>
                                    <TableCell>Arrival Date</TableCell>
                                    <TableCell>Arrival Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {permitData.landings.map((landing, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>{landing.departure_airport.airport_name} ({landing.departure_airport.icao_code})</TableCell>
                                        <TableCell>{landing.departure_date.split('T')[0]}</TableCell>
                                        <TableCell>{landing.departure_time}</TableCell>
                                        <TableCell>{landing.arrival_airport.airport_name} ({landing.arrival_airport.icao_code})</TableCell>
                                        <TableCell>{landing.arrival_date.split('T')[0]}</TableCell>
                                        <TableCell>{landing.arrival_time}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}

            {permitData.overflyings && permitData.overflyings.length > 0 && (
                <div>
                    <Typography variant="h5" className="font-bold mb-3">Overflyings</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Country</TableCell>
                                    <TableCell>Entry Airspace</TableCell>
                                    <TableCell>Entry Time</TableCell>
                                    <TableCell>Exit Airspace</TableCell>
                                    <TableCell>Exit Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {permitData.overflyings.map((overflying, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>{overflying.country.country_name}</TableCell>
                                        <TableCell>{overflying.entry_airspace}</TableCell>
                                        <TableCell>{new Date(overflying.entry_time).toLocaleString()}</TableCell>
                                        <TableCell>{overflying.exit_airspace}</TableCell>
                                        <TableCell>{new Date(overflying.exit_time).toLocaleString()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}

            {permitData.passengers && permitData.passengers.length > 0 && (
                <div>
                    <Typography variant="h5" className="font-bold mb-3">Passenger</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Document Name</TableCell>
                                    <TableCell>Download</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {permitData.passengers.map((cargo, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>{cargo.description || 'N/A'}</TableCell>
                                        <TableCell>{cargo.document.document_name}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleDownload(cargo.document.file_path, cargo.document.document_name, permitData.request_id)}>
                                                Download
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}

            {permitData.cargos && permitData.cargos.length > 0 && (
                <div>
                    <Typography variant="h5" className="font-bold mb-3">Cargo</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Document Name</TableCell>
                                    <TableCell>Download</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {permitData.cargos.map((cargo, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>{cargo.description || 'N/A'}</TableCell>
                                        <TableCell>{cargo.document.document_name}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleDownload(cargo.document.file_path, cargo.document.document_name, permitData.request_id)}>
                                                Download
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}

            {permitData.dgrs && permitData.dgrs.length > 0 && (
                <div>
                    <Typography variant="h5" className="font-bold mb-3">DGR Documents</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Document Name</TableCell>
                                    <TableCell>Download</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {permitData.dgrs.map((dgr, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>{dgr.document.document_name}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleDownload(dgr.document.file_path, dgr.document.document_name, permitData.request_id)}>
                                                Download
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}
        </div>
    );
};

export default PermitDetails;
