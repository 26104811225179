import React, { useEffect, useState } from 'react';
import { HomeIcon } from "@heroicons/react/16/solid";
import Breadcrumb from "../../assets/components/breadcrumb";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useNavigate, useParams} from "react-router-dom";
import { BACKEND_URL } from "../../Default";
import { BACK } from "../../assets/wordings/constants";
import axios from 'axios';
import {Button, CircularProgress} from "@mui/material";
import Select from "react-select";

function FlightDetails() {
  const { requestId } = useParams();
  const navigate = useNavigate();
  const [requiredServices, setRequiredServices] = useState({});
  const [purpose, setPurpose] = useState(null);
  const [subPurpose, setSubPurpose] = useState(null);
  const [type, setType] = useState(null);
  const [sameAsUser, setSameAsUser] = useState(false);
  const [operatorName, setOperatorName] = useState('');
  const [operatorAddress, setOperatorAddress] = useState('');
  const [operatorEmail, setOperatorEmail] = useState('');
  const [operatorContactNumber, setOperatorContactNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [aocFile, setAocFile] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [existingOperators, setExistingOperators] = useState([]);
  const [existingOperatorData, setExistingOperatorData] = useState([]);
  const [selectedOperatorId, setSelectedOperatorId] = useState('');
  const [dataRetrieved, setDataRetrieved] = useState(false);
  const [operatorSelected, setOperatorSelected] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [aocAvailable, setAOCAvailable] = useState(false);


  useEffect(() => {


    fetchCountryCodes();
  }, []);

  useEffect(() => {

    fetchExistingOperators();
  }, []);

  useEffect(() => {

    fetchExistingData();
  }, [requestId]);

  useEffect(() => {
    const storedPurpose = JSON.parse(localStorage.getItem('purpose'));
    const subPurpose = JSON.parse(localStorage.getItem('subPurpose'));
    const storedType = JSON.parse(localStorage.getItem('type'));

    setPurpose(storedPurpose || null);
    setType(storedType || null);
    setSubPurpose(subPurpose || null);

    if (dataRetrieved && existingOperatorData) {
      // Set operator data from the existing operator
      setOperatorName(existingOperatorData.operator_name || '');
      setOperatorAddress(existingOperatorData.operator_address || '');
      setOperatorEmail(existingOperatorData.operator_email || '');
      setCountryCode(existingOperatorData.operator_country_code || '');
      setOperatorContactNumber(existingOperatorData.operator_contact_no || '');
    } else if (sameAsUser) {
      const userData = JSON.parse(localStorage.getItem('user'));
      if (userData) {
        setOperatorName(userData.company_name || '');
        setOperatorAddress(userData.company_address || '');
        setOperatorEmail(userData.email || '');
        setCountryCode(userData.country_code || '');
        setOperatorContactNumber(userData.contact_no || '');
      }
    } else {
      // Clear operator data if no existing data and not same as user
      setOperatorName('');
      setOperatorAddress('');
      setOperatorEmail('');
      setOperatorContactNumber('');
    }
  }, [sameAsUser, existingOperatorData, dataRetrieved]);


  const fetchExistingOperators = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem('user'));
      // console.log("Operator Data: ", userData)
      const userId = userData.id;
      const response = await axios.get(`${BACKEND_URL}/operators/view/${userId}`);
      // console.log(response.data);
      setExistingOperators(response.data);

    } catch (error) {
      console.error('Error fetching existing operators:', error);
    }
  };

  const fetchExistingData = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/permit/get/detailed/${requestId}`);
      // console.log(response.data);

      if (response.data && response.data.operator && response.data.operator.operator_id !== null) {
        setDataRetrieved(true);
        setExistingOperatorData(response.data.operator);
      } else {
        setDataRetrieved(false);
        setExistingOperatorData(null);
      }
    } catch (error) {
      console.error('Error fetching existing operators:', error);
    }
  };

  const fetchCountryCodes = async () => {
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all');
      const countries = response.data;
      const codes = countries.map(country => ({
        country: country.name.common,
        code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : '')
      }));
      setCountryCodes(codes);
      setCountryCode(response.data[0]?.code || ''); // Set default country code
    } catch (error) {
      console.error('Error fetching country codes:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (dataRetrieved){
      navigate(`/permit/new/permit-type/${requestId}`);
    } else {

      const validationErrors = validate();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }

      setSubmitLoading(true);

      const updatedServices = {
        ...requiredServices,
        operator: {
          name: operatorName,
          address: operatorAddress,
          email: operatorEmail,
          countryCode: countryCode,
          contactNumber: operatorContactNumber,
        },
      };

      const formData = new FormData();
      formData.append('client_id', JSON.parse(localStorage.getItem('user')).id);
      formData.append('request_id', requestId);
      formData.append('request_data', JSON.stringify(updatedServices));
      formData.append('status', 'incomplete');

      if (aocFile && !aocFile.path) {
        formData.append('files', aocFile, `AOC.${aocFile.type.split('/')[1]}`);
      }

      localStorage.setItem('requiredServices', JSON.stringify(updatedServices));

      try {
        const response = await axios.post(`${BACKEND_URL}/permit/operator-details`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('Response from backend:', response.data);
        setSubmitLoading(false);
        navigate(`/permit/new/permit-type/${requestId}`);
      } catch (error) {
        console.error('Error sending data to backend:', error);
        setSubmitLoading(false);
      }

    }
  };

  const handleOperatorSelection = (event) => {
    const operatorId = event.target.value;
    setSelectedOperatorId(operatorId);

    if (operatorId) {
      const selectedOperator = existingOperators.find(op => op.operator_id === parseInt(operatorId));
      // console.log(selectedOperator);
      setOperatorName(selectedOperator.operator_name);
      setOperatorAddress('');
      setOperatorEmail(selectedOperator.operator_email);
      setOperatorAddress(selectedOperator.operator_address);
      setCountryCode(selectedOperator.operator_country_code);
      setOperatorContactNumber(selectedOperator.operator_contact);

      // if (response.data?.aoc_path) {
      //   setAOCAvailable(true);
      // }


      if (selectedOperator.aoc_path) {
        setAocFile({
          name: selectedOperator.aoc_name,
          path: selectedOperator.aoc_path
        });
        setAOCAvailable(true);
      } else {
        setAocFile(null);
        setAOCAvailable(false);
      }
    } else {
      setOperatorName('');
      setOperatorAddress('');
      setOperatorEmail('');
      setOperatorContactNumber('');
      setAocFile(null);
      setAOCAvailable(false);
    }
    setOperatorSelected(true);
    setSameAsUser(false); // Disable "Same as User" when selecting an existing operator
  };

  const handleSameAsUserChange = (event) => {
    setSameAsUser(event.target.checked);
    setSelectedOperatorId(''); // Clear selected operator when "Same as User" is checked

    if (event.target.checked) {
      const userData = JSON.parse(localStorage.getItem('user'));
      if (userData) {
        setOperatorName(userData.company_name || '');
        setOperatorAddress(userData.company_address || '');
        setOperatorEmail(userData.email || '');
        setOperatorContactNumber(userData.contact_no || '');
      }
    } else {
      setOperatorName('');
      setOperatorAddress('');
      setOperatorEmail('');
      setOperatorContactNumber('');
    }
  };

  const validate = () => {
    const errors = {};
    if (!operatorName) errors.operatorName = 'Operator name is required.';
    if (!operatorEmail) errors.operatorEmail = 'Operator email is required.';
    if (!operatorAddress) errors.operatorAddress = 'Operator address is required.';
    return errors;
  };

  const goBack = () => {
    navigate(`/permit/new/${requestId}`);
  };

  const viewAOC = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const downloadFileName = `${fileName}`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', downloadFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const breadcrumbItems = [
    { label: <HomeIcon className="h-4 w-5" />, url: '/' },
    { label: 'Permit', url: '' },
    { label: 'New', url: '/permit/new' },
  ];

  const codeOptions = countryCodes.map((code) => ({
    value: code.code,
    label: `${code.code} (${code.country})`,
  }));

// Sort the codeOptions array based on the 'value' property
  const sortedCodeOptions = codeOptions.sort((a, b) => {
    // Compare the values numerically
    return parseInt(a.value, 10) - parseInt(b.value, 10);
  });


  return (
      <>
        <div className="flex flex-col">
          <main className="flex-grow">
            <div className="w-full min-w-full px-4">
              <div className="mt-3">
                <Breadcrumb items={breadcrumbItems} />
              </div>
              <div className="mt-3">
                <h1 className="font-bold text-gray-800 text-xl">Details of the Airline/Operator</h1>
              </div>
              <div className="mt-3">
                <form onSubmit={handleSubmit}>
                  <div className="bg-white py-2 px-4 rounded-md">
                    <h2>Fill in the following fields</h2>
                    <div className="flex max-w-full justify-center items-center">
                      <div className="mt-3 flex justify-between gap-5">
                        <div>
                          Purpose of Flight: <strong>{purpose}</strong>
                        </div>
                        <div>
                          Type of flight: <strong className="capitalize"> {subPurpose}</strong>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-5 mt-3 items-center">
                      <div>
                        <label htmlFor="sameAsUser" className="text-sm text-gray-700 mb-2 inline-block">
                          <input
                              type="checkbox"
                              id="sameAsUser"
                              checked={sameAsUser}
                              onChange={handleSameAsUserChange}
                              disabled={selectedOperatorId || dataRetrieved}
                          />
                          <span className="ml-2">Same as User</span>
                        </label>
                      </div>
                      <div>
                        <label htmlFor="existingOperator" className="text-sm text-gray-700 mb-2 inline-block">
                          Select Existing Operator
                        </label>
                        <select
                            id="existingOperator"
                            className="w-full px-3 py-2 text-sm border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            value={selectedOperatorId}
                            onChange={handleOperatorSelection}
                            disabled={sameAsUser || dataRetrieved}
                        >
                          <option value="">Select an existing operator</option>
                          {existingOperators.map((operator) => (
                              <option key={operator.operator_id} value={operator.operator_id}>
                                {operator.operator_name}
                              </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-5 mt-3">
                      <div>
                        <label htmlFor="operatorName" className="text-sm text-gray-700 mb-2 inline-block">
                          Name of the Airline/Operator
                        </label>
                        <input
                            type="text"
                            id="operatorName"
                            className="w-full px-3 py-2 text-sm border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            value={operatorName}
                            disabled={dataRetrieved || operatorSelected}
                            onChange={(e) => setOperatorName(e.target.value)}
                        />
                        {errors.operatorName && (
                            <span className="text-red-500 text-xs">{errors.operatorName}</span>
                        )}
                      </div>
                      <div>
                        <label htmlFor="operatorEmail" className="text-sm text-gray-700 mb-2 inline-block">
                          Email of the Airline/Operator
                        </label>
                        <input
                            type="email"
                            id="operatorEmail"
                            className="w-full px-3 py-2 text-sm border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            value={operatorEmail}
                            disabled={dataRetrieved || operatorSelected}
                            onChange={(e) => setOperatorEmail(e.target.value)}
                        />
                        {errors.operatorEmail && (
                            <span className="text-red-500 text-xs">{errors.operatorEmail}</span>
                        )}
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-5 mt-3">
                      <div>
                        <label htmlFor="operatorContactNumber" className="text-sm text-gray-700 mb-2 inline-block">
                          Contact Number of the Airline/Operator
                        </label>
                        <div className="flex">
                          <Select
                              className="w-1/4 text-sm"
                              value={sortedCodeOptions.find(option => option.value === countryCode)}
                              isDisabled={dataRetrieved || operatorSelected}
                              onChange={(selectedOption) => setCountryCode(selectedOption.value)}
                              options={sortedCodeOptions}
                              placeholder="code"
                              isSearchable
                              menuPortalTarget={document.body}
                              menuPosition="fixed"
                              styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999, width:'30%' })
                              }}
                          />
                          <input
                              type="text"
                              id="operatorContactNumber"
                              disabled={dataRetrieved || operatorSelected}
                              className="w-3/4 px-3 py-2 text-sm border-2 border-gray-300 rounded-r-md focus:outline-none focus:border-blue-500"
                              value={operatorContactNumber}
                              onChange={(e) => setOperatorContactNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="operatorAddress" className="text-sm text-gray-700 mb-2 inline-block">
                          Address of the Airline/Operator
                        </label>
                        <input
                            type="text"
                            id="operatorAddress"
                            disabled={dataRetrieved || operatorSelected}
                            className="w-full px-3 py-2 text-sm border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            value={operatorAddress}
                            onChange={(e) => setOperatorAddress(e.target.value)}
                        />
                        {errors.operatorAddress && (
                            <span className="text-red-500 text-xs">{errors.operatorAddress}</span>
                        )}
                      </div>
                    </div>

                    {!aocAvailable ? (
                        <div className="mt-3">
                          <label htmlFor="aocFile" className="text-sm text-gray-700 mb-2 inline-block">
                            Air Operator Certificate (AOC)
                          </label>
                          <input
                              type="file"
                              id="aocFile"
                              onChange={(e) => setAocFile(e.target.files[0])}
                              disabled={dataRetrieved || operatorSelected}
                              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                          />
                          {aocFile && aocFile.name && (
                              <span className="text-sm text-gray-700 mt-2 inline-block">{aocFile.name}</span>
                          )}
                        </div>
                    ) : (
                        <div className="mt-3">
                         
                            <label htmlFor="aocFile" className="text-sm text-gray-700 mb-2 inline-block">
                              Air Operator Certificate (AOC)
                            </label>
                          <a
                              href={`${BACKEND_URL}/${aocFile.path}`}
                              target="_blank"
                              className="bg-fts-blue text-white py-2 px-4 rounded-2xl ml-3 hover:bg-fts-gold"
                              rel="noopener noreferrer"
                          >
                            View
                          </a>
                          {/*<Button variant="contained"*/}
                          {/*        onClick={() => viewAOC(`${BACKEND_URL}/aocFile.path`, aocFile.name)}>View AOC</Button>*/}
                        </div>
                    )}



                  <div className="flex justify-between mt-4">
                    <button
                        className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                        type="button"
                        onClick={goBack}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                      {BACK}
                    </button>


                    {submitLoading ? (
                        <button
                            type="button"
                            className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                        >
                          <CircularProgress size={20}/>
                        </button>
                    ) : (
                        <button
                            type="submit"
                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                        >
                          Next
                          <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                        </button>
                    )}
                  </div>
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
      </>
  );
}

export default FlightDetails;
