import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox
} from '@mui/material';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes, faPlus, faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { BACKEND_URL } from "../Default";
import Select from 'react-select';
import {BACK} from "../assets/wordings/constants";
import {toast} from "react-toastify";

const FlightSchedule = ({ requestId }) => {
    const { uniqueId } = useParams();
    const navigate = useNavigate();
    const [operatorData, setOperatorData] = useState({});
    const [sessionType, setSessionType] = useState(null);
    const [type, setType] = useState(null);
    const [overflying, setOverflying] = useState(false);
    const [landing, setLanding] = useState(false);
    const [flightSchedule, setFlightSchedule] = useState([{}]);
    const [airspacePoints, setAirspacePoints] = useState([{}]);
    const [airports, setAirports] = useState([]);
    const [countries, setCountries] = useState([]);
    const [airportLoading, setAirportLoading] = useState(true);
    const [countriesLoading, setCountriesLoading] = useState(true);
    const [errors, setErrors] = useState({});

    useEffect(() => {

        const fetchExistingData = () => {
            try {
                const response = localStorage.getItem('generalInformation');

                if (response) {
                    const existingData = JSON.parse(response);
                    console.log(existingData);
                    const session = parseInt(existingData.permitType, 10); // Ensure it's an integer
                    setSessionType(session);
                    setType(existingData.flightType || '');

                    if (existingData.landing?.flightSchedule) {
                        const flightData = existingData.landing.flightSchedule;
                        const mappedData = flightData.map((landing) => {
                            return {
                                flightNumber: landing.flightNumber,
                                depAirport: landing.depAirport || 'Unknown Airport',
                                depDate: landing.depDate,
                                depTime: landing.depTime,
                                arrAirport: landing.arrAirport || 'Unknown Airport',
                                arrDate: landing.arrDate,
                                arrTime: landing.arrTime,
                                landingRequired: landing.landingRequired,
                            };
                        });
                        setFlightSchedule(mappedData);
                    }

                    if (existingData.overflying?.airspacePoints) {
                        const airspaceData = existingData.overflying.airspacePoints;
                        const mappedData = airspaceData.map((point) => {
                            return {
                                id: point.id,
                                flightNo: point.flightNo,
                                country: point.country,
                                entryPoint: point.entryPoint,
                                entryDate: point.entryDate,
                                entryTime: point.entryTime,
                                exitPoint: point.exitPoint,
                                exitDate: point.exitDate,
                                exitTime: point.exitTime,
                            };
                        });
                        setAirspacePoints(mappedData);
                    }
                }
            } catch (error) {
                console.error('Error fetching existing data:', error);
            }
        };

        const fetchAirports = async () => {
            try {
                const response = await fetch('/assets/data/Airports.json');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setAirports(data);
            } catch (error) {
                console.error('Error fetching airport data:', error);
            } finally {
                setAirportLoading(false);
            }
        };

        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/requests/countries/all`);
                setCountries(response.data);
                // console.log('Countries:', response.data); // Log data to verify
                setCountriesLoading(false);
            } catch (error) {
                console.error('Error fetching countries:', error);
                setCountriesLoading(false); // Stop loading even on error
            }
        };

        fetchAirports();
        fetchCountries();
        fetchExistingData();
    }, [navigate]);

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;

        if (name === "depDate" || name === "arrDate") {
            const year = value.split("-")[0]; // Extract the year from the date
            if (year.length !== 4 || isNaN(year)) {
                toast.info("Please enter a valid year with 4 digits.");
                return;
            }
        }


        const values = [...flightSchedule];
        values[index][name] = value;
        setFlightSchedule(values);
    };



    const validateAirports = () => {
        for (let i = 0; i < flightSchedule.length; i++) {
            const flight = flightSchedule[i];
            if (flight.depAirport.value && flight.arrAirport.value && flight.depAirport.value === flight.arrAirport.value) {
                toast.info(`Departure and Arrival airports cannot be the same in the same sector.`);
                return false; // Return false to indicate validation failure
            }
        }

        return true; // Return true if validation passes
    };


    const maskTime = (value) => {
        const strippedValue = value.replace(/[^0-9]/g, '');
        let hh = strippedValue.substring(0, 2);
        let mm = strippedValue.substring(2, 4);
        if (hh.length === 1 && hh > 2) {
            hh = '0' + hh;
        }
        if (hh.length === 2 && parseInt(hh, 10) > 23) {
            hh = '23';
        }
        if (mm.length === 2 && parseInt(mm, 10) > 59) {
            mm = '59';
        }
        return `${hh}:${mm}`;
    };

    const handleTimeChange = (index, event) => {
        const { name, value } = event.target;
        const maskedValue = maskTime(value);

        if (name === "depTime" || name === "arrTime") {
            const updatedSchedule = [...flightSchedule];
            updatedSchedule[index][name] = maskedValue;
            setFlightSchedule(updatedSchedule);
        } else if (name === "entryTime" || name === "exitTime") {
            const updatedPoints = [...airspacePoints];
            updatedPoints[index][name] = maskedValue;
            setAirspacePoints(updatedPoints);
        }
    };

    const handleTimeBlur = (index, event) => {
        validateTime(index, event);
    };

    const validateTime = (index, event) => {
        const { name, value } = event.target;
        const timePattern = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;
        if (!timePattern.test(value)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [`${name}-${index}`]: 'Invalid time format. Use hh:mm'
            }));
        } else {
            setErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors[`${name}-${index}`];
                return newErrors;
            });
        }
    };

    const handleSelectChange = (index, selectedOption, name) => {
        setFlightSchedule(prev => {
            const updated = [...prev];
            updated[index] = { ...updated[index], [name]: selectedOption };
            return updated;
        });
    };

    const handleAirspaceSelectChange = (index, selectedOption, name) => {
        setAirspacePoints(prev => {
            const updated = [...prev];
            updated[index] = { ...updated[index], [name]: selectedOption };
            return updated;
        });
    };

    const handleCheckboxChange = (index, event) => {
        const { checked } = event.target;
        const values = [...flightSchedule];
        values[index].landingRequired = checked;
        setFlightSchedule(values);
    };

    const handleAirspacePointChange = (index, value, field) => {
        const updatedPoints = airspacePoints.map((point, idx) =>
            idx === index ? { ...point, [field]: value } : point
        );
        setAirspacePoints(updatedPoints);
    };


    const addFlightRow = () => {
        setFlightSchedule((prevSchedule) => {
            const lastRow = prevSchedule[prevSchedule.length - 1];
            const newRow = lastRow ? { depAirport: lastRow.arrAirport } : {};
            return [...prevSchedule, newRow];
        });
    };


    const removeFlightRow = (index) => setFlightSchedule(flightSchedule.filter((_, i) => i !== index));

    const addAirspacePoint = () => setAirspacePoints([...airspacePoints, { id: Date.now() }]);
    const removeAirspacePoint = (id) => setAirspacePoints(airspacePoints.filter(point => point.id !== id));

    const handleSubmit = (event) => {
        event.preventDefault();

        try {
            const isAirportsValid = validateAirports();
            const isAnyCheckboxChecked = flightSchedule.some(flight => flight.landingRequired);

            if (!isAnyCheckboxChecked && landing) {
                // Show a toast notification if no checkbox is selected
                toast.info("At least one checkbox must be selected.");
                return;
            }

            if (!isAirportsValid) {
                // Show a toast notification if airport validation fails
                // toast.error("Please ensure all airports are valid.");
                return;
            }

            let formData = JSON.parse(localStorage.getItem('generalInformation')) || {};

            formData = {
                ...formData,
                landing: { flightSchedule },
                overflying: { airspacePoints }
            };

            localStorage.setItem('generalInformation', JSON.stringify(formData));

            // Navigate to the appropriate page based on the type
            if (type && type.toLowerCase() === "passenger") {
                navigate(`/guest/passenger-documents-upload/${uniqueId}`);
            } else if (type && type.toLowerCase() === "cargo") {
                navigate(`/guest/cargo-documents-upload/${uniqueId}`);
            }
        } catch (error) {
            console.error('Error in handleSubmit:', error);
            toast.error("An error occurred while submitting the form. Please try again.");
        }
    };

    const goBack = () => {
        navigate(`/guest/permit-type/${uniqueId}`);
    };

    return (
        <div className=" mx-auto">
            <div className="">
                <form className="block mx-8" onSubmit={handleSubmit}>
                    <h1 className="font-bold text-[48px] text-fts-gold">Flight Schedule</h1>
                    <hr className="w-full border-fts-gold border-2 my-4" />

                    {(sessionType === 1 || sessionType === 4) && (
                        <div className="bg-white rounded-md px-2 py-1 w-full mt-1">
                            <div className="flex justify-between items-center mt-1">
                                <h5 className="text-sm font-bold">Landing Permit</h5>
                                <button
                                    type="button"
                                    onClick={addFlightRow}
                                    className="bg-green-500 hover:bg-green-700 text-xs text-white py-1 px-2 rounded"
                                >
                                    Add Sector
                                </button>
                            </div>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>FLT No.</strong></TableCell>
                                            <TableCell><strong>DEP Airport</strong></TableCell>
                                            <TableCell><strong>Date (UTC)</strong></TableCell>
                                            <TableCell><strong>STD (UTC)</strong></TableCell>
                                            <TableCell><strong>ARR Airport</strong></TableCell>
                                            <TableCell><strong>Date (UTC)</strong></TableCell>
                                            <TableCell><strong>STA (UTC)</strong></TableCell>
                                            <TableCell><strong>Landing Permit Req? (ARR)</strong></TableCell>
                                            <TableCell><strong>Actions</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {flightSchedule.map((flight, index) => (
                                            <TableRow key={index}>
                                                <TableCell width="100px">
                                                    <input
                                                        type="text"
                                                        name="flightNumber"
                                                        required
                                                        value={flight.flightNumber || ''}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                                    />
                                                </TableCell>
                                                <TableCell width="250px">
                                                    <Select
                                                        name="depAirport"
                                                        value={flight.depAirport}
                                                        onChange={(selectedOption) => handleSelectChange(index, selectedOption, 'depAirport')}
                                                        options={airports.map(airport => ({
                                                            value: airport.id,
                                                            label: `${airport.icao_code} | ${airport.iata_code} - (${airport.airport_name})`
                                                        }))}
                                                        isLoading={airportLoading}
                                                        isClearable
                                                        required
                                                        classNamePrefix="select"
                                                        styles={{
                                                            menuPortal: base => ({ ...base, zIndex: 9999, width:'30%' })
                                                        }}
                                                        menuPortalTarget={document.body}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="date"
                                                        name="depDate"
                                                        required
                                                        value={flight.depDate || ''}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                                    />
                                                </TableCell>
                                                <TableCell width="100px">
                                                    <input
                                                        type="text"
                                                        name="depTime"
                                                        value={flight.depTime || ''}
                                                        required
                                                        onChange={(event) => handleTimeChange(index, event)}
                                                        onBlur={(event) => handleTimeBlur(index, event)}
                                                        className={`bg-gray-50 border ${errors[`depTime-${index}`] ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1`}
                                                        placeholder="hh:mm"
                                                    />
                                                    {errors[`depTime-${index}`] && (
                                                        <p className="text-red-500 text-xs mt-1">{errors[`depTime-${index}`]}</p>
                                                    )}
                                                </TableCell>
                                                <TableCell width="250px">
                                                    <Select
                                                        name="arrAirport"
                                                        value={flight.arrAirport}
                                                        required
                                                        onChange={(selectedOption) => handleSelectChange(index, selectedOption, 'arrAirport')}
                                                        options={airports.map(airport => ({
                                                            value: airport.id,
                                                            label: `${airport.icao_code} | ${airport.iata_code} - (${airport.airport_name})`
                                                        }))}
                                                        isLoading={airportLoading}
                                                        isClearable
                                                        classNamePrefix="select"
                                                        styles={{
                                                            menuPortal: base => ({ ...base, zIndex: 9999, width:'30%' })
                                                        }}
                                                        menuPortalTarget={document.body}

                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="date"
                                                        required
                                                        name="arrDate"
                                                        value={flight.arrDate || ''}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                                    />
                                                </TableCell>
                                                <TableCell width="100px">
                                                    <input
                                                        type="text"
                                                        name="arrTime"
                                                        required
                                                        value={flight.arrTime || ''}
                                                        onChange={(event) => handleTimeChange(index, event)}
                                                        onBlur={(event) => handleTimeBlur(index, event)}
                                                        className={`bg-gray-50 border ${errors[`arrTime-${index}`] ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1`}
                                                        placeholder="hh:mm"
                                                    />
                                                    {errors[`arrTime-${index}`] && (
                                                        <p className="text-red-500 text-xs mt-1">{errors[`arrTime-${index}`]}</p>
                                                    )}
                                                </TableCell>
                                                <TableCell width="70px">
                                                    <Checkbox
                                                        checked={flight.landingRequired || false}
                                                        onChange={(event) => handleCheckboxChange(index, event)}
                                                        color="primary"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <button
                                                        type="button"
                                                        onClick={() => removeFlightRow(index)}
                                                        className="bg-red-500 hover:bg-red-700 text-xs text-white py-1 px-2 rounded"
                                                    >
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )}

                    {(sessionType === 2 || sessionType === 3 || sessionType === 4) && (
                        <div className="bg-white rounded-md px-2 py-1 mt-1">
                            <div className="flex justify-between items-center mt-1">
                                <h5 className="text-sm font-bold">Overflying Permit</h5>
                                <button
                                    type="button"
                                    onClick={addAirspacePoint}
                                    className="bg-green-500 hover:bg-green-700 text-xs text-white py-1 px-2 rounded"
                                >
                                    Add Route
                                </button>
                            </div>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>FLT No.</strong></TableCell>
                                            <TableCell><strong>Country</strong></TableCell>
                                            <TableCell><strong>Entry Point</strong></TableCell>
                                            <TableCell><strong>Entry Date (UTC)</strong></TableCell>
                                            <TableCell><strong>Entry Time (UTC)</strong></TableCell>
                                            <TableCell><strong>Exit Point</strong></TableCell>
                                            <TableCell><strong>Exit Date (UTC)</strong></TableCell>
                                            <TableCell><strong>Exit Time (UTC)</strong></TableCell>

                                            <TableCell><strong>Actions</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {airspacePoints.map((point, index) => (
                                            <TableRow key={point.id}>
                                                <TableCell width="150px">
                                                    <input
                                                        type="text"
                                                        value={point.flightNo || ''}
                                                        onChange={(event) => handleAirspacePointChange(index, event.target.value, 'flightNo')}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                                    />
                                                </TableCell>
                                                <TableCell width="250px">
                                                    <Select
                                                        name="country"
                                                        value={point.country}
                                                        onChange={(selectedOption) => handleAirspaceSelectChange(index, selectedOption, 'country')}
                                                        options={countries.map(country => ({
                                                            value: country.id,
                                                            label: country.country_name,
                                                        }))}
                                                        isLoading={countriesLoading}
                                                        classNamePrefix="select"
                                                        styles={{
                                                            menuPortal: base => ({ ...base, zIndex: 9999, width: '30%' })
                                                        }}
                                                        menuPortalTarget={document.body}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="text"
                                                        value={point.entryPoint || ''}
                                                        onChange={(event) => handleAirspacePointChange(index, event.target.value, 'entryPoint')}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="date"
                                                        value={point.entryDate || ''}
                                                        onChange={(event) => handleAirspacePointChange(index, event.target.value, 'entryDate')}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="text"
                                                        required
                                                        name="entryTime"
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                                        value={point.entryTime || ''}
                                                        onChange={(e) => handleTimeChange(index, e)}
                                                        onBlur={(e) => handleTimeBlur(index, e)}
                                                        placeholder="hh:mm"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="text"
                                                        value={point.exitPoint || ''}
                                                        onChange={(event) => handleAirspacePointChange(index, event.target.value, 'exitPoint')}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="date"
                                                        value={point.exitDate || ''}
                                                        onChange={(event) => handleAirspacePointChange(index, event.target.value, 'exitDate')}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="text"
                                                        name="exitTime"
                                                        required
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                                        value={point.exitTime || ''}
                                                        onChange={(event) => handleTimeChange(index, event)}
                                                        onBlur={(event) => handleTimeBlur(index, event)}
                                                        placeholder="hh:mm"
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <button
                                                        type="button"
                                                        onClick={() => removeAirspacePoint(point.id)}
                                                        className="bg-red-500 hover:bg-red-700 text-xs text-white py-1 px-2 rounded"
                                                    >
                                                        <FontAwesomeIcon icon={faTimes}/>
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )}

                    {(sessionType === 2 ) && (
                        <div className="bg-white rounded-md px-2 py-1 mt-1">
                            <div className="flex justify-between items-center mt-1">
                                <h5 className="text-sm font-bold">Flight Schedule</h5>
                                <button
                                    type="button"
                                    onClick={addFlightRow}
                                    className="bg-green-500 hover:bg-green-700 text-xs text-white py-1 px-2 rounded"
                                >
                                    Add Sector
                                </button>
                            </div>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>FLT No.</strong></TableCell>
                                            <TableCell><strong>DEP Airport</strong></TableCell>
                                            <TableCell><strong>Date (UTC)</strong></TableCell>
                                            <TableCell><strong>STD (UTC)</strong></TableCell>
                                            <TableCell><strong>ARR Airport</strong></TableCell>
                                            <TableCell><strong>Date (UTC)</strong></TableCell>
                                            <TableCell><strong>STA (UTC)</strong></TableCell>
                                            <TableCell><strong>Actions</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {flightSchedule.map((flight, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <input
                                                        type="text"
                                                        name="flightNumber"
                                                        value={flight.flightNumber || ''}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        name="depAirport"
                                                        value={flight.depAirport}
                                                        onChange={(selectedOption) => handleSelectChange(index, selectedOption, 'depAirport')}
                                                        options={airports.map(airport => ({
                                                            value: airport.id,
                                                            label: `${airport.icao_code} | ${airport.iata_code} - (${airport.airport_name})`
                                                        }))}
                                                        isLoading={airportLoading}
                                                        isClearable
                                                        classNamePrefix="select"
                                                        styles={{
                                                            menuPortal: base => ({ ...base, zIndex: 9999, width:'30%' })
                                                        }}
                                                        menuPortalTarget={document.body}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="date"
                                                        name="depDate"
                                                        value={flight.depDate || ''}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="text"
                                                        name="depTime"
                                                        value={flight.depTime || ''}
                                                        onChange={(event) => handleTimeChange(index, event)}
                                                        onBlur={(event) => handleTimeBlur(index, event)}
                                                        className={`bg-gray-50 border ${errors[`depTime-${index}`] ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1`}
                                                        placeholder="hh:mm"
                                                    />
                                                    {errors[`depTime-${index}`] && (
                                                        <p className="text-red-500 text-xs mt-1">{errors[`depTime-${index}`]}</p>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        name="arrAirport"
                                                        value={flight.arrAirport}
                                                        onChange={(selectedOption) => handleSelectChange(index, selectedOption, 'arrAirport')}
                                                        options={airports.map(airport => ({
                                                            value: airport.id,
                                                            label: `${airport.icao_code} | ${airport.iata_code} - (${airport.airport_name})`
                                                        }))}
                                                        isLoading={airportLoading}
                                                        isClearable
                                                        classNamePrefix="select"
                                                        styles={{
                                                            menuPortal: base => ({ ...base, zIndex: 9999, width:'30%' })
                                                        }}
                                                        menuPortalTarget={document.body}

                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="date"
                                                        name="arrDate"
                                                        value={flight.arrDate || ''}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="text"
                                                        name="arrTime"
                                                        value={flight.arrTime || ''}
                                                        onChange={(event) => handleTimeChange(index, event)}
                                                        onBlur={(event) => handleTimeBlur(index, event)}
                                                        className={`bg-gray-50 border ${errors[`arrTime-${index}`] ? 'border-red-500' : 'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1`}
                                                        placeholder="hh:mm"
                                                    />
                                                    {errors[`arrTime-${index}`] && (
                                                        <p className="text-red-500 text-xs mt-1">{errors[`arrTime-${index}`]}</p>
                                                    )}
                                                </TableCell>

                                                <TableCell>
                                                    <button
                                                        type="button"
                                                        onClick={() => removeFlightRow(index)}
                                                        className="bg-red-500 hover:bg-red-700 text-xs text-white py-1 px-2 rounded"
                                                    >
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )}

                    <div className="flex justify-between mt-4 mx-auto">
                        <button
                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                            type="button"
                            onClick={goBack}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                            {BACK}
                        </button>


                            <button
                                type="submit"
                                className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                            >
                                Next
                                <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                            </button>

                    </div>
                </form>
            </div>
        </div>
    );
};

export default FlightSchedule;
