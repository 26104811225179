import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import { HomeIcon } from "@heroicons/react/16/solid";
import Breadcrumb from "../../assets/components/breadcrumb";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BACKEND_URL } from '../../Default';
import { BACK } from '../../assets/wordings/constants';
import axios from 'axios';
import {CircularProgress} from "@mui/material";

function TandC() {
  const navigate = useNavigate();
  const { requestId } = useParams();
  const [requiredServices, setRequiredServices] = useState({});
  const [type, setType] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [subPurpose, setSubPurpose] = useState(null);
  const [purpose, setPurpose] = useState(null);

  useEffect(() => {
    const storedServices = JSON.parse(localStorage.getItem('requiredServices'));
    const storedPurpose = JSON.parse(localStorage.getItem('purpose'));
    const storedType = JSON.parse(localStorage.getItem('type'));
    const subPurpose = JSON.parse(localStorage.getItem('subPurpose'));
    setSubPurpose(subPurpose || null);
    setPurpose(storedPurpose || null);
    setType(storedType || null);
    setRequiredServices(storedServices || {});
  }, []);

  const goBack = () => {
    if (type && type.toLowerCase() === "passenger") {
      navigate(`/permit/new/add-on-services/${requestId}`);
    } else if (type && type.toLowerCase() === "cargo"){
      navigate(`/permit/new/add-on-services/${requestId}`);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitLoading(false);
    navigate(`/permit/new/request-summary/${requestId}`);
  };

  const breadcrumbItems = [
    { label: <HomeIcon className="h-4 w-5" />, url: '/dashboard' },
    { label: 'Permit', url: '' },
    { label: 'New', url: '/permit/new' },
  ];

  return (
      <div className="flex flex-col">
        <main className="flex-grow">
          <div className="w-full min-w-full px-4">
            <div className="mt-3">
              <Breadcrumb items={breadcrumbItems}/>
            </div>
            <div className="mt-3">
              <h1 className="font-bold text-gray-800 text-xl">Terms and Conditions</h1>
              <form onSubmit={handleSubmit} className="justify-center items-center">
                <div className="justify-center items-center mt-4">
                  <div
                      className="text-left text-gray-700 p-8 bg-white border border-gray-300 rounded-lg overflow-auto w-full h-[60vh]">
                    <h2 className="font-semibold text-lg mb-4">Acceptance of Terms</h2>
                    <p className="mb-4">By accessing or using our application SkyPermits, you agree to comply with and be bound by these Terms and Conditions. Please read this Agreement carefully before using the Service. If you do not agree to these terms, you must not use the Service.</p>
                    <h2 className="font-semibold text-lg mb-4">2. Use of the Service</h2>
                    <p className="mb-4">You agree to use the Service solely for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit any other person's use and enjoyment of the Service. Prohibited conduct includes, but is not limited to, harassment, causing distress or inconvenience to others, transmitting obscene or offensive content, and disrupting the normal flow of dialogue within the Service.
                    </p>
                    <h2 className="font-semibold text-lg mb-4">3. Privacy Policy</h2>
                    <p className="mb-4">We are committed to protecting your privacy. Our Privacy Policy, which is hereby incorporated into this Agreement by reference, details how we collect, use, and safeguard your personal information. By using the Service, you consent to the collection and use of your information as outlined in our Privacy Policy. Please review the Privacy Policy to understand our practices.</p>
                    <h2 className="font-semibold text-lg mb-4">4. Modifications to Terms</h2>
                    <p className="mb-4">We reserve the right, at our sole discretion, to modify or replace these Terms and Conditions at any time. Any such modifications shall become effective immediately upon posting the updated Agreement on this page. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms and Conditions. It is your responsibility to review this Agreement periodically for any updates.
                    </p>
                    <h2 className="font-semibold text-lg mb-4">5. Contact Information</h2>
                    <p>If you have any questions about this Agreement, please contact us at support@skypermits.net. Your inquiries will be addressed promptly, and we will strive to resolve any concerns you may have regarding these Terms and Conditions.</p>
                  </div>

                  <div className="flex mt-1 w-full max-w-4x items-center">
                    <input type="checkbox" name="agree" id="agree" required className="mr-2 h-5 w-5"/>
                    <label htmlFor="agree">I <strong>AGREE</strong> to the terms and conditions</label>
                  </div>
                </div>

                <div className="mt-6">
                  <div className="flex justify-between mt-4">
                    <button
                        className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                        type="button"
                        onClick={goBack}>
                      <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                      {BACK}
                    </button>

                    {submitLoading? (
                        <button type="button"
                                className="text-fts-blue  rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                          <CircularProgress size={20}/>
                        </button>
                    ) : (
                        <button type="submit"
                                className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                          Next
                          <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                        </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
  );
}

export default TandC;
