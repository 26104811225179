import React, { useEffect, useState } from 'react';
import Breadcrumb from "../../assets/components/breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { BACK } from "../../assets/wordings/constants";
import { CircularProgress } from "@mui/material";
import { HomeIcon } from "@heroicons/react/24/solid";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../Default";

const PermitType = () => {
    const { requestId } = useParams();
    const navigate = useNavigate();
    const [purpose, setPurpose] = useState(null);
    const [type, setType] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [requiredServices, setRequiredServices] = useState({});
    const [permitTypes, setPermitTypes] = useState([]);
    const [permitTypeLoading, setPermitTypeLoading] = useState(true);
    const [selectedPermitType, setSelectedPermitType] = useState(null);
    const [existingPermitTypeData, setExistingPermitTypeData] = useState([]);
    const [dataRetrieved, setDataRetrieved] = useState(false);
    const [subPurpose, setSubPurpose] = useState(null);

    useEffect(() => {
        const storedPurpose = JSON.parse(localStorage.getItem('purpose'));
        const storedType = JSON.parse(localStorage.getItem('type'));
        const subPurpose = JSON.parse(localStorage.getItem('subPurpose'));
        setSubPurpose(subPurpose || null);
        setPurpose(storedPurpose || null);
        setType(storedType || null);

        const fetchPermitTypes = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/requests/request-permit-types`);
                const data = response.data;
                setPermitTypes(data);
                setPermitTypeLoading(false);
            } catch (error) {
                console.error('Error fetching permit types:', error);
            }
        };

        const fetchExistingData = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/permit/get/detailed/${requestId}`);
                const data = response.data;

                if (data.permit_types.length > 0) {
                    setSelectedPermitType(data?.permit_types[0]?.permit_type_id.toString());
                }

                if (data && (data.landings.length > 0 || data.overflyings.length > 0)) {
                    setDataRetrieved(true);
                    setExistingPermitTypeData(data.permit_types);
                    if (data.permit_types.length > 0) {
                        setSelectedPermitType(data?.permit_types[0]?.permit_type_id.toString());
                    }
                }
            } catch (error) {
                console.error('Error fetching existing data:', error);
            }
        };

        fetchPermitTypes();
        fetchExistingData();
    }, [requestId]);

    const handlePermitTypeChange = (event) => {
        const { id } = event.target;
        setSelectedPermitType(id);
    };



    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitLoading(true);

        if (dataRetrieved) {
            navigate(`/permit/new/flight-schedule/${requestId}`);
        } else {
            const updatedServices = {
                ...requiredServices,
                permit: selectedPermitType ? {[selectedPermitType]: true} : {},
            };

            const formData = new FormData();
            formData.append('client_id', JSON.parse(localStorage.getItem('user')).id);
            formData.append('request_id', requestId);
            formData.append('request_data', JSON.stringify(updatedServices));
            formData.append('status', 'incomplete');

            localStorage.setItem('requiredServices', JSON.stringify(updatedServices));

            try {
                const response = await axios.post(`${BACKEND_URL}/permit/permit-type`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log('Response from backend:', response.data);
                setSubmitLoading(false);
                navigate(`/permit/new/flight-schedule/${requestId}`);
            } catch (error) {
                console.error('Error sending data to backend:', error);
                setSubmitLoading(false);
            }
        }
    };

    const goBack = () => {

        const userData = JSON.parse(localStorage.getItem('user'));

        if (userData.company_type === "OPERATOR"){
            navigate(`/permit/new/${requestId}`);
        } else {
            navigate(`/permit/new/flight-details/${requestId}`);
        }
    };

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Permit', url: '' },
        { label: 'New', url: '/permit/new' },
    ];

    return (
        <div className="flex flex-col">
            <main className="flex-grow">
                <div className="w-full min-w-full px-4">
                    <div className="mt-3">
                        <Breadcrumb items={breadcrumbItems} />
                    </div>

                    <div className="mt-3">
                        <h1 className="font-bold text-gray-800 text-xl">
                            {existingPermitTypeData.length > 0 ?
                                'Permit Types' :
                                'Select required permits'
                            }
                        </h1>
                    </div>
                    <div className="mt-3">
                        <form onSubmit={handleSubmit}>
                            <div className="bg-white py-2 px-4 rounded-md">
                                <div className="flex max-w-full justify-center items-center">
                                    <div className="mt-3 flex justify-between gap-5">
                                        <div>
                                            Purpose of Flight: <strong>{purpose}</strong>
                                        </div>
                                        <div>
                                            Type of flight: <strong className="capitalize">{subPurpose}</strong>
                                        </div>
                                    </div>
                                </div>

                                {existingPermitTypeData.length > 0 ? (
                                    <div className="mt-3">
                                        <ul className=" ml-5 mt-2 flex justify-center ">
                                            {existingPermitTypeData.map(pt => (
                                                <div key={pt.permit_type_id} className="">
                                                    <div
                                                        className={`block cursor-pointer p-8 capitalize font-bold rounded-md mr-4 text-gray-700 bg-fts-gold border-2 border-fts-blue ${selectedPermitType === pt.permit_type_id.toString() ? 'bg-fts-gold text-fts-blue' : 'bg-white text-fts-blue'}`}
                                                    >
                                                        {pt.permit_type_name}
                                                    </div>
                                                </div>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <div className="grid grid-cols-1 gap-5 mt-3">
                                        <div>
                                            <label className="text-sm text-gray-700 mb-2 inline-block">
                                                Permit Type
                                            </label>
                                            {permitTypeLoading ? (
                                                <div className="flex justify-center items-center p-5">
                                                    <CircularProgress />
                                                </div>
                                            ) : (
                                                <div className="flex items-center justify-center">
                                                    {permitTypes.map(permit => (
                                                        <div key={permit.id}>
                                                            <input
                                                                type="checkbox"
                                                                id={permit.id.toString()}
                                                                checked={selectedPermitType === permit.id.toString()}
                                                                onChange={handlePermitTypeChange}
                                                                className="hidden"
                                                            />
                                                            <label
                                                                htmlFor={permit.id.toString()}
                                                                className={`block cursor-pointer p-8 capitalize font-bold rounded-md mr-4 ${selectedPermitType === permit.id.toString() ? 'bg-fts-gold text-fts-blue border-2 border-fts-blue' : 'bg-white text-fts-blue border-2 border-fts-blue'}`}
                                                            >
                                                                {permit.name}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div className="flex justify-between mt-4">
                                    <button
                                        className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        type="button"
                                        onClick={goBack}
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                        {BACK}
                                    </button>

                                    {submitLoading ? (
                                        <button
                                            type="button"
                                            className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        >
                                            <CircularProgress size={20} />
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        >
                                            Next
                                            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default PermitType;
