import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BACKEND_URL } from '../Default';
import { BACK } from '../assets/wordings/constants';
import axios from 'axios';
import {CircularProgress} from "@mui/material";

function TermsAndCondition() {
    const { uniqueId } = useParams();
    const navigate = useNavigate();
    const { requestId } = useParams();
    const [requiredServices, setRequiredServices] = useState({});
    const [isDGR, setIsDGR] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [type, setType] = useState(null);

    useEffect(() => {
        const storedServices = JSON.parse(localStorage.getItem('generalInformation'));
        setIsDGR(storedServices.isDGR || false);
        setType(storedServices.flightType || '');
        setRequiredServices(storedServices || {});
    }, []);

    const goBack = () => {

        if (isDGR) {
            navigate(`/guest/dgr-documents-upload/${uniqueId}`);
        } else {
            if (type && type.toLowerCase() === "passenger") {
                navigate(`/guest/passenger-documents-upload/${uniqueId}`);
            } else if (type && type.toLowerCase() === "cargo") {
                navigate(`/guest/cargo-documents-upload/${uniqueId}`);
            }
        }

    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitLoading(false);
        navigate(`/guest/request-summary/${uniqueId}`);
    };



    return (
        <div className="w-full min-w-full min-h-[88vh] px-4">
            <main>
                <div className="mt-3 text-center max-w-4xl mx-auto">
                    <h1 className="font-bold text-[48px] text-fts-gold">Terms and Conditions</h1>
                    <hr className="w-[90%] border-fts-gold border-2 my-4"/>
                </div>


                <div className="mt-3 max-w-4xl mx-auto">
                    <form onSubmit={handleSubmit} className="justify-center items-center">
                        <div className="justify-center items-center mt-4">
                            <div
                                className="text-left text-gray-700 p-8 bg-white border border-gray-300 rounded-lg overflow-auto w-full h-[60vh]">
                                <h2 className="font-semibold text-lg mb-4">1. Introduction</h2>
                                <p className="mb-4">Welcome to our application. By using our service, you agree to be
                                    bound by the
                                    following terms and conditions. Please read them carefully.</p>
                                <h2 className="font-semibold text-lg mb-4">2. Use of the Service</h2>
                                <p className="mb-4">You agree to use the service only for lawful purposes and in a way
                                    that does not
                                    infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the
                                    service.</p>
                                <h2 className="font-semibold text-lg mb-4">3. Privacy Policy</h2>
                                <p className="mb-4">We value your privacy. Please review our Privacy Policy to
                                    understand how we
                                    collect, use, and protect your information.</p>
                                <h2 className="font-semibold text-lg mb-4">4. Changes to Terms</h2>
                                <p className="mb-4">We may update these terms and conditions from time to time. We will
                                    notify you
                                    of any changes by posting the new terms on this page.</p>
                                <h2 className="font-semibold text-lg mb-4">5. Contact Us</h2>
                                <p>If you have any questions about these terms, please contact us at
                                    support@example.com.</p>
                            </div>

                            <div className="flex mt-1 w-full max-w-4x items-center text-white">
                                <input type="checkbox" name="agree" id="agree" required className="mr-2 h-5 w-5 text-white"/>
                                <label htmlFor="agree">I <strong>AGREE</strong> to the terms and conditions</label>
                            </div>
                        </div>

                        <div className="mt-6">
                            <div className="flex justify-between mt-4">
                                <button
                                    className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                    type="button"
                                    onClick={goBack}>
                                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                                    {BACK}
                                </button>

                                {submitLoading ? (
                                    <button type="button"
                                            className="text-fts-blue  rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                        <CircularProgress size={20}/>
                                    </button>
                                ) : (
                                    <button type="submit"
                                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                        Next
                                        <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>

            </main>
        </div>

    );
}

export default TermsAndCondition;
