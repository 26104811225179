import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import {BACKEND_URL} from "../Default";

const DgrDocumentUpload = () => {
    const { uniqueId } = useParams();
    const navigate = useNavigate();
    const [sessionType, setSessionType] = useState(null);
    const [type, setType] = useState(null);
    const [dGCertificate, setDGCertificate] = useState([]);
    const [personDG, setPersonDG] = useState([]);
    const [OPS, setOPS] = useState([]);
    const [insurance, setInsurance] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        const fetchExistingData = () => {
            try {
                const response = localStorage.getItem('generalInformation');
                console.log(response);
                if (response) {
                    const existingData = JSON.parse(response);
                    const session = parseInt(existingData.permitType, 10); // Ensure it's an integer
                    setSessionType(session);
                    setType(existingData.flightType || '');
                    setDGCertificate(existingData.dgrDocuments.dGCertificate || []);
                    setPersonDG(existingData.dgrDocuments.personDG || []);
                    setOPS(existingData.dgrDocuments.OPS || []);
                    setInsurance(existingData.dgrDocuments.insurance || []);
                }
            } catch (error) {
                console.error('Error fetching existing data:', error);
            }
        };

        fetchExistingData();
    }, [navigate]);

    const handleFileChange = (setFileState) => (event) => {
        setFileState(Array.from(event.target.files));
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitLoading(true);

        let existingData = JSON.parse(localStorage.getItem('generalInformation')) || {};

        existingData = {
            ...existingData,
            dgrDocuments: {
                dGCertificate,
                personDG,
                OPS,
                insurance,
            },
        };

        function generateShortKey() {
            return Math.random().toString(36).substr(2, 5); // Generates a 5-character string
        }

        const formData = new FormData();

        formData.append('request_id', uniqueId);
        formData.append('operator_name', existingData.company_name);
        formData.append('guest_name', existingData.guest_name);
        formData.append('email', existingData.guest_email);
        formData.append('contact', `${existingData.country_code}${existingData.company_contact_number}`);
        formData.append('request_data', JSON.stringify(existingData));
        formData.append('request_status', 'incomplete');

        dGCertificate.forEach((file, index) => {
            formData.append('files', file, `DG-Certificate-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        personDG.forEach((file, index) => {
            formData.append('files', file, `Person-DG-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        OPS.forEach((file, index) => {
            formData.append('files', file, `OPS-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        insurance.forEach((file, index) => {
            formData.append('files', file, `Insurance-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        try {

            const response = await axios.post(`${BACKEND_URL}/guests/new`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log(response.data);
            localStorage.setItem('generalInformation', JSON.stringify(existingData));
            setSubmitLoading(false);
            navigate(`/guest/terms-and-conditions/${uniqueId}`);

            // setSubmitLoading(false);

        } catch (error) {
            console.error(error);
            setSubmitLoading(false);
        }

        // localStorage.setItem('generalInformation', JSON.stringify(existingData));


    };

    const goBack = () => {
        if (type && type.toLowerCase() === "passenger") {
            navigate(`/guest/passenger-documents-upload/${uniqueId}`);
        } else if (type && type.toLowerCase() === "cargo") {
            navigate(`/guest/cargo-documents-upload/${uniqueId}`);
        }
    };

    // const renderFileNames = (files) => {
    //     return files.map((file, index) => (
    //         <p key={index} className="text-sm text-neutral-100">{file.name}</p>
    //     ));
    // };

    return (
        <div className="w-full min-w-full min-h-[88vh] px-4">
            <main className="">
                <div className="mt-3 text-center max-w-4xl mx-auto">
                    <h1 className="font-bold text-[48px] text-fts-gold">DGR Documents</h1>
                    <hr className="w-[90%] border-fts-gold border-2 my-4"/>
                </div>
                <div className="mt-3 max-w-4xl mx-auto">
                    <form onSubmit={handleSubmit} className="text-gray-800">
                        <div className="text-start">
                            <div className="text-white py-2 px-4 rounded-md">
                                <h2>Upload the following fields</h2>
                                <div className="grid grid-cols-1 gap-4 mx-12 px-12 mt-3 justify-center items-center">
                                    <div className="mb-2 grid grid-cols-2 gap-2">
                                        <label className="">Dangerous goods certificate:</label>
                                        <div>
                                            <input
                                                type="file"
                                                multiple
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                onChange={handleFileChange(setDGCertificate)}
                                            />
                                            {/*{renderFileNames(dGCertificate)}*/}
                                        </div>
                                    </div>
                                    <div className="mb-2 grid grid-cols-2 gap-2">
                                        <label className="block">DG Certificate of person signed DG:</label>
                                        <div>
                                            <input
                                                type="file"
                                                multiple
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                onChange={handleFileChange(setPersonDG)}
                                            />
                                            {/*{renderFileNames(personDG)}*/}
                                        </div>
                                    </div>
                                    <div className="mb-2 grid grid-cols-2 gap-2">
                                        <label className="block">OPS Specification:</label>
                                        <div>
                                            <input
                                                type="file"
                                                multiple
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                onChange={handleFileChange(setOPS)}
                                            />
                                            {/*{renderFileNames(OPS)}*/}
                                        </div>
                                    </div>
                                    <div className="mb-2 grid grid-cols-2 gap-2">
                                        <label className="block">Insurance Certificate:</label>
                                        <div>
                                            <input
                                                type="file"
                                                multiple
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                onChange={handleFileChange(setInsurance)}
                                            />
                                            {/*{renderFileNames(insurance)}*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between mt-4">
                                <button
                                    className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                    type="button"
                                    onClick={goBack}
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                                    Back
                                </button>
                                {submitLoading ? (
                                    <button type="button"
                                            className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                        <CircularProgress size={20}/>
                                    </button>
                                ) : (
                                    <button type="submit"
                                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                        Next
                                        <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    );
};

export default DgrDocumentUpload;
