import React, { useState, useEffect } from 'react';

const HeadlineMarquee = () => {
    // State variables for UTC and local time
    const [utcTime, setUtcTime] = useState('');
    const [localTime, setLocalTime] = useState('');

    // Function to get the current UTC time as a string
    const getCurrentUtcTime = () => {
        // Get the current date and time
        const currentDate = new Date();
        // Convert the current date to ISO string format (UTC date)
        const utcDateString = currentDate.toISOString().split('T')[0];
        // Get the current time
        const currentTimeString = currentDate.toISOString().split('T')[1];
        // Combine date and time with a space in between
        const utcTimeString = `${utcDateString} | ${currentTimeString}`;
        // Return the UTC time string
        return utcTimeString;
    };



    // Function to get the current local time as a string
    const getCurrentLocalTime = () => {
        const now = new Date();
        return now.toLocaleString();
    };

    // Effect hook to update time every second
    useEffect(() => {
        // Function to update time
        const updateTime = () => {
            // Update UTC time
            setUtcTime(getCurrentUtcTime());
            // Update local time
            setLocalTime(getCurrentLocalTime());
        };

        // Call updateTime initially and then every second
        updateTime();
        const interval = setInterval(updateTime, 1000);

        // Cleanup interval on unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="timezone-slider bg-neutral-800 text-white w-full">
            <div className="mx-auto flex justify-between py-1 px-2 text-sm font-semibold leading-none">
                <div>UTC: {utcTime}</div>
                <div>Local: {localTime}</div>
            </div>
        </div>
    );
};

export default HeadlineMarquee;
