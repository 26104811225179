import React, {useEffect, useState} from 'react';
import {HomeIcon} from "@heroicons/react/16/solid";
import {useNavigate} from "react-router-dom";
import Breadcrumb from "../../assets/components/breadcrumb";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WaitingForApproval = () => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
       localStorage.removeItem('requiredServices');
       localStorage.removeItem('request_id');

        const timer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown <= 1) {
                    clearInterval(timer);
                    navigate('/permit/management');
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [navigate]);

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/dashboard' },
        { label: 'Permit', url: '' },
        { label: 'New', url: '/permit/new' },
    ];

    return (
        <>
            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={2000} // Close after 5 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false} // Show newest toast at the top
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        <div className="w-full min-w-full min-h-[88vh] px-4">
            <main>
                <div className="mt-3">
                    <Breadcrumb items={breadcrumbItems} />
                </div>
                <div className="mt-3">
                    <h1 className="font-bold text-gray-800 text-xl">Submission in progress...</h1>

                    <div className="flex flex-grow justify-center items-center">
                        <div className="text-center text-fts-gold p-8">

                            <div className="flex justify-center items-center mt-8">
                                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-24 w-24"></div>
                            </div>
                            <div className="flex justify-start items-start mt-8">
                                <div className="text-gray-800">
                                    Redirecting in {countdown} second{countdown !== 1 ? 's' : ''}...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6">

                </div>
            </main>
        </div>
        </>
    );
};

export default WaitingForApproval;
