import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {SYSTEM_NAME, SYSTEM_VERSION} from "./assets/wordings/constants";
import Footer from "./assets/components/footer";

const UserSelection = () => {
    const navigate = useNavigate();

    const goToGuest = () => {
        navigate('/guest');
    };

    const goToLogin = () => {
        navigate('/sign-in');
    };

    const containerStyle = {
        backgroundImage: 'url("https://ftsaero.com/wp-content/uploads/2023/07/flight-planning.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '100vh',
        width: '100%',
    }

    return (
        <div style={containerStyle}>
            <div className="bg-fts-blue/80 min-h-screen flex flex-col">
                <div className="flex flex-grow">
                    <div className="flex justify-center items-center w-full text-fts-gold p-8">
                        <div className="text-center">
                            <h1 className="font-bold text-[48px]">Choose to proceed</h1>
                            <hr className="w-[90%] border-fts-gold border-2 my-4" />
                            <h3 className="font-medium text-[32px]">ALORMS</h3>
                            <div className="flex justify-center items-center mt-12">
                                <button
                                    onClick={goToGuest}
                                    className="rounded-2xl bg-fts-gold hover:bg-fts-gold/70 px-4 py-2 text-fts-blue mr-5">
                                    Guest Login
                                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                </button>
                                <button
                                    onClick={goToLogin}
                                    className="rounded-2xl bg-fts-gold hover:bg-fts-gold/70 px-4 py-2 text-fts-blue">
                                    Already Registered
                                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default UserSelection;
