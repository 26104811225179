import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Breadcrumb from "../../assets/components/breadcrumb";
import { HomeIcon } from "@heroicons/react/16/solid";
import { BACKEND_URL } from "../../Default";
import { BACK } from "../../assets/wordings/constants";
import axios from 'axios';
import {CircularProgress, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {TrashIcon} from "@heroicons/react/24/solid";
import { confirmAlert } from 'react-confirm-alert'; // Import react-confirm-alert module
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DgrDocumentUpload = () => {
    const { requestId } = useParams();
    const navigate = useNavigate();
    const [purpose, setPurpose] = useState(null);
    const [type, setType] = useState(null);
    const [requiredServices, setRequiredServices] = useState({});

    const [unNumber, setUnNumber] = useState(null);
    const [permissionOrigin, setPermissionOrigin] = useState(null);
    const [permissionLanding, setPermissionLanding] = useState(null);
    const [otherPermissions, setOtherPermissions] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [subPurpose, setSubPurpose] = useState(null);

    const [existingDGRData, setExistingDGRData] = useState([]);
    const [dataRetrieved, setDataRetrieved] = useState(false);

    useEffect(() => {
        const storedPurpose = JSON.parse(localStorage.getItem('purpose'));
        const storedType = JSON.parse(localStorage.getItem('type'));
        const subPurpose = JSON.parse(localStorage.getItem('subPurpose'));
        setSubPurpose(subPurpose || null);

        setPurpose(storedPurpose || null);
        setType(storedType || null);
        fetchExistingData();
    }, []);

    const fetchExistingData = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/permit/get/detailed/${requestId}`);
            if (response.data && response.data.dgrs) {
                setDataRetrieved(true);
                console.log(response.data.dgrs)
                setExistingDGRData(response.data.dgrs);
            } else {
                setDataRetrieved(false);
                setExistingDGRData([]);
            }

        } catch (error) {
            console.error('Error fetching existing passenger data:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitLoading(true);

        const updatedServices = {
            ...requiredServices,
            dgrDocuments: {
                unNumber: unNumber ? unNumber.name : null,
                permissionOrigin: permissionOrigin ? permissionOrigin.name : null,
                permissionLanding: permissionLanding ? permissionLanding.name : null,
                otherPermissions: otherPermissions ? otherPermissions.name : null
            }
        };

        const formData = new FormData();
        formData.append('client_id', JSON.parse(localStorage.getItem('user')).id);
        formData.append('request_id', requestId);
        formData.append('request_data', JSON.stringify(updatedServices));
        formData.append('request_status', 'INCOMPLETE');

        function generateShortKey() {
            return Math.random().toString(36).substr(2, 5); // Generates a 5-character string
        }

        if (unNumber) {
            formData.append('files', unNumber, `DG-Certificate-${generateShortKey()}.${unNumber.type.split('/')[1]}`);
        }
        if (permissionOrigin) {
            formData.append('files', permissionOrigin, `Person-DG-${generateShortKey()}.${permissionOrigin.type.split('/')[1]}`);
        }
        if (permissionLanding) {
            formData.append('files', permissionLanding, `OPS-${generateShortKey()}.${permissionLanding.type.split('/')[1]}`);
        }
        if (otherPermissions) {
            formData.append('files', otherPermissions, `Insurance-${generateShortKey()}.${otherPermissions.type.split('/')[1]}`);
        }

        localStorage.setItem('requiredServices', JSON.stringify(updatedServices));

        try {
            const response = await axios.post(`${BACKEND_URL}/permit/dgr-data`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Response from backend:', response.data);

            setSubmitLoading(false);

            navigate(`/permit/new/add-on-services/${requestId}`);

        } catch (error) {
            console.error('Error sending data to backend:', error);
            setSubmitLoading(false);
        }
    };

    const goBack = () => {
        if (type && type.toLowerCase() === "passenger") {
            navigate(`/permit/new/passenger-documents-upload/${requestId}`);
        } else if (type && type.toLowerCase() === "cargo"){
            navigate(`/permit/new/cargo-documents-upload/${requestId}`);
        }
    };

    const handleDeleteDGRData = (document_id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this file?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            // Send the DELETE request with document_id in the URL
                            await axios.delete(`${BACKEND_URL}/permit/dgr-delete/${document_id}`, {
                                data: { request_id: requestId } // Send additional data in the request body
                            });
                            fetchExistingData();
                            toast.success('Passenger data deleted successfully');
                        } catch (error) {
                            console.log('Error deleting:', error);
                            toast.error('Error deleting passenger file');
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Permit', url: '' },
        { label: 'New', url: '/permit/new' },
    ];

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        <div className="flex flex-col">
            <main className="flex-grow">
                <div className="w-full min-w-full px-4">
                    <div className="mt-3">
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                    <div className="mt-3">
                        <h1 className="font-bold text-gray-800 text-xl">Upload DGR Documents</h1>
                    </div>
                    <div className="mt-3">
                        <form onSubmit={handleSubmit} className="text-gray-800">
                            <div className="text-start">
                                <div className="bg-white py-2 px-4 rounded-md">
                                    <h2>Upload the following documents</h2>
                                    <div className="flex max-w-full justify-center items-center">
                                        <div className="mt-3 flex justify-between gap-5">
                                            <div>
                                                Purpose of Flight: <strong>{purpose}</strong>
                                            </div>
                                            <div>
                                                Type of flight: <strong className="capitalize">{subPurpose}</strong>
                                            </div>
                                        </div>
                                    </div>

                                    {existingDGRData && existingDGRData.length > 0 && (
                                        <div className="flex justify-center">
                                            <div className="mt-3">
                                                <label className="font-bold ">Existing DGR Data</label>
                                                <div className="flex max-w-full justify-center items-center">
                                                    <div className="mt-3 flex justify-between gap-5">
                                                        <Table>
                                                            <TableBody>
                                                                {
                                                                    existingDGRData.map((data, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell>{index + 1}</TableCell>
                                                                            <TableCell>{data.document.document_name.split('-')[0]}</TableCell>
                                                                            <TableCell>
                                                                                <a
                                                                                    href={`${BACKEND_URL}/${data?.document?.file_path}`}
                                                                                    target="_blank"
                                                                                    className="bg-fts-blue text-white py-2 px-4 rounded-2xl ml-3 hover:bg-fts-gold"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    View
                                                                                </a>
                                                                            </TableCell>

                                                                            <TableCell>
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => handleDeleteDGRData(data.document_id)}>
                                                                                    <TrashIcon className="h-5 w-5 text-red-700 hover:text-red-500"/>
                                                                                </button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                }
                                                            </TableBody>
                                                        </Table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="grid grid-cols-1 gap-4 mx-12 px-12 mt-3 justify-center items-center">
                                        <div className="mb-2 grid grid-cols-2 gap-2">
                                            <label className="">Dangerous goods certificate:</label>
                                            <input
                                                type="file"
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                onChange={(e) => setUnNumber(e.target.files[0])}
                                            />
                                        </div>
                                        <div className="mb-2 grid grid-cols-2 gap-2">
                                            <label className="block">DG Certificate of person signed DG:</label>
                                            <input
                                                type="file"
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                onChange={(e) => setPermissionOrigin(e.target.files[0])}
                                            />
                                        </div>
                                        <div className="mb-2 grid grid-cols-2 gap-2">
                                            <label className="block">OPS Specification:</label>
                                            <input
                                                type="file"
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                onChange={(e) => setPermissionLanding(e.target.files[0])}
                                            />
                                        </div>
                                        <div className="mb-2 grid grid-cols-2 gap-2">
                                            <label className="block">Insurance Certificate:</label>
                                            <input
                                                type="file"
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                onChange={(e) => setOtherPermissions(e.target.files[0])}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-between mt-4">
                                    <button
                                        className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        type="button"
                                        onClick={goBack}>
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                        {BACK}
                                    </button>

                                    {submitLoading ? (
                                        <button type="button"
                                                className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                            <CircularProgress size={20} />
                                        </button>
                                    ) : (
                                        <button type="submit"
                                                className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                            Next
                                            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
            </>
    );
};

export default DgrDocumentUpload;
