const BRAND_NAME = "FTS Aero";
const SYSTEM_NAME = "SkyPermits©";
const SYSTEM_VERSION = "v1.0.2";

const AUTHOR = "FTS";

// Back Button
const BACK = "Back";
const NEXT = "Next";


export { BRAND_NAME, SYSTEM_NAME, SYSTEM_VERSION, AUTHOR, BACK, NEXT };