import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumb from "../assets/components/breadcrumb";
import { HomeIcon } from "@heroicons/react/16/solid";
import axios from "axios";
import { BACKEND_URL } from "../Default";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress
} from "@mui/material";
import { PieChart, Pie, Cell, Tooltip, Legend, LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

const Dashboard = ({ user: propUser }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(propUser || JSON.parse(localStorage.getItem('user')));
    const [permits, setPermits] = useState([]);
    const [permitLoading, setPermitLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortedField, setSortedField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [filterStatus, setFilterStatus] = useState('');
    const [submittedPermitCount, setSubmittedPermitCount] = useState(0);
    const [incompletePermitCount, setIncompletePermitCount] = useState(0);
    const [approvedPermitCount, setApprovedPermitCount] = useState(0);
    const [generatedPermitCount, setGeneratedPermitCount] = useState(0);
    const [dgrCount, setDgrCount] = useState(0);
    const [requestCategories, setRequestCategories] = useState([]);

    useEffect(() => {
        const fetchPermits = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
            }

            try {
                if (user && user.id) {
                    const response = await axios.get(`${BACKEND_URL}/permit/get/all/${user.id}`);
                    const permitData = response.data.map(permit => {
                        return {
                            id: permit.id,
                            requestId: permit.request_id || 'N/A',
                            name: permit.operator_name || 'N/A',
                            type: permit.category || 'Unknown',
                            subCategory: permit.sub_request_name || 'N/A',
                            isDGR: permit.DGR === 1 ? 'Yes' : 'No',
                            status: permit.status,
                            submissionDate: permit.added_date ? new Date(permit.added_date).toLocaleDateString() : 'N/A',
                            approvalDate: permit.updated_date ? new Date(permit.updated_date).toLocaleDateString() : 'N/A',
                            addedDate: permit.added_date || 'N/A'
                        };
                    });

                    setPermitLoading(false);
                    setPermits(permitData);

                    // Categorize permits
                    const submittedPermits = permitData.filter(permit => permit.status.toLowerCase() === 'submitted');
                    const incompletePermits = permitData.filter(permit => permit.status.toLowerCase() === 'incomplete');
                    const approvedPermits = permitData.filter(permit => permit.status.toLowerCase() === 'approved');
                    const generatedPermits = permitData.filter(permit => permit.status.toLowerCase() === 'rejected');
                    const dgrPermits = permitData.filter(permit => permit.isDGR === 'Yes');

                    setSubmittedPermitCount(submittedPermits.length);
                    setIncompletePermitCount(incompletePermits.length);
                    setApprovedPermitCount(approvedPermits.length);
                    setGeneratedPermitCount(generatedPermits.length);
                    setDgrCount(dgrPermits.length);

                    // Fetch request categories
                    const categoryResponse = await axios.get(`${BACKEND_URL}/requests/request-categories`);
                    setRequestCategories(categoryResponse.data);
                }
            } catch (error) {
                console.error('Error fetching permits:', error);
            }
        };

        fetchPermits();
    }, [navigate, user]);


    const filteredData = permits.filter(permit => {
        const matchesSearchTerm = (permit.name && permit.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (permit.requestId && permit.requestId.toLowerCase().includes(searchTerm.toLowerCase()));

        const matchesFilterStatus = filterStatus ? permit.status === filterStatus : true;
        return matchesSearchTerm && matchesFilterStatus;
    });

    const sortedData = filteredData.slice().sort((a, b) => {
        let comparison = 0;
        if (sortedField) {
            const fieldA = a[sortedField] ?? '';
            const fieldB = b[sortedField] ?? '';
            comparison = fieldA.localeCompare(fieldB);
            comparison = sortDirection === 'asc' ? comparison : -comparison;
        }
        if (sortedField === 'submissionDate') {
            const dateA = new Date(a.submissionDate);
            const dateB = new Date(b.submissionDate);
            comparison = sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
        }
        return comparison;
    }).sort((a, b) => new Date(b.addedDate) - new Date(a.addedDate)).slice(0, 3);

    const countPermitsByCategory = () => {
        const counts = {};

        requestCategories.forEach(category => {
            counts[category.name] = 0;
        });

        permits.forEach(permit => {
            if (counts[permit.type] !== undefined) {
                counts[permit.type]++;
            }
        });

        return counts;
    };

    const permitCounts = countPermitsByCategory();
    const pieChartData = Object.entries(permitCounts).map(([name, value]) => ({name, value}));
    const COLORS = ['#fe0000', '#00C49F', '#FFBB28', '#FF8042', '#FF4560'];

    const prepareLineChartData = () => {
        const dateCounts = {};
        permits.forEach(permit => {
            const date = new Date(permit.addedDate).toLocaleDateString();
            if (!dateCounts[date]) {
                dateCounts[date] = 0;
            }
            dateCounts[date]++;
        });

        return Object.entries(dateCounts).map(([date, count]) => ({
            date,
            count
        }));
    };

    const lineChartData = prepareLineChartData();

    const breadcrumbItems = [
        {label: <HomeIcon className="h-4 w-5"/>, url: '/'},
        {label: 'Dashboard', url: ''},
    ];

    return (
        <div className="w-full min-w-full mb-3 px-4">
            <main>
                <div className="mt-3 flex justify-between">
                    <div>
                        <Breadcrumb items={breadcrumbItems}/>
                    </div>

                    <div>
                        <h5 className="font-bold capitalize">
                            {user && user.username ? user.username : 'Designation Unavailable'}
                        </h5>
                    </div>
                </div>


                <div className="grid grid-cols-2 gap-4 mt-2">

                    <div
                        className="col-span-1 bg-white rounded shadow-md items-center justify-center text-center px-3 py-3">
                        <h5 className="font-semibold">Permit Distribution by Category</h5>
                        {permits.length === 0 ? (
                            <div className="flex items-center justify-center h-40">
                                <p>Data unavailable</p>
                            </div>
                        ) : (
                            <ResponsiveContainer width="100%" height={300}>
                                <PieChart>
                                    <Pie data={pieChartData} dataKey="value" nameKey="name" cx="50%" cy="50%"
                                         outerRadius={100} fill="#8884d8">
                                        {pieChartData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                        ))}
                                    </Pie>
                                    <Tooltip/>
                                    <Legend/>
                                </PieChart>
                            </ResponsiveContainer>
                        )}
                    </div>

                    <div
                        className="col-span-1 bg-white rounded shadow-md items-center justify-center text-center px-3 py-3">
                        <h5 className="font-semibold">Request Trend Over Time</h5>
                        {permits.length === 0 ? (
                            <div className="flex items-center justify-center h-40">
                                <p>Data unavailable</p>
                            </div>
                        ) : (
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={lineChartData}>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="date"/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <Legend/>
                                    <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{r: 8}}/>
                                </LineChart>
                            </ResponsiveContainer>
                        )}
                    </div>

                    <div className="grid col-span-2 grid-cols-5 gap-2">
                        <div className="bg-white rounded shadow-md items-center justify-center text-center px-3 py-3">
                            <h5 className="font-semibold">Total Requests</h5>
                            <h1 className="font-black text-2xl">{permits.length}</h1>
                        </div>

                        <div className="bg-white rounded shadow-md items-center justify-center text-center px-3 py-3">
                            <h5 className="font-semibold">Submitted Requests</h5>
                            <h1 className="font-black text-2xl">{submittedPermitCount}</h1>
                        </div>

                        <div className="bg-white rounded shadow-md items-center justify-center text-center px-3 py-3">
                            <h5 className="font-semibold">Incomplete Requests</h5>
                            <h1 className="font-black text-2xl">{incompletePermitCount}</h1>
                        </div>

                        <div className="bg-white rounded shadow-md items-center justify-center text-center px-3 py-3">
                            <h5 className="font-semibold">Approved Requests</h5>
                            <h1 className="font-black text-2xl">{approvedPermitCount}</h1>
                        </div>

                        <div className="bg-white rounded shadow-md items-center justify-center text-center px-3 py-3">
                            <h5 className="font-semibold">Rejected Requests</h5>
                            <h1 className="font-black text-2xl">{generatedPermitCount}</h1>
                        </div>


                    </div>

                    <div className="col-span-2">
                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Request ID</strong></TableCell>
                                            <TableCell><strong>Name</strong></TableCell>
                                            <TableCell><strong>Type</strong></TableCell>
                                            <TableCell><strong>Purpose</strong></TableCell>
                                            <TableCell><strong>DGR</strong></TableCell>
                                            <TableCell><strong>Status</strong></TableCell>
                                            <TableCell><strong>Submission Date</strong></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedData.map((permit) => (
                                            <TableRow key={permit.id}>
                                                <TableCell>{permit.requestId}</TableCell>
                                                <TableCell>{permit.name}</TableCell>
                                                <TableCell>{permit.type}</TableCell>
                                                <TableCell>{permit.subCategory}</TableCell>
                                                <TableCell>{permit.isDGR}</TableCell>
                                                <TableCell><div className="capitalize">{permit.status}</div></TableCell>
                                                <TableCell>{permit.submissionDate}</TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>


                </div>
            </main>
        </div>
    );
};

export default Dashboard;
