import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { BACKEND_URL } from '../Default';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewAircraft = ({ showModal, onClose }) => {
    const [aircraftTypes, setAircraftTypes] = useState([]);
    const [prefixOptions, setPrefixOptions] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [user, setUser] = useState({});
    const [userId, setUserId] = useState(null);
    const [aircraftPrefix, setAircraftPrefix] = useState('');
    const [aircraftRegNo, setAircraftRegNo] = useState('');
    const [aircraftMTOW, setAircraftMTOW] = useState('');
    const [airdropCapability, setAirdropCapability] = useState('');
    const [seatingCapacity, setSeatingCapacity] = useState('');
    const [onboardCommunication, setOnboardCommunication] = useState('');
    const [noiseCertificate, setNoiseCertificate] = useState(null);
    const [nationality, setNationality] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [countries, setCountries] = useState([]);
    const [customType, setCustomType] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (showModal) {
            const user = localStorage.getItem('user');
            const parsedUser = JSON.parse(user);
            setUser(parsedUser);
            setUserId(parsedUser.id);

            const fetchAircraftTypes = async () => {
                setLoading(true);
                try {
                    const response = await axios.get(`${BACKEND_URL}/aircrafts/all-types`);
                    setAircraftTypes(response.data);
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.message) {
                        setErrorMessage(error.response.data.message);
                    } else {
                        setErrorMessage('An error occurred. Please try again.');
                    }
                    console.error('Error fetching aircraft types:', error);
                } finally {
                    setLoading(false);
                }
            };

            const fetchPrefixes = async () => {
                try {
                    const response = await axios.get(`${BACKEND_URL}/aircrafts/all-reg`);
                    setPrefixOptions(response.data);
                } catch (error) {
                    console.error('Error fetching prefixes:', error);
                }
            };

            axios
                .get(`${BACKEND_URL}/requests/countries/all`)
                .then((response) => {
                    const countryOptions = response.data.map((country) => ({
                        value: country.id,
                        label: country.country_name,
                    }));
                    setCountries(countryOptions);
                })
                .catch((error) => {
                    console.error("Error fetching countries:", error);
                });

            fetchAircraftTypes();
            fetchPrefixes();

        }
    }, [showModal]);

    const validate = () => {
        const errors = {};
        if (!aircraftRegNo) errors.aircraftRegNo = 'Aircraft registration number is required.';
        if (!selectedType) errors.selectedType = 'Aircraft type is required.';
        if (!aircraftMTOW) errors.aircraftMTOW = 'MTOW is required.';
        // if (!airdropCapability) errors.airdropCapability = 'Airdrop capability is required.';
        if (!seatingCapacity) errors.seatingCapacity = 'Seating capacity is required.';
        // if (!onboardCommunication) errors.onboardCommunication = 'Onboard communication system is required.';
        if (!aircraftPrefix) errors.aircraftPrefix = 'Aircraft prefix is required.';
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const formData = new FormData();
        formData.append('client_id', user.id);
        formData.append('aircraft_registration', `${aircraftPrefix} ${aircraftRegNo}`);
        formData.append('aircraft_type_id', selectedType);
        formData.append('aircraft_mtow', aircraftMTOW);
        formData.append('airdrop_capability', airdropCapability);
        formData.append('seating_capacity', seatingCapacity);
        formData.append('onboard_communication', onboardCommunication);
        formData.append('noise_certificate', noiseCertificate);
        formData.append('nationality', nationality);

        try {
            await axios.post(`${BACKEND_URL}/aircrafts/new-aircraft`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // Reset form fields on success
            setSelectedType('');
            setAircraftPrefix('');
            setAircraftRegNo('');
            setAircraftMTOW('');
            setAirdropCapability('');
            setSeatingCapacity('');
            setOnboardCommunication('');
            setNoiseCertificate(null);
            setNationality('');
            setCustomType('');
            setErrors({});
            onClose();

            toast.success('Aircraft registered successfully');
        } catch (error) {
            // Check if the error response contains a message
            const errorMessage = error.response?.data?.error || 'Failed to register aircraft. Please try again later.';
            toast.error(errorMessage);
            console.error('Error adding new aircraft:', error);
        }
    };


    if (!showModal) {
        return null;
    }

    const handleTypeChange = (e) => {
        const value = e.target.value;
        setSelectedType(value);
        // Clear custom type if a predefined type is selected

    };

    const handlePrefixChange = (e) => {
        const selectedPrefix = e.target.value;
        setAircraftPrefix(selectedPrefix);

        const selectedCountry = prefixOptions.find(prefix => prefix.prefix === selectedPrefix);
        if (selectedCountry) {
            setNationality(selectedCountry.country);
        } else {
            setNationality('');
        }
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
                <div className="bg-white rounded-lg p-6 w-1/2">
                    <div className="flex justify-end">
                        <button onClick={onClose}>
                            <FontAwesomeIcon icon={faTimes} className="text-gray-700" />
                        </button>
                    </div>
                    <h2 className="text-2xl font-bold mb-4">Add New Aircraft</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="mb-4">
                                <label className="block">Aircraft Registration Number:</label>
                                <div className="flex">
                                    <select
                                        className="border border-gray-300 px-3 py-2 rounded-md w-1/4 mr-2"
                                        value={aircraftPrefix}
                                        onChange={handlePrefixChange}
                                    >
                                        <option value="">Select Reg</option>
                                        {prefixOptions.map((option) => (
                                            <option key={option.id} value={option.prefix}>
                                                {option.prefix}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        className="border border-gray-300 px-3 py-2 rounded-md w-3/4"
                                        value={aircraftRegNo}
                                        onChange={(e) => setAircraftRegNo(e.target.value)}
                                        placeholder="Registration Number"
                                    />
                                </div>
                                {errors.aircraftRegNo && <p className="text-red-500 text-sm">{errors.aircraftRegNo}</p>}
                            </div>

                            <div>
                                <label className="block">Aircraft Registered Country:</label>
                                <input
                                    type="text"
                                    className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                    value={nationality}
                                    disabled
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block">Aircraft Type:</label>
                                {loading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <div>
                                    <select
                                            className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                            value={selectedType}
                                            onChange={handleTypeChange}
                                        >
                                            <option value="">Select a type</option>
                                            {aircraftTypes.map((type) => (
                                                <option key={type.aircraft_type_id} value={type.aircraft_type_id}>
                                                    {type.aircraft_model}
                                                </option>
                                            ))}

                                        </select>
                                        {selectedType === 'other' && (
                                            <input
                                                type="text"
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full mt-2"
                                                placeholder="Enter aircraft type"
                                                value={customType}
                                                onChange={(e) => setCustomType(e.target.value)}
                                            />
                                        )}
                                    </div>
                                )}
                                {errors.selectedType && <p className="text-red-500 text-sm">{errors.selectedType}</p>}
                            </div>
                            <div className="mb-4">
                                <label className="block">Aircraft MTOW: (Kg)</label>
                                <input
                                    type="number"
                                    className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                    value={aircraftMTOW}
                                    onChange={(e) => setAircraftMTOW(e.target.value)}
                                />
                                {errors.aircraftMTOW && <p className="text-red-500 text-sm">{errors.aircraftMTOW}</p>}
                            </div>
                            {/*<div className="mb-4">*/}
                            {/*    <label className="block">Aircraft capable of air dropping:</label>*/}
                            {/*    <select*/}
                            {/*        className="border border-gray-300 px-3 py-2 rounded-md w-full"*/}
                            {/*        value={airdropCapability}*/}
                            {/*        onChange={(e) => setAirdropCapability(e.target.value)}*/}
                            {/*    >*/}
                            {/*        <option value="">Select Capability</option>*/}
                            {/*        <option value="YES">Yes</option>*/}
                            {/*        <option value="NO">No</option>*/}
                            {/*    </select>*/}
                            {/*    {errors.airdropCapability && <p className="text-red-500 text-sm">{errors.airdropCapability}</p>}*/}
                            {/*</div>*/}
                            <div className="mb-4">
                                <label className="block">Total seating capacity:</label>
                                <input
                                    type="number"
                                    className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                    value={seatingCapacity}
                                    onChange={(e) => setSeatingCapacity(e.target.value)}
                                />
                                {errors.seatingCapacity && <p className="text-red-500 text-sm">{errors.seatingCapacity}</p>}
                            </div>
                            {/*<div className="mb-4">*/}
                            {/*    <label className="block">Are there any communication systems onboard:</label>*/}
                            {/*    <select*/}
                            {/*        className="border border-gray-300 px-3 py-2 rounded-md w-full"*/}
                            {/*        value={onboardCommunication}*/}
                            {/*        onChange={(e) => setOnboardCommunication(e.target.value)}*/}
                            {/*    >*/}
                            {/*        <option value="">Select Availability</option>*/}
                            {/*        <option value="YES">Yes</option>*/}
                            {/*        <option value="NO">No</option>*/}
                            {/*    </select>*/}
                            {/*    {errors.onboardCommunication && <p className="text-red-500 text-sm">{errors.onboardCommunication}</p>}*/}
                            {/*</div>*/}
                            <div className="mb-4">
                                <label className="block">Noise certificate of the aircraft:</label>
                                <input
                                    type="file"
                                    className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                    onChange={(e) => setNoiseCertificate(e.target.files[0])}
                                />
                            </div>
                            <div className="flex justify-end col-span-2">
                                <button type="submit" className="hover:bg-fts-gold bg-fts-blue text-white px-4 py-2 rounded-md">
                                    Add Aircraft
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default NewAircraft;
