import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./user/Home";
import { ThemeProvider } from "@material-tailwind/react";



import Landing from "./Landing";
import Login from "./user/Login";
import Registration from "./user/Registration";
import UserSelection from "./UserSelection";
import GuestHome from "./guest/GuestHome";





function App() {

  return (
      <ThemeProvider>
          <Router>
              <div className="font-montserrat min-w-screen min-h-screen w-full h-full">

                              <Routes>
                                  <Route path="/" element={<Login />} />
                                  <Route path="/select-user" element={<UserSelection />} />
                                  <Route path="/guest/*" element={<GuestHome />} />

                                  <Route path="/sign-in" element={<Login />} />
                                  <Route path="/sign-up" element={<Registration />} />
                                  <Route path="/*" element={<Home />} />

                              </Routes>

              </div>
          </Router>
      </ThemeProvider>
  );
}

export default App;
